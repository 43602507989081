/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { BoltIcon, PlusIcon } from "@heroicons/react/20/solid";
import { BuildingOffice2Icon, LightBulbIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import {
  ActionIcon, Divider, Flex,
  LoadingOverlay,
  Paper, ScrollArea, Select, Stack, Text, TextInput, Tooltip,
} from "@mantine/core";
import { IconInfoSquareRounded, IconSelector } from "@tabler/icons-react";
import { useMemo } from "react";
import { useAllPlaybooks } from "../../../../../hooks/QueryHooks";
import MButton from "../../../../../shared/ui/buttons/MButton";
import CustomSelectInput from "../../../../../shared/ui/inputs/select/CustomSelectInput";
import useWorkspaceStore from "../../../../../store/workspace/workspaceStore";
import SignalFilterContainer from "../../signal-overview/components/SignalFilterContainer";
import ConditionsMeet from "./ConditionsMeet";
import CreatePlaybook from "./CreatePlaybook";
import Destinations from "./Destinations";
import TooltipPlaybookPreview from "./TooltipPlaybookPreview";

const typeChoices = [
  { value: "accounts", label: "Accounts", Icon: BuildingOffice2Icon },
  { value: "users", label: "Users", Icon: UserCircleIcon },
];

const basedOnChoices = [
  { value: "usage_based", label: "Usage - hits a usage threshold" },
];

type Props = {
  traitsAndMetrics: any;
  signal: any;
  setName: any;
  setEntityRelation: any;
  setTrigger: any;
  onTimeWindowChange: any;
  handlePredicateAdd: any;
  handlePredicateClear: any;
  handlePredicateRemove: any;
  handlePredicateChange: any;
  handleFilterAdd: any;
  clearFilter: any;
  createAction: any;
  updateAction: any;
  deleteAction: any;
  playbooks: any;
  error: any;
  onSignalSubmit: any;
  clearSignal: any;
  total: any;
  setSuppressionPeriod: any;
  setPlaybook: any;
  containerClass?: string;
  height?: string;
  setSelectedFields?: any;
  setSelectedMetrics?: any;
};

const NewSignalBody = ({
  traitsAndMetrics,
  signal,
  setPlaybook,
  setName,
  setEntityRelation,
  setTrigger,
  onTimeWindowChange,
  handlePredicateAdd,
  handlePredicateClear,
  handlePredicateRemove,
  handlePredicateChange,
  handleFilterAdd,
  clearFilter,
  updateAction,
  deleteAction,
  playbooks,
  error,
  createAction,
  onSignalSubmit,
  clearSignal,
  total,
  setSuppressionPeriod,
  containerClass,
  height,
  setSelectedFields,
  setSelectedMetrics,
}: Props) => {
  const workspace = useWorkspaceStore((state) => state.workspace);
  const { data: playBookData }: any = useAllPlaybooks({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
    select: (pb: any) => pb?.data,
  });

  const selectedPlaybook = useMemo(
    () => playBookData?.find((item: any) => item?.id === signal?.automation_actions?.playbook_id),
    [playBookData, signal?.automation_actions?.playbook_id],
  );

  return (
    <Paper
      p="lg"
      className={`mt-6 relative ${containerClass || ""}`}
      w="full"
    >
      <LoadingOverlay loaderProps={{ size: "md" }} c="main.5" visible={traitsAndMetrics.isLoading} overlayBlur={2} />
      <ScrollArea h={height || "calc(100vh - 250px)"} offsetScrollbars>
        <TextInput
          value={signal?.name}
          onChange={(event) => setName(event.currentTarget.value)}
          classNames={{
            label: "text-gray-500 font-bold mb-1",
          }}
          label="Signal title"
          error={error && !signal?.name && "Signal name must not be empty"}
          placeholder="Enter a signal title here"
        />
        <Flex align="center" gap={5} className="mt-6">
          <ActionIcon color="main.5" size="xl">
            <BoltIcon className="rounded-md p-2 bg-purple-100" />
          </ActionIcon>
          <Stack spacing={0}>
            <Text color="text.9" size="sm" fw={600}>When</Text>
            <Text color="text.4" size="xs">Add a condition here</Text>
          </Stack>
        </Flex>
        <Flex gap={30} className="mt-3">
          <Select
            icon={signal?.entity_relation === "accounts"
              ? <BuildingOffice2Icon className="w-4 h-4 text-gray-500" radius="xl" />
              : <UserCircleIcon className="w-4 h-4 text-gray-500" radius="xl" />}
            classNames={{
              label: "text-gray-500 font-bold mb-1",
            }}
            value={signal?.entity_relation}
            w={280}
            size="sm"
            itemComponent={CustomSelectInput}
            data={typeChoices}
            label="Type"
            onChange={(value: "accounts" | "users") => {
              setEntityRelation(value);
              clearFilter();
            }}
          />
          <Select
            w={280}
            size="sm"
            classNames={{
              label: "text-gray-500 font-bold mb-1",
            }}
            value={signal?.trigger}
            data={basedOnChoices}
            label="Trigger"
            onChange={(value: "usage_based" | "event_based") => setTrigger(value)}
          />
        </Flex>
        <Text color="text.4" size="xs" fw={600} className="mt-3 mb-1">Select condition(s)</Text>
        {signal?.filters?.predicates?.map((filter: any, index: number) => (
          <>
            <SignalFilterContainer
              key={index + signal.entity_relation}
              entityRelation={signal?.entity_relation}
              time_window={signal?.time_window}
              selectedFilters={filter.predicates}
              handleAdd={handlePredicateAdd}
              handleClear={handlePredicateClear}
              handleRemove={handlePredicateRemove}
              handleFilter={handlePredicateChange}
              filterId={index}
            />
            {signal?.filters?.predicates?.length === index + 1 ? null : (
              <Flex h={50} direction="column" align="center" justify="center">
                <Divider w={2} className="mx-auto" orientation="vertical" size="lg" h={15} />
                <Text size="xs" c="text.4" weight={600}>- OR -</Text>
                <Divider w={2} className="mx-auto" orientation="vertical" size="lg" h={15} />
              </Flex>
            )}
          </>
        ))}
        <button
          type="button"
          onClick={() => { handleFilterAdd(); }}
          className="flex mt-4 rounded-lg hover:text-purple-800 items-center p-1 text-xs font-medium text-purple-500"
        >
          <PlusIcon className="mr-[1px] flex-shrink-0 h-[20px] w-[20px]" />
          Add Condition Group
        </button>
        {error && (!signal?.filters?.predicates?.find((fil: any) => fil?.predicates?.find((item: any) => item?.field?.includes("metric"))))
        && (
        <Text color="red.5" size="xs" fw={600} className="mt-3 mb-1">
          There must be at least one metric condition to create a signal
        </Text>
        )}
        <ConditionsMeet total={total} entityRelation={signal?.entity_relation} />
        <Flex align="center" gap={5} className="mt-6">
          <ActionIcon color="main.5" size="xl">
            <LightBulbIcon className="rounded-md p-2 bg-purple-100" />
          </ActionIcon>
          <Stack spacing={0}>
            <Text color="text.9" size="sm" fw={600}>Then</Text>
            <Text color="text.4" size="xs">This action should be performed</Text>
          </Stack>
        </Flex>
        <Flex align="center" gap={30} className="mt-6">
          <Select
            w={280}
            value={signal?.automation_actions.suppression_period}
            onChange={(value: any) => setSuppressionPeriod(value)}
            classNames={{
              label: "text-gray-500 font-bold mb-1",
            }}
            data={[{
              label: "First time only",
              value: "all_time",
            }, {
              label: "Every 24 hours",
              value: "daily",
            }]}
            label={`Alert Frequency (per ${signal.entity_relation === "accounts" ? "account" : "user"})`}
            size="sm"
            placeholder="Alert Frequency (per Account)"
            error={error && !signal?.automation_actions.suppression_period && "Alert Frequency is required"}
          />
          <Flex gap={5} align="end">
            <Select
              w={280}
              classNames={{
                label: "text-gray-500 font-bold mb-1",
                input: "overflow-hidden text-ellipsis whitespace-nowrap",
              }}
              className="flex-1"
              data={playbooks?.length ? playbooks : []}
              rightSection={(
                <Flex gap={2} className="bg-white">
                  <Tooltip
                    classNames={{
                      tooltip: "w-[450px]",
                    }}
                    label={playBookData ? <TooltipPlaybookPreview htmlContent={selectedPlaybook?.content} /> : "Please select a playbook to see preview"}
                  >
                    <IconInfoSquareRounded className="w-4 h-4 text-gray-500 hover:text-gray-700 cursor-context-menu" />
                  </Tooltip>
                  <IconSelector className="w-4 h-4 text-gray-500 cursor-pointer" />
                </Flex>
)}
              label="Play"
              size="sm"
              placeholder="None selected"
              onChange={(value: string) => setPlaybook(value)}
              value={signal?.automation_actions?.playbook_id}
            />
            <Text size="xs" c="text.4" weight={600} className="mb-2 w-6 text-center">or</Text>
            <CreatePlaybook>
              <MButton size="sm" className="px-1" c="main.5" variant="subtle">Create playbook</MButton>
            </CreatePlaybook>
          </Flex>
        </Flex>
        <Destinations
          deleteAction={deleteAction}
          setSelectedMetrics={setSelectedMetrics}
          setSelectedFields={setSelectedFields}
          signal={signal}
          actions={signal?.automation_actions?.actions}
          updateAction={updateAction}
          playbook={selectedPlaybook}
        />
      </ScrollArea>
    </Paper>
  );
};

export default NewSignalBody;
