/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */

import { ICellTextProps } from "ka-table/props";
import React from "react";
import { Flex, Progress, Text } from "@mantine/core";

const PercentCell: React.FC<ICellTextProps> = React.memo(
  ({
    column, dispatch, rowKeyValue, ...props
  }) => {
    const value = props.rowData[`${column.key}`];
    return (
      <Flex align="center" gap={5}>
        <Progress className="flex-1" value={parseInt(value, 10)} color="main.4" radius="xl" />
        <Text className="w-[20px]" c="text.4" fw={600} size={10}>
          {Math.round(value)}
          %
        </Text>
      </Flex>
    );
  },
);

export default PercentCell;
