import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { BuildingOfficeIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { Collapse, Tooltip, UnstyledButton } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import React, {
  useMemo, useState,
} from "react";
import { useLocation } from "react-router-dom";
import ViewDropdown from "./ViewDropdown";

type Props = {
  text?: string,
  label?: string,
  NavIcon: React.ComponentType<React.SVGProps<SVGSVGElement>>,
  view: any,
  menu: boolean,
  setMenu: any,
};

const classNames = (...classes : any[]) => (classes.filter(Boolean).join(" "));

const NavBarLinksGroup = ({
  text,
  NavIcon,
  view,
  menu, setMenu,
  label,
} : Props) => {
  const location = useLocation();
  const [dropdown, setDropdown] = useState<boolean>(true);
  const { hovered, ref } = useHover();

  const defaultView = useMemo(() => (
    view?.filter((item: any) => item?.is_precreated)?.reduce((ob: any, val: any) => {
      ob[val?.entity_relation] = val;
      return ob;
    }, {} as any)
  ), [view]);

  return (
    <Tooltip opened={!menu && hovered} withinPortal label={label} position="right" zIndex={1000}>
      <div
        className={classNames(
          location.pathname.includes("/view")
            ? "bg-purple-50 text-purple-600"
            : "text-gray-500 hover:bg-white hover:text-gray-600",
          "w-full min-w-0 overflow-x-visible flex-nowrap ease-linear duration-300 group",
          menu ? "justify-between" : "justify-center",
        )}
        ref={ref}
      >
        <UnstyledButton
          type="button"
          onClick={() => { setMenu(true); setDropdown((state) => !state); }}
          className={classNames(
            location.pathname.includes("/view")
              ? "bg-purple-50 hover:bg-purple-100 text-purple-700"
              : "text-gray-500 hover:bg-gray-100 hover:text-gray-900 border-transparent",
            "w-full min-w-0 flex px-[11px] py-[11px] overflow-x-visible items-center font-semibold text-sm",
            menu ? "justify-between" : "justify-center",
          )}
        >
          <NavIcon
            className={`${menu && "mr-2"} stroke-purple-400 h-5 w-5 flex-shrink-0`}
            aria-hidden="true"
          />
          {menu && (
            <>
              {text}
              <ChevronDownIcon
                className={classNames(
                  dropdown ? "rotate-180" : "text-gray-700",
                  "ml-auto h-4 w-4 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-900",
                )}
              />
            </>
          )}
        </UnstyledButton>
        {menu && (
        <Collapse in={dropdown}>
          <div className="pl-4">
            {view && (
            <>
              <ViewDropdown
                mainView={defaultView.accounts}
                views={view?.filter((item:any) => item.entity_relation === "accounts" && !item.is_precreated)}
                NavIcon={BuildingOfficeIcon}
                type={defaultView?.accounts?.entity_relation}
              />
              <ViewDropdown
                mainView={defaultView.users}
                views={view?.filter((item:any) => item.entity_relation === "users" && !item.is_precreated)}
                NavIcon={UserCircleIcon}
                type={defaultView?.users?.entity_relation}
              />
            </>
            )}
          </div>
        </Collapse>
        )}
      </div>
    </Tooltip>
  );
};

export default NavBarLinksGroup;
