import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../../shared/ui/breadcrumb/Breadcrumb";

type Props = {
  accountName?: string,
  userName?: string,
  className?: string,
};

const AccountCrumbs = ({
  accountName, userName, className,
}: Props) => {
  const {
    workspaceId, viewId, accountId, userId,
  } = useParams();

  return (
    <div className={`pl-5 flex gap-[2px] items-center text-gray-700 text-sm w-full ${className && className} `}>
      <Breadcrumb size="sm" to={`/home/${workspaceId}/headquarters`}><HomeIcon width={14} height={14} /></Breadcrumb>
      {viewId
      && (
      <>
        <ChevronRightIcon height={14} width={14} />
        <Breadcrumb
          size="sm"
          to={`/home/${workspaceId}/view/${viewId}`}
        >
          Views
        </Breadcrumb>
      </>
      )}
      {accountId
      && (
      <>
        <ChevronRightIcon height={14} width={14} className={`${!userId && "fill-purple-500 !hover:fill-purple-700"}`} />
        <Breadcrumb size="sm" className={`${!userId && "text-purple-500 !hover:text-purple-700"}`} to={`/home/${workspaceId}/view/${viewId}/account/${accountId}/overview`}>{accountName}</Breadcrumb>
      </>
      )}
      {userId
        && (
        <>
          <ChevronRightIcon height={14} width={14} className="fill-purple-500 !hover:fill-purple-700" />
          <Breadcrumb
            size="sm"
            className="text-purple-500 !hover:text-purple-700"
            to={accountId ? `/home/${workspaceId}/view/${viewId}/account/${accountId}/user/${userId}`
              : `/home/${workspaceId}/view/${viewId}/user/${userId}`}
          >
            {userName}
          </Breadcrumb>
        </>
        )}
    </div>
  );
};

export default AccountCrumbs;
