/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { IconBook } from "@tabler/icons-react";
import {
  ActionType, ITableProps, SortingMode, kaReducer,
} from "ka-table";
import {
  hideLoading,
  loadData, showLoading, updateData, updatePageIndex, updatePagesCount,
} from "ka-table/actionCreators";
import { DispatchFunc } from "ka-table/types";
import { useLayoutEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import { useAllPlaybooks } from "../../../../hooks/QueryHooks";
import KaTable, { IActionPayload } from "../../../../shared/table/KaTable";
import MButton from "../../../../shared/ui/buttons/MButton";
import useWorkspaceStore from "../../../../store/workspace/workspaceStore";

const PlaybooksPage = () => {
  const navigate = useNavigate();
  const { workspace }: any = useWorkspaceStore();
  const tableHeight = useMemo(() => window.innerHeight - 260, []);
  const [total, setTotal] = useState<number>(0);

  const [tableProps, changeTableProps] = useState<ITableProps>({
    columns: [{
      key: "title",
      dataType: "string",
      title: "Playbook Title",
      isResizable: true,
      colGroup: { style: { minWidth: 50 } },
      isSortable: true,
    }, {
      key: "created_at",
      dataType: "string",
      title: "Created At",
      isResizable: true,
      isSortable: true,
      colGroup: { style: { minWidth: 50 } },
    }, {
      key: "updated_at",
      dataType: "string",
      title: "Last Updated",
      isResizable: true,
      colGroup: { style: { minWidth: 50 } },
      isSortable: true,
    },
    {
      key: "playbook_actions",
      dataType: "playbook_actions",
      title: "",
      isResizable: false,
      colGroup: { style: { width: 100 } },
      isSortable: false,
    },
    ],
    columnResizing: true,
    columnReordering: true,
    height: tableHeight,
    rowKeyField: "randomId",
    singleAction: loadData(),
    sortingMode: SortingMode.Single,
    loading: {
      enabled: true,
      text: "Loading",
    },
  });

  const playbooks: any = useAllPlaybooks({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
  });

  const onColumnChange = (payload: IActionPayload) => {
    const {
      type, columnKey, width, targetColumnKey,
    } = payload;

    if (type === ActionType.ResizeColumn) {
      const newFields = tableProps?.columns?.map((field: any) => {
        if (field.key === columnKey) {
          field.width = width;
        }
        return field;
      });
      changeTableProps((state) => ({ ...state, columns: newFields }));
    }

    if (type === ActionType.ReorderColumns) {
      const columnIndex = tableProps?.columns?.findIndex((field: any) => field.key === columnKey);
      const targetColumnIndex = tableProps?.columns?.findIndex(
        (field: any) => field.key === targetColumnKey,
      );
      const newFields = [...tableProps.columns];
      const temp = newFields[columnIndex];
      newFields[columnIndex] = newFields[targetColumnIndex];
      newFields[targetColumnIndex] = temp;
      // shift the column to the target column index.
      changeTableProps((state) => ({ ...state, columns: newFields }));
    }
  };

  const kaDispatch: DispatchFunc = (action: any) => {
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
    if (action.type === ActionType.ResizeColumn || action.type === ActionType.ReorderColumns) {
      if (action.type === ActionType.ResizeColumn) {
        onColumnChange({
          type: action.type,
          columnKey: action.columnKey,
          width: action.width,
        });
      } else {
        onColumnChange({
          type: action.type,
          columnKey: action.columnKey,
          targetColumnKey: action.targetColumnKey,
        });
      }
    }
  };

  const setData = () => {
    const temp = playbooks?.data?.map((item: any) => {
      const value = { ...item };
      value.randomId = uuid();
      return value;
    });
    if (temp?.length > 0) {
      setTotal(playbooks?.data?.length);
      kaDispatch(updateData(temp));
      kaDispatch(hideLoading());
    } else {
      setTotal(0);
      kaDispatch(updatePageIndex(0));
      kaDispatch(updateData([]));
      kaDispatch(updatePagesCount(1));
      kaDispatch(hideLoading());
    }
  };

  useLayoutEffect(() => {
    if (playbooks?.isLoading) {
      kaDispatch(showLoading());
    } else {
      setData();
    }
  }, [playbooks?.isLoading]);

  useLayoutEffect(() => {
    if (tableHeight) {
      changeTableProps((prevState: ITableProps) => ({
        ...prevState,
        height: tableHeight,
      }));
    }
  }, [tableHeight]);

  return (
    <div className="px-12 py-8 overflow-auto relative h-full bg-gray-100">
      <div className="max-w-10xl mx-auto">
        <div className="flex mt-2 mb-5 justify-between items-center">
          <h2 className="text-gray-900 text-[30px] font-bold">Your Playbooks</h2>
          <div className="flex gap-2">
            <MButton
              size="sm"
              onClick={() => navigate("new")}
              color="white"
              className="text-sm"
              leftIcon={<IconBook className="w-4 h-4" />}
            >
              New Playbook
            </MButton>
          </div>
        </div>
      </div>
      <div className="mx-auto">
        <div className="bg-white border-2 border-gray-200 rounded-md">
          <KaTable
            tableProps={tableProps}
            totalData={total}
            changeTableProps={changeTableProps}
            kaDispatch={kaDispatch}
            rowKeyField="randomId"
            itemType="Actions"
            onColumnChange={onColumnChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PlaybooksPage;
