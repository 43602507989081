/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Flex,
  Group, Modal,
  Text, TextInput,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Link, RichTextEditor } from "@mantine/tiptap";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { BubbleMenu, FloatingMenu, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import PlaybookPreview from "../../signals/new-signal/components/PlaybookPreview";
import MButton from "../../../../shared/ui/buttons/MButton";
import { createPlaybook, updatePlaybook } from "../../../../services/playbook/playbookEndpoints";
import useWorkspaceStore from "../../../../store/workspace/workspaceStore";
import notify from "../../../../utils/notify";
import { queryClient, useAllPlaybooks, useCurrentUser } from "../../../../hooks/QueryHooks";

type Props = {
  children: React.ReactNode;
  entity_relation?: string;
  title?: string;
};

const EditPlaybookPage = () => {
  const workspace = useWorkspaceStore((state) => state.workspace);
  const params = useParams();
  const navigate = useNavigate();

  const editor = useEditor({
    extensions: [
      Underline,
      Link,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3, 4],
          HTMLAttributes: {
            class: "text-2xl font-bold",
          },
        },
        code: {
          HTMLAttributes: {
            class: "bg-gray-100 text-sm p-2 rounded",
          },
        },
        bulletList:
        {
          HTMLAttributes: {
            class: "list-disc list-outside",
          },
        },
        orderedList:
        {
          HTMLAttributes: {
            class: "list-decimal list-outside",
          },
        },
      }),
    ],
  });

  const { data: user }: any = useCurrentUser({
  });
  const playbooks: any = useAllPlaybooks({
    workspaceId: workspace?.id,
    select: (data: any) => data?.data
      ?.filter((playbook: any) => playbook?.id === params.playbookId),
  });
  const [error, setError] = useState(false);
  const [playName, setPlayName] = useState("");

  useEffect(() => {
    if (playbooks.isSuccess) {
      if (playbooks?.data[0]) {
        setPlayName(playbooks?.data[0]?.title);
        editor?.commands?.setContent(playbooks?.data[0]?.content);
      }
    }
  }, [playbooks.isSuccess, editor]);

  const editPlaybookMutation = useMutation({
    mutationFn: updatePlaybook,
  });

  const submitPlay = () => {
    if (!playName || !user?.user_id || !workspace?.id) {
      setError(true);
    } else {
      setError(false);
      editPlaybookMutation.mutate({
        title: playName,
        content: editor?.getHTML() || "",
        content_json: editor?.getJSON() || {},
        entity_relation: playbooks?.data[0]?.entity_relation,
        workspace_id: workspace?.id,
        updated_by: user?.user_id,
        playbook_id: params.playbookId!,
      }, {
        onSuccess: (data: any) => {
          queryClient.setQueryData(["all_playbooks", workspace?.id], (oldData: any) => ({
            ...oldData,
            data: oldData.data.map((playbook: any) => {
              if (playbook.id === params.playbookId) {
                return data.data;
              }
              return playbook;
            }),
          }));
          notify({
            type: "info",
            messageList: ["Updated Playbook"],
          });
          navigate(-1);
        },
        onError: (err: any) => {
          if (err.response?.status !== 0) {
            notify({
              heading: "Unknown error.",
              type: "failure",
              messageList: [
                "An unknown error occurred while updating the playbook. Please try again later or contact our support team for assistance.",
              ],
            });
          }
        },
      });
    }
  };

  return (
    <div className="px-12 py-8 overflow-auto relative h-full bg-gray-100">
      <div className="max-w-10xl mx-auto">
        <div className="flex mt-2 mb-5 justify-between items-center">
          <h2 className="text-gray-900 text-[30px] font-bold">Edit Playbook</h2>
        </div>
      </div>
      <div className="mx-auto">
        <div className="bg-white border-2 p-5 border-gray-200 rounded-md">
          <TextInput
            value={playName}
            onChange={
          (event: any) => {
            setPlayName(event?.currentTarget.value);
          }
        }
            classNames={{
              label: "text-gray-500 font-bold mb-1",
            }}
            label="Play name"
            placeholder="Enter a play"
            error={error && playName === "" ? "Play name must not be empty" : null}
          />
          <Text size="sm" className="text-gray-500 font-bold mt-5">
            Content
          </Text>
          <RichTextEditor editor={editor}>
            {editor && (
            <>
              <BubbleMenu editor={editor}>
                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Bold />
                  <RichTextEditor.Italic />
                  <RichTextEditor.Link />
                </RichTextEditor.ControlsGroup>
              </BubbleMenu>
              <FloatingMenu editor={editor}>
                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.H1 />
                  <RichTextEditor.H2 />
                  <RichTextEditor.BulletList />
                </RichTextEditor.ControlsGroup>
              </FloatingMenu>
            </>
            )}
            <RichTextEditor.Toolbar>
              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Bold />
                <RichTextEditor.Italic />
                <RichTextEditor.Underline />
                <RichTextEditor.Strikethrough />
                <RichTextEditor.ClearFormatting />
                <RichTextEditor.Highlight />
                <RichTextEditor.Code />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.H1 />
                <RichTextEditor.H2 />
                <RichTextEditor.H3 />
                <RichTextEditor.H4 />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Blockquote />
                <RichTextEditor.Hr />
                <RichTextEditor.BulletList />
                <RichTextEditor.OrderedList />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Link />
                <RichTextEditor.Unlink />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.AlignLeft />
                <RichTextEditor.AlignCenter />
                <RichTextEditor.AlignJustify />
                <RichTextEditor.AlignRight />
              </RichTextEditor.ControlsGroup>
            </RichTextEditor.Toolbar>

            <RichTextEditor.Content />
          </RichTextEditor>
          <Text size="sm" className="text-gray-500 font-bold mt-5">
            Preview
          </Text>
          <PlaybookPreview htmlContent={editor?.getHTML() || ""} />
          <Flex justify="flex-end" gap={5} className="mt-4">
            <MButton
              onClick={() => {
                navigate(-1);
              }}
              variant="outline"
              className="hover:bg-gray-100"
              color="gray"
            >
              Cancel
            </MButton>
            <MButton
              color="purple"
              onClick={
              () => {
                submitPlay();
              }
          }
            >
              Save Play
            </MButton>
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default EditPlaybookPage;
