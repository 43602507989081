/* eslint-disable react/jsx-props-no-spreading */
import { Button, ButtonProps } from "@mantine/core";

const MButton = (props: import("@mantine/utils").PolymorphicComponentProps<"button", ButtonProps>) => {
  const { color, className } = props;
  let buttonStyle = "";
  switch (color) {
    case "green":
      buttonStyle += " bg-emerald-600 text-white hover:bg-emerald-700 focus:bg-emerald-600 focus:outline-none focus:shadow-textBox focus:shadow-emerald-100";
      break;
    case "white":
      buttonStyle += " border-gray-300 bg-white text-gray-500 hover:bg-secondary-100 hover:text-secondary-700 focus:bg-white focus:text-primary-600 focus:border-primary-600 focus:shadow-textBox";
      break;
    case "purple":
      buttonStyle += "  bg-primary-600 text-white hover:bg-primary-700 focus:bg-primary-600 focus:outline-none focus:shadow-textBox disabled:bg-gray-400";
      break;

    case "opaque":
      buttonStyle += "  border-purple-500 bg-none text-purple-500 hover:text-purple-600 hover:border-purple-600 hover:bg-gray-50 focus:bg-white focus:text-primary-600 focus:border-primary-600 focus:shadow-textBox";
      break;
    default:
  }

  return (
    <Button {...props} className={`${buttonStyle} ${className && className}`} />
  );
};

export default MButton;
