import React, { useMemo } from "react";
import { PencilIcon } from "@heroicons/react/20/solid";
import HeButton from "../../../../../shared/ui/buttons/HeButton";
import MultiSelectDropdown from "../../../../../shared/ui/dropdown/MultiSelectDropdown";
import BasicSelect from "../../../../../shared/ui/inputs/select/BasicSelect";

type Props = {
  users: number,
  metric: any,
  setMetric: any,
  showHidden: boolean,
  setShowHidden: any,
  filteredTraits: any[],
  filteredMetrics: any[],
  selectedColumns: any[],
  setSelectedColumns: any,
};

const UsersToolbar = ({
  users,
  showHidden, setShowHidden, filteredTraits, filteredMetrics, selectedColumns, setSelectedColumns,
  metric, setMetric,
}: Props) => {
  const metrics = useMemo(() => (
    ["Last day", "Last 7 days", "Last 30 days", "Last 90 days", "Last 180 days", "Last 365 days"].map((key, id) => ({ id: (id + 1), name: key }))), []);
  return (
    <div className="flex mt-2 gap-10 px-5 w-full justify-between items-center">
      <h2 className="text-gray-900 whitespace-nowrap text-[20px] font-semibold">
        {users}
        {" "}
        users
      </h2>
      <div className="flex flex-wrap gap-2 items-center justify-end flex-1">
        <BasicSelect textClass="text-gray-600 text-xs py-0.5" containerClass="min-w-[140px]" placeholder="Last Seen" list={metrics} selectedItem={metric} setSelectedItem={setMetric} />
        <MultiSelectDropdown
          selected={selectedColumns}
          setSelected={setSelectedColumns}
          isOpen={showHidden}
          setIsOpen={setShowHidden}
          list={filteredTraits}
          metricsList={filteredMetrics}
        >
          <div>
            <HeButton size="sm" onClick={() => { setShowHidden((state: any) => !state); }} text="Edit columns" color="white" className="text-xs" LeadingIcon={PencilIcon} />
          </div>
        </MultiSelectDropdown>
      </div>
    </div>
  );
};

export default UsersToolbar;
