const stepSelector = (
  methodName: string,
  workspace_uid: string,
  key: string,
) => {
  switch (methodName) {
    case "Browser":
      return [
        {
          script: `<script 
  src="https: //unpkg.com/@hyperengage/sdk-js/dist/web/lib.js" 
  data-tracking-host="${process.env.REACT_APP_TRACKING_HOST}" 
  data-workspace-key="${workspace_uid}"
  data-key="${key}"
  data-init-only="true"
  defer></script>
<script>window.hyperengage = window.hyperengage || (function(){(window.hyperengageQ = window.hyperengageQ || []).push(arguments);})</script>`,
          title: "Install the tracking script",
          desc: "A small snippet of code that installs hyperengae to your site, sending us important actions that users trigger throughout your app.",
        },
        {
          script: `window.hyperengage(
  'identify_account', {
    account_id: 'YOUR_ACCOUNT_ID',
    traits: {
      name: 'YOUR_ACCOUNT_NAME',
    }}
);`,
          title: "Identify your accounts",
          desc: "After connecting your data source with the base tracking script send over your account/company data.",
        },
        {
          script: `window.hyperengage(
  'identify_user', {
    user_id: 'YOUR_USER_ID',
    traits: {
      name: "YOUR_USER_NAME",
      email: "YOU_USER_EMAIL"
    }}
);`,
          title: "Identify your users",
          desc: "You can attach any properties that you want to our users eg their job role, industry etc.",
        },
        {
          script: `window.hyperengage(
  'track',
  'EVENT_NAME', {
    properties: {
      test: "Test-1"
    }
  }
);`,
          title: "Track your events",
          desc: "Track key actions that users perform in your  product so you can accurately measure their adoption and retention. ",
        },
      ];
    case "Node":
      return [
        {
          script: "npm i @hyperengage/sdk-js --save",
          title: "Install the NodeJS SDK",
          desc: "A NodeJS SDK that you can add to your app.",
          script2: `const { hyperengageClient } = require('@hyperengage/sdk-js');

  const hyperengage = hyperengageClient({
    key: "${key}",
    workspace_key: "${workspace_uid}",
    tracking_host: "${process.env.REACT_APP_TRACKING_HOST}",
  });`,
        },
        {
          script: `hyperengage.identify_account({
  account_id: 'YOUR_ACCOUNT_ID', 
  user_id: 'YOUR_USER_ID',
  traits: {
    name: 'YOUR_ACCOUNT_NAME',
  }
});`,
          title: "Identify your accounts",
          desc: "After installing the SDK, the next step is for you to identify your accounts",
        },
        {
          script: `hyperengage.identify_user({
  user_id: 'YOUR_USER_ID',
  traits: {
    name: "YOUR_USER_NAME",
    email: "YOU_USER_EMAIL"
  }
});`,
          title: "Identify your users",
          desc: "You can attach any properties that you want to our users eg their job role, industry etc.",
        },
        {
          script: `hyperengage.track(
  'EVENT_NAME', {
    properties: {
      test: "Test-1"
    }
  }
);`,
          title: "Track your events",
          desc: "Track key actions that users perform in your  product so you can accurately measure their adoption and retention. To send bulk events, send the payload as an array of events.",
        },
      ];
    case "Api":
      return [
        {
          script: `${workspace_uid}`,
          title: "Obtaining Server Token",
          desc: "Copy the server token and workspace_id from below and use it to authenticate your requests",
          script2: `${key}`,
        },
        {
          script: `{
            "api_key": "${key}",  // change this with your API key
            "workspace_key": "${workspace_uid}",  // change this with your workspace key
            "event_id": "",
            "event_type": "account_identify",  // once the user signs in to the platform. 
            "ids": {},
            "anonymous_id": "random_id" ,  // Ideally, use the __eventn_id from the cookies to stitch the user activity.
            "user_id": "lzL24K3kYw",     // Unique ID for the user in database.
            "account_id": "65f02acc-e104-45d9-8199-1491332d8454",  // Unique ID for the account in database.
            "traits": {
              "name": "Hyperengage",
              "created_at": "2022-02-15T17:25:08",
              "plan_name": "plan",
              "industry": "industry",
              "trial_start_date": "trial_start",
              "trial_expiry_date": "trial_end",
              "website": "website" 
               // Optional attributes (you can name attributes what you wish)
            },
             // Send company level attributes if no account_id is present
            "screen_resolution": "0",
            "src": "http"
        }`,
          title: "Identify Your Accounts",
          desc: "After connecting your data source with the base tracking script send over your account/company data.",
        },
        {
          script: `{
            "api_key": "${key}",  // change this with your API key
            "workspace_key": "${workspace_uid}",  // change this with your workspace key
            "event_id": "",
            "event_type": "user_identify",  // once the user signs in to the platform. 
            "ids": {},
            "anonymous_id": "random_id" ,  // Ideally, use the __eventn_id from the cookies to stitch the user activity.
            "user_id": "lzL24K3kYw",     // Unique ID for the user in database.
            "account_id": "65f02acc-e104-45d9-8199-1491332d8454",  // Unique ID for the account in database.
            "traits": {
               // Required attributes for users in user_identify
              "email": "user@domain.com",  // Email address.
              "created_at": "2021-01-20T09:55:35",    // DateTime string in your system that represents when the user first signed up.
              "name": "John Doe"  // Full name.
               // Optional attributes (you can name attributes what you wish)
            },
             // Send company level attributes if no account_identify is present
            "company": {
              "id": "65f02acc-e104-45d9-8199-1491332d8454",
              "name": "zeeshan",
              "created_at": "2022-02-15T17:25:08",
              "plan_name": "plan",
              "industry": "industry",
              "trial_start_date": "trial_start",
              "trial_expiry_date": "trial_end",
              "website": "website"
              },
            "screen_resolution": "0",
            "src": "http"
        }`,
          title: "Identify Your Users",
          desc: "You can attach any properties that you want to our users eg their job role, industry etc.",
        },
        {
          script: `{
            "api_key": "${key}",  // change this with your API key
            "workspace_key": "${workspace_uid}",  // change this with your workspace key
            "event_id": "",
            "event_type": "CUSTOM_EVENT", 
            "ids": {},
            "anonymous_id": "random_id" ,  // Ideally, use the __eventn_id from the cookies to stitch the user activity.
            "user_id": "lzL24K3kYw",     // Unique ID for the user in database.
            "account_id": "65f02acc-e104-45d9-8199-1491332d8454",  // Unique ID for the account in database.
            "properties": {
              "plan_name": "Enterprise",
              "industry": "SaaS",
              "trial_start_date": "2022-02-15T17:25:08",
              "trial_expiry_date": "2022-02-15T17:25:08",
              "website": "https://hyperengage.io"
            },
            "screen_resolution": "0",
            "src": "http"
        }`,
          title: "Track your custom events",
          desc: "Track key actions that users perform in your  product so you can accurately measure their adoption and retention. If you would like to send the custom event, the only thing that is going to be changed from the above payload is event_type. For sending additional custom event attributes, you can pass the attributes in the form of properties object.",
        },
      ];
    default:
      return [];
  }
};

export default stepSelector;
