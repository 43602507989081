/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
import { Tooltip } from "@mantine/core";
import { IconCircleFilled } from "@tabler/icons-react";
import { formatDistance } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { ICellTextProps } from "ka-table/props";
import React from "react";

/**
 * Custom cell component.
 */
const TopFeatureCell: React.FC<ICellTextProps> = React.memo(
  ({
    column, dispatch, rowKeyValue, ...props
  }) => {
    const value = props.rowData[`${column.key}`];
    const localTime = zonedTimeToUtc(new Date(props?.rowData?.launched?.replace(" ", "T")), "UTC");
    const date = formatDistance(localTime, new Date(), {
      addSuffix: true,
    });

    return (
      <Tooltip position="left" withinPortal multiline width={250} classNames={{ tooltip: "text-xs bg-gray-700 bg-opacity-80" }} label={`Last time ${value} was launched was ${date}`}>
        <span className="whitespace-nowrap flex items-center gap-1.5 cursor-pointer text-gray-500 w-full text-ellipsis overflow-hidden !px-1 !py-2">
          <IconCircleFilled className="w-3 h-3 shrink-0 text-gray-300" />
          {" "}
          {value}
        </span>
      </Tooltip>
    );
  },
);
export default TopFeatureCell;
