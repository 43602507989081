/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { PlusIcon } from "@heroicons/react/20/solid";
import { useTraitsAndMetrics } from "../../../hooks/QueryHooks";
import RowEntitySelect from "../../../shared/filter/RowEntitySelect";
import HeButton from "../../../shared/ui/buttons/HeButton";
import useWorkspaceStore from "../../../store/workspace/workspaceStore";
import ConditionsMeet from "../signals/new-signal/components/ConditionsMeet";

type Props = {
  selectedFilters?: any;
  handleClear?: any;
  handleFilter?: any;
  handleRemove?: any,
  entityType?: "accounts" | "users";
  onSave: () => void;
  filterId?: number;
  time_window: string;
  handleAdd?: any;
  total?: number;
  disabled?: boolean;
};

const GettingStartedViewFilterContainer = ({
  disabled = false,
  total = 0,
  entityType = "accounts",
  onSave,
  selectedFilters,
  handleClear,
  handleFilter,
  handleRemove,
  handleAdd,
  filterId,
  time_window,
}: Props) => {
  const workspace = useWorkspaceStore((state) => state.workspace);
  const { data: traitsAndMetrics }: any = useTraitsAndMetrics({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
  });

  return (
    <div className="sm:flex sm:items-center px-5 py-2 w-full">
      <div className="overflox-auto flex flex-col w-full gap-2">
        {selectedFilters && selectedFilters?.map((filter: any, index: number) => (
          <RowEntitySelect
            filter={filter}
            entityRelation={entityType}
            time_window={time_window}
            key={filter?.field}
            onRemove={handleRemove}
            traitsAndMetrics={traitsAndMetrics}
            currentKey={index}
            filterId={filterId!}
            onCompleteFilter={handleFilter}
          />
        ))}
        <ConditionsMeet total={total} entityRelation="accounts" />
        <div className="w-full flex justify-between pb-3 pt-2">
          <button
            type="button"
            onClick={() => { handleAdd(); }}
            className="flex rounded-lg hover:text-purple-800 items-center p-1 text-sm font-medium text-purple-500"
          >
            <PlusIcon className="mr-[1px] flex-shrink-0 h-[16px] w-[16px]" />
            Add Condition
          </button>
          <div className="flex gap-2 items-center">
            <button
              type="button"
              onClick={handleClear}
              className="flex rounded-lg hover:text-purple-800 items-center p-1 text-xs font-medium text-purple-500"
            >
              Clear All
            </button>
            <HeButton
              type="button"
              disabled={disabled}
              onClick={() => {
                onSave();
              }}
              text="Create View"
              size="xs"
              className="p-2 px-4"
              color="purple"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GettingStartedViewFilterContainer;
