/* eslint-disable */
import React from 'react';
/* @ts-ignore */
import CryptoJS from 'crypto-js';

function computeHash (email = '') {
  // Trim the leading and trailing whitespaces
  // Transform to all lowercase characters
  // Compute the MD5 hash
  return CryptoJS.MD5(email.trim().toLowerCase());
}
const avatarGenerator = ({
  img, desc, size,
} : { img?: string, desc?: string, size?: number }) => {
  if (img) {
    return img;
  }
  if (desc) {
    if (desc.startsWith('http')) {
      const temp = desc.replaceAll('http://', '').replaceAll('https://', '');
      return `https://logo.clearbit.com/${desc.length > 0 ? desc : 'temp-mail.org'}?size=700`;
    } else if(!desc.includes('.')) {
      return `https://logo.clearbit.com/${desc.length > 0 ? `${desc}.com` : 'temp-mail.org'}?size=700`;
    } else {
      return `https://www.gravatar.com/avatar/${computeHash(desc)}.jpg?s=${size || 80}?&d=identicon`;
    }
  }
  return ``;
};

export default avatarGenerator;
