/* eslint-disable import/prefer-default-export */
import { authorizedApiClient } from "./config";

type TypeAhead = {
  workspace_identifier: string,
  entity_type: "accounts" | "users",
  search_query: string,
  selector_field: string,
  limit?: number,
  offset?: number,
};

export const getTypeAhead = async ({
  workspace_identifier,
  entity_type,
  search_query,
  selector_field,
  limit = 100,
  offset = 0,
}: TypeAhead) => authorizedApiClient.post(`/${workspace_identifier}/utils/typeahead`, {
  entity_type,
  search_query,
  selector_field,
  limit,
  offset,
});
