/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { LoadingOverlay, Text } from "@mantine/core";
import { ITableProps, kaReducer } from "ka-table";
import {
  hideLoading, loadData, showLoading, updateData, updatePageIndex, updatePagesCount,
} from "ka-table/actionCreators";
import { ActionType, SortDirection, SortingMode } from "ka-table/enums";
import { DispatchFunc } from "ka-table/types";
import { capitalize, isEqual } from "lodash";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { useTraitsAndMetrics, useViews, useWorkspaceMembers } from "../../../hooks/QueryHooks";
import { getWorkspaceTrendsAgaintQuery } from "../../../services/workspace/workspaceEndpoints";
import KaTable, { IColumn, ISortBy } from "../../../shared/table/KaTable";
import useViewStore from "../../../store/view/viewStore";
import useWorkspaceStore from "../../../store/workspace/workspaceStore";
import parseTableData from "../../../utils/parseTable";
import AccountControls from "./components/AccountControls";
import AccountHeader from "./components/AccountHeader";

const AccountsPage = () => {
  const { viewId } = useParams<{ viewId: string }>();
  const location = useLocation();
  const { updateView, setView } = useViewStore((state: any) => ({
    updateView: state.updateView,
    setView: state.setView,
  }));

  // Making current view transient as explained in zustand docs
  const storedView = useRef<any>(useViewStore.getState().view);

  const currentView = storedView.current;

  useEffect(() => {
    useViewStore.subscribe(
      (state: any) => { storedView.current = state.view; },
    );
  }, []);

  const workspace = useWorkspaceStore((state) => state.workspace);

  const { data: workspaceMembers, isLoading, isSuccess }: any = useWorkspaceMembers({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
    retry: 2,
  });

  const traitsAndMetrics: any = useTraitsAndMetrics({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
  });

  const views: any = useViews({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
  });

  useEffect(() => {
    if (viewId && views.isSuccess && views?.data?.length && !location.pathname.includes("new")) {
      setView(views?.data?.find((view: any) => view.id === viewId));
    }
  }, [viewId, views.isSuccess]);

  const traits: any = currentView?.entity_relation === "accounts" ? traitsAndMetrics?.data?.accountTraits : traitsAndMetrics?.data?.contactTraits;

  const metrics: any = currentView?.entity_relation === "accounts" ? traitsAndMetrics?.data?.accountMetrics : traitsAndMetrics?.data?.contactMetrics;

  const tableRef = useRef<any>(null);
  const [noResults, setNoResults] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(
    0,
  );

  const tableHeight = useMemo(
    () => (window.innerHeight - 310),
    [],
  );

  const [tableProps, changeTableProps] = useState<ITableProps>({
    columns: [],
    columnResizing: true,
    paging: {
      enabled: true,
      pageIndex: 0,
      pagesCount: 1,
      pageSize: 10,
    },
    columnReordering: true,
    rowKeyField: "randomId",
    height: tableHeight,
    singleAction: loadData(),
    sortingMode: SortingMode.SingleRemote,
    loading: {
      enabled: true,
      text: "Loading",
    },
  });

  const onSortChange = useCallback((payload: { field: string, order: string, type?: string }) => {
    const { field, order } = payload;
    updateView({
      sort_fields: {
        field,
        order,
        type: { ...traits?.obj, ...metrics?.obj }[field].attribute_type === "metric_traits" ? "changeval" : "property",
      },
    });
  }, [traits?.obj, metrics?.obj, currentView]);

  const kaDispatch: DispatchFunc = (action: any) => {
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
    const sortColumn: any = tableProps.columns.find((c) => c.sortDirection);
    if (action.type === ActionType.UpdateSortDirection) {
      // change sort direction
      let newSortDirection = SortDirection.Ascend;
      if (action.columnKey === sortColumn?.key) {
        newSortDirection = sortColumn?.sortDirection === SortDirection.Ascend ? SortDirection.Descend : SortDirection.Ascend;
      }
      if (tableProps.sortingMode === SortingMode.SingleRemote) {
        onSortChange({
          field: action.columnKey,
          order: newSortDirection === SortDirection.Ascend ? "asc" : "desc",
          type: undefined,
        });
      }
    }
  };

  const fetchData = async (page: number, sortedBy: ISortBy, signal: any) => {
    if (workspace?.id && currentView?.id) {
      kaDispatch(showLoading());
      try {
        const response: any = await getWorkspaceTrendsAgaintQuery(
          {
            workspaceId: workspace?.id,
            entityType: currentView?.entity_relation,
            signal,
            query: {
              include_count: true,
              page: page + 1,
              per_page: 10,
              filter: {
                ...currentView?.filters,
                predicates: currentView?.filters?.predicates?.filter((fil: any) => fil?.predicates?.filter((item: any) => item.field !== "").length > 0).map((fil: any) => ({
                  ...fil,
                  predicates: fil?.predicates?.filter((item: any) => item.field !== ""),
                })),
              },
              selected_fields: currentView?.selected_columns || [],
              sort: {
                field: sortedBy?.key || currentView?.sort_fields?.field,
                order: sortedBy?.direction === SortDirection.Ascend ? "asc" : "desc",
                type: sortedBy?.type || ({ ...traits.obj, ...metrics.obj }[sortedBy?.key]?.attribute_type === "metric_traits" ? "changeval" : "property"),
              },
              metrics: currentView?.metrics,
              time_window: currentView?.metrics_time_frame,
              time_zone: workspace?.workspace_time_zone,
            },
          },
        );
        if (response?.data) {
          setTotal(response.total_results);
          const list = parseTableData({ data: response.data, memberData: workspaceMembers?.users });
          kaDispatch(updateData(list));
          if (response.total_results < ((tableProps?.paging?.pageIndex || 0) * (tableProps?.paging?.pageSize || 1))) {
            kaDispatch(updatePageIndex(0));
          }
          kaDispatch(updatePagesCount(Math.ceil(response.total_results / (tableProps?.paging?.pageSize ?? 1)) || 0));
          kaDispatch(hideLoading());
        } else {
          setTotal(0);
          setNoResults(true);
          kaDispatch(updatePageIndex(0));
          kaDispatch(updateData([]));
          kaDispatch(updatePagesCount(1));
          kaDispatch(hideLoading());
        }
      } catch (err: any) {
        if (err?.message === "Component unmounted or effect re-ran") {
          return;
        }
        setTotal(0);
        setNoResults(true);
        kaDispatch(updatePageIndex(0));
        kaDispatch(updateData([]));
        kaDispatch(updatePagesCount(1));
        kaDispatch(hideLoading());
      }
    }
  };

  useEffect(() => {
    document.title = `${
      currentView?.view_name ? `${capitalize(currentView?.view_name)} | ` : ""
    }View | Hyperengage`;
  }, [currentView?.view_name]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (isSuccess && !!traits?.obj && !!metrics?.obj && (currentView?.selected_columns?.length || currentView?.metrics?.length) && !isEqual(tableProps?.columns, [...currentView.selected_columns, ...currentView.metrics])) {
      changeTableProps((state: any) => ({
        ...state,
        columns: [...(currentView?.selected_columns || []), ...(currentView?.metrics || []), "related_accounts"]
          .reduce((arr: any, item: any) => {
            if (!{ ...traits.obj, ...metrics?.obj }[item]
            || { ...traits?.obj, ...metrics?.obj }[item]?.exclude_display) {
              if (item === "related_accounts" && currentView?.entity_relation === "users") {
                arr.push({
                  key: "related_accounts",
                  title: "Related Accounts",
                  isResizable: true,
                  colGroup: { style: { minWidth: 50, width: 200 } },
                  isSortable: true,
                  dataType: "object",
                  attributeType: "trait",
                });
              }
              return arr;
            }
            const dat: any = {
              key: { ...traits?.obj, ...metrics?.obj }[item].name,
              dataType: !item.includes("metric") ? { ...traits?.obj, ...metrics?.obj }[item].data_type : "object",
              title: { ...traits?.obj, ...metrics?.obj }[item].display_name,
              isResizable: true,
              colGroup: { style: { minWidth: 50, width: 200 } },
              isSortable: true,
              attributeType: { ...traits?.obj, ...metrics?.obj }[item].attribute_type,
            };
            if (currentView?.sort_fields?.field === item) {
              dat.sortType = currentView?.sort_fields?.type;
              dat.sortDirection = currentView?.sort_fields?.order === "asc" ? SortDirection.Ascend : SortDirection.Descend;
            }
            if (item === "accounts.name" || item === "users.name") {
              arr.unshift(dat);
            } else {
              arr.push(dat);
            }
            return arr;
          }, [] as IColumn[]),
      }));
    }
    fetchData(tableProps?.paging?.pageIndex || 0, {
      key: currentView?.sort_fields?.field,
      direction: currentView?.sort_fields?.order === "asc" ? SortDirection.Ascend : SortDirection.Descend,
      type: currentView?.sort_fields?.type,
    }, source);
    return () => {
      // Cancel the fetch request when the component unmounts or the effect re-runs
      source.cancel("Component unmounted or effect re-ran");
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView, metrics?.obj, traits?.obj, tableProps?.paging?.pageIndex, isSuccess]);

  return (
    <div className="px-12 py-8 pb-5 overflow-auto relative h-full bg-gray-100">
      <div className="w-content">
        {currentView?.id && <AccountHeader key={currentView?.id} currentView={currentView} />}
      </div>
      <div className="mx-auto">
        <div className="py-1 bg-white border-2 border-gray-200 rounded-md">
          {traits && (
          <AccountControls
            traits={traits}
            metrics={metrics}
            count={total}
            currentView={currentView}
          />
          )}
          <LoadingOverlay loaderProps={{ size: "sm" }} c="main.5" visible={isLoading} overlayBlur={2} />
          <KaTable
            tableProps={tableProps}
            changeTableProps={changeTableProps}
            ref={tableRef}
            totalData={total}
            kaDispatch={kaDispatch}
            noDataTitle={currentView?.entity_relation === "accounts" ? "No Accounts Available" : "No Contacts Available"}
            noDataMessage={"We couldn't find any data for the given filters"}
            itemType={currentView?.entity_relation === "accounts" ? "Accounts" : "Contacts"}
            rowKeyField="randomId"
          />
        </div>
        <Text size="xs" italic p={5} c="text.4" className="mt-2">
          <a href="https://clearbit.com">
            Logos provided by
            {" "}
            <Text span c="main.6">
              Clearbit
            </Text>
          </a>
        </Text>
      </div>
    </div>
  );
};

export default AccountsPage;
