import { authorizedApiClient } from "../config";
import {
  CreateAutomation, ReadAllAutomations, ReadAllAutomationsResponse,
  ReadAutomation, ReadLogsById, ReadLogsByIdResponse, UpdateAutomation,
} from "./automationEndpoints.interface";

export const createAutomation = async ({
  name,
  description,
  trigger,
  entity_relation,
  automation_type,
  is_precreated,
  filters,
  metrics,
  status,
  automation_actions,
  time_window,
  selected_fields,
  time_zone,
  owner_id,
  workspace_id,
}: CreateAutomation) => authorizedApiClient.post<CreateAutomation>(`/${workspace_id}/automation`, {
  name,
  description,
  trigger,
  entity_relation,
  automation_type,
  is_precreated,
  filters,
  metrics,
  status,
  automation_actions,
  time_window,
  selected_fields,
  time_zone,
  owner_id,
  workspace_id,
});

export const updateAutomation = async ({
  name,
  description,
  trigger,
  entity_relation,
  automation_type,
  is_precreated,
  filters,
  metrics,
  status,
  automation_actions,
  time_window,
  selected_fields,
  time_zone,
  automation_id,
  workspace_id,
}: { automation_id: string, workspace_id: string } & UpdateAutomation) => authorizedApiClient.put<CreateAutomation>(`/${workspace_id}/automation/${automation_id}`, {
  name,
  description,
  trigger,
  entity_relation,
  automation_type,
  is_precreated,
  filters,
  metrics,
  status,
  automation_actions,
  time_window,
  selected_fields,
  time_zone,
});

export const readAutomation = async ({
  workspace_id,
  automation_id,
}: ReadAutomation) => authorizedApiClient.get<ReadAutomation>(`/${workspace_id}/automation/${automation_id}`);

export const deleteAutomation = async ({
  workspace_id,
  automation_id,
}: ReadAutomation) => authorizedApiClient.delete<void>(
  `/${workspace_id}/automation/${automation_id}`,
);

export const readAllAutomations = async ({
  workspace_id,
  automation_type,
  skip = 0,
  limit = 100,
}: ReadAllAutomations) => authorizedApiClient.get<ReadAllAutomationsResponse>(`/${workspace_id}/automations/all`, {
  params: { automation_type, skip, limit },
});

export const readAutomationLogs = async ({
  workspace_id,
  automation_id,
  status,
  automation_type,
  skip = 0,
  limit = 20,
}: ReadLogsById) => {
  const params: any = {
    skip,
    limit,
  };

  if (workspace_id !== undefined) {
    params.workspace_id = workspace_id;
  }

  if (automation_id !== undefined) {
    params.automation_id = automation_id;
  }

  if (status !== undefined) {
    params.status = status;
  }

  if (automation_type !== undefined) {
    params.automation_type = automation_type;
  }

  return authorizedApiClient.get<ReadLogsByIdResponse>(`/${workspace_id}/automation_logs`, {
    params,
  });
};
