/* eslint-disable import/prefer-default-export */
import qs from "qs";
import { authorizedApiClient } from "../config";
import {
  CreateSignalComment, ReadSignalRuns, ReadSignalRunsResponse, UpdateSignalComment, UpdateSignalRun,
} from "./inboxEndpoints.interface";

export const readSignalRuns = async ({
  workspace_id,
  time_start,
  time_end,
  playbook_id,
  automation_id,
  signal_status,
  notification_type,
  owner_id,
  entity_relation,
}:ReadSignalRuns) => {
  const params: any = {};
  if (time_start) {
    params.time_start = time_start;
  }
  if (time_end) {
    params.time_end = time_end;
  }
  if (playbook_id) {
    params.playbook_id = playbook_id;
  }
  if (automation_id) {
    params.automation_id = automation_id;
  }
  if (signal_status) {
    params.signal_status = signal_status;
  }
  if (notification_type) {
    params.notification_type = notification_type;
  }

  if (owner_id) {
    if (owner_id?.includes("unassigned")) {
      params.owner_id = owner_id?.map((id: any) => (id === "unassigned" ? "null" : id));
    } else {
      params.owner_id = owner_id;
    }
  }

  if (entity_relation) {
    params.entity_relation = entity_relation;
  }

  return authorizedApiClient.get<ReadSignalRunsResponse>(`/${workspace_id}/signal_runs`, {
    params,
    paramsSerializer(para: any) {
      return qs.stringify(para, { arrayFormat: "repeat" });
    },
  });
};

export const createSignalComment = async ({
  workspace_id,
  comment,
  user_id,
  signal_id,
}: CreateSignalComment & { workspace_id: string }) => authorizedApiClient.post<{
  comment: string;
  id: string;
}>(`/${workspace_id}/signal_comments`, {
  comment,
  user_id,
  signal_id,
});

export const updateSignalComment = async ({
  workspace_id,
  signal_comment_id,
  comment,
}: UpdateSignalComment &
{ workspace_id: string; signal_comment_id: string }) => authorizedApiClient.put<{
  comment: string;
  id: string;
}>(`/${workspace_id}/signal_comments/${signal_comment_id}`, {
  comment,
});

export const deleteSignalComment = async ({
  workspace_id,
  signal_comment_id,
}: {
  workspace_id: string;
  signal_comment_id: string;
}) => authorizedApiClient.delete<{
  comment: string;
  id: string;
}>(`/${workspace_id}/signal_comments/${signal_comment_id}`);

export const updateSignalRun = async ({
  workspace_id,
  signal_run_id,
  status,
}: UpdateSignalRun & { workspace_id: string; signal_run_id: string }) => authorizedApiClient.put<{
  external_identifier: string;
  owner_id: string;
  workspace_id: string;
  automation_id: string;
  playbook_id: string;
  notification_type: string;
  status: string;
  id: string;
  created_at: string;
  updated_at: string;
}>(`/${workspace_id}/signal_runs/${signal_run_id}`, {
  status,
});
