/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import {
  ActionIcon, Collapse, Flex, Paper, Text,
} from "@mantine/core";
import { ITableProps, kaReducer } from "ka-table";
import {
  hideLoading, loadData, updateData, updatePageIndex, updatePagesCount,
} from "ka-table/actionCreators";
import { SortDirection, SortingMode } from "ka-table/enums";
import { DispatchFunc } from "ka-table/types";
import {
  useEffect,
  useRef,
  useState,
} from "react";
import { getWorkspaceTrendsAgaintQuery } from "../../../../../services/workspace/workspaceEndpoints";
import { ENTITY } from "../../../../../services/workspace/workspaceEndpoints.enums";
import EmptyTablePlaceholder from "../../../../../shared/table/EmptyTablePlaceHolder";
import KaTable, { ISortBy } from "../../../../../shared/table/KaTable";
import useWorkspaceStore from "../../../../../store/workspace/workspaceStore";
import parseTableData from "../../../../../utils/parseTable";

type Props = {
  accountId: string,
  tableHeight: number,
  containerClass?: string,
  headingClass?: string,
  defaultOpen?: boolean,
};

const ActiveUsers = ({
  accountId,
  tableHeight,
  headingClass,
  defaultOpen = true,
  containerClass,
}: Props) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const workspace = useWorkspaceStore((state) => state.workspace);
  const [noResults, setNoResults] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const tableRef = useRef<any>(null);

  const [tableProps, changeTableProps] = useState<any>({
    columns: [
      {
        key: "users.name",
        dataType: "object",
        title: "Name",
        isResizable: true,
        colGroup: { style: { minWidth: 50 } },
        isSortable: true,
        attributeType: "object_traits",
      }, {
        key: "default_metric_days_active",
        title: "Days Active",
        dataType: "object",
        isResizable: true,
        colGroup: { style: { minWidth: 50 } },
        sortDirection: SortDirection.Descend,
        isSortable: true,
        attributeType: "metric_traits",
      },
    ],
    columnResizing: true,
    paging: {
      enabled: true,
      pageIndex: 0,
      pagesCount: 1,
      pageSize: 5,
    },
    rowKeyField: "randomId",
    height: tableHeight,
    singleAction: loadData(),
    sortingMode: SortingMode.SingleRemote,
    loading: {
      enabled: true,
      text: "Loading",
    },
  });

  const kaDispatch: DispatchFunc = (action: any) => {
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
  };

  const fetchData = async (page: number, sortedBy: ISortBy) => {
    try {
      const response: any = await getWorkspaceTrendsAgaintQuery(
        {
          workspaceId: workspace?.id,
          entityType: ENTITY.USERS,
          query: {
            include_count: true,
            page: page + 1,
            per_page: 5,
            filter: {
              query_type: "AND",
              predicates: [
                {
                  field: "accounts.external_id",
                  operator: "eq",
                  type: "string",
                  value: accountId,
                  entity_type: "column",
                },
              ],
            },
            selected_fields: ["users.name", "users.avatar_url", "users.email", "users.external_id"],
            sort: {
              field: sortedBy?.key,
              order: sortedBy?.direction === SortDirection.Ascend ? "asc" : "desc",
              type: sortedBy?.type,
            },
            metrics: ["default_metric_days_active"],
            time_window: "all_time",
            time_zone: workspace?.workspace_time_zone,
          },
        },
      );

      if (response?.data?.length) {
        setNoResults(false);
        setTotal(response.total_results);
        const list = parseTableData({ data: response.data });
        kaDispatch(updateData(list));
        if (response.total_results
          < ((tableProps?.paging?.pageIndex || 0) * (tableProps?.paging?.pageSize || 1))) {
          kaDispatch(updatePageIndex(0));
        }
        kaDispatch(updatePagesCount(
          Math.ceil(response.total_results / (tableProps?.paging?.pageSize ?? 1)) || 0,
        ));
        kaDispatch(hideLoading());
      } else {
        setTotal(0);
        setNoResults(true);
        kaDispatch(updatePageIndex(0));
        kaDispatch(updateData([]));
        kaDispatch(updatePagesCount(1));
        kaDispatch(hideLoading());
      }
    } catch (err) {
      setTotal(0);
      setNoResults(true);
      kaDispatch(updatePageIndex(0));
      kaDispatch(updateData([]));
      kaDispatch(updatePagesCount(1));
      kaDispatch(hideLoading());
    }
  };

  useEffect(() => {
    if (accountId) {
      const sortColumn: any = tableProps.columns.find((c: any) => c.sortDirection);
      fetchData(tableProps?.paging?.pageIndex || 0, {
        key: sortColumn.key,
        direction: sortColumn.sortDirection,
        type: sortColumn.key === "users.name" ? "property" : "growthval",
      });
    }
  }, [tableProps?.paging?.pageIndex, tableProps.columns, accountId]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Paper shadow="md" radius="md" className={`border-2 border-gray-200 ${containerClass || ""}`}>
      <Flex align="center" p="sm" className="border-b-2" justify="space-between">
        <Text size={20} c="text.9" fw={600} className={headingClass}>
          Power Users
        </Text>
        <ActionIcon onClick={handleToggle} color="dark" size="xs">
          <ChevronUpIcon style={{ transform: isOpen ? "rotate(0)" : "rotate(180deg)" }} />
        </ActionIcon>
      </Flex>
      <Collapse in={isOpen}>
        {noResults ? (<EmptyTablePlaceholder height={tableHeight} />)
          : (
            <KaTable
              tableProps={tableProps}
              totalData={total}
              kaDispatch={kaDispatch}
              rowKeyField="randomId"
              changeTableProps={changeTableProps}
              noDataTitle="No Recent User Activity"
              ref={tableRef}
              itemType="Users"
            />
          )}
      </Collapse>
    </Paper>
  );
};

export default ActiveUsers;
