import {
  ActionIcon, Collapse,
  Flex,
  Paper,
  Spoiler,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  useMemo,
  useRef, useState,
} from "react";

/* eslint-disable react-hooks/exhaustive-deps */
import {
  CheckIcon,
  ChevronUpIcon,
  PlusIcon, XMarkIcon,
} from "@heroicons/react/20/solid";
import uuid from "react-uuid";
import { useTraitsAndMetrics } from "../../../../hooks/QueryHooks";
import MButton from "../../../../shared/ui/buttons/MButton";
import MultiSelectDropdown from "../../../../shared/ui/dropdown/MultiSelectDropdown";
import HeaderTrait from "../../account-details/components/HeaderTraits";
import useWorkspaceStore from "../../../../store/workspace/workspaceStore";

type Props = {
  account: any,
  defaultOpen?: boolean,
};

const HqAccountTraits = ({ account, defaultOpen = true }: Props) => {
  const [opened, handlers] = useDisclosure(defaultOpen);
  const [edit, setEdit] = useState<boolean>(false);
  const [showHidden, setShowHidden] = useState(false);
  const workspaceId = useWorkspaceStore((state) => state.workspace?.id);

  const [displayedTraits, setDisplayedTraits] = useState([
    "accounts.name",
    "accounts.arr",
    "account.avatar_url",
    "accounts.created_at",
    "accounts.csm_id",
    "accounts.first_payment_date",
    "accounts.is_ignored",
    "accounts.last_renewal_date",
    "accounts.mrr",
    "accounts.ae_id",
    "accounts.trial_expiry_date",
    "accounts.account_champion_id",
  ]);

  const onSelectTrait = (trait: any) => {
    setDisplayedTraits((selectedColumns: any) => (selectedColumns.includes(trait.name)
      ? selectedColumns.filter((name: any) => name !== trait.name)
      : [...selectedColumns, trait.name]));
  };

  const originalTraits = useRef<string[]>(displayedTraits);

  const { data }: any = useTraitsAndMetrics({
    workspaceId,
    enabled: !!workspaceId,
  });

  const shownTraits = useMemo(() => Object
    .entries(account || {}).reduce((obj: any, [trKey, trValue]: any) => {
      if (displayedTraits?.some((item: any) => item === `accounts.${trKey}`)) {
        const selectedTrait = data?.accountTraits?.obj[`accounts.${trKey}`];
        if (selectedTrait && !selectedTrait.exclude_display) {
          obj[trKey] = {
            ...selectedTrait,
            val: trValue,
          };
        }
      }
      return obj;
    }, {}), [
    account?.name,
    account?.arr,
    account?.avatar_url,
    account?.created_at,
    account?.csm_id,
    account?.first_payment_date,
    account?.is_ignored, account
      ?.last_renewal_date,
    account?.mrr,
    account?.ae_id,
    account?.trial_expiry_date,
    account?.account_champion_id,
    displayedTraits,
    data?.accountTraits?.obj]);

  const editHandler = () => {
    setEdit(!edit);
  };

  const handleDiscard = () => {
    setDisplayedTraits(originalTraits.current);
    setEdit(false);
  };

  const handleSave = () => {
    originalTraits.current = displayedTraits;
    setEdit(false);
  };

  const deleteHandler = (trait: any) => {
    setDisplayedTraits(displayedTraits.filter((item: any) => item !== trait));
  };

  const handleHidden = () => {
    setShowHidden((state) => !state);
  };

  return (
    <Paper shadow="sm" radius="md" className="border-2 border-gray-200" style={{ position: "relative" }}>
      <Flex align="center" p="xs" className="border-b-2" justify="space-between">
        <Text size="xs" lts={0.6} c="text.5" tt="uppercase" fw={600}>
          Traits
        </Text>
        <Flex gap={5} align="center">
          {!edit && <MButton color="white" size="xs" variant="outline" onClick={editHandler} radius="sm">Edit traits</MButton>}
          {edit && (
          <div className="flex items-center gap-2">
            <ActionIcon
              variant="outline"
              color="red"
              size="md"
              className="hover:bg-red-100"
              onClick={handleDiscard}
            >
              <XMarkIcon width={25} height={25} />
            </ActionIcon>
            <ActionIcon
              variant="filled"
              color="green"
              className="bg-emerald-500 hover:bg-emerald-600"
              size="md"
              onClick={handleSave}
            >
              <CheckIcon width={25} height={25} />
            </ActionIcon>
          </div>
          )}
          <ActionIcon onClick={handlers.toggle} color="text.5" size="xs">
            <ChevronUpIcon style={{ transform: opened ? "rotate(0)" : "rotate(180deg)" }} />
          </ActionIcon>
        </Flex>
      </Flex>
      <Collapse in={opened}>
        <Spoiler
          classNames={{ root: "p-1", control: "pl-3 text-xs" }}
          maxHeight={180}
          showLabel={`Show ${shownTraits ? Object.keys(shownTraits).length - 6 : ""} more`}
          hideLabel="Hide"
        >
          <div className="grid grid-flow-row grid-cols-2 gap-x-1 gap-y-3">
            {Object.values(shownTraits).map(
              (value: any) => (
                <HeaderTrait
                  edit={edit}
                  handleDelete={deleteHandler}
                  key={uuid()}
                  trait={value}
                />
              ),
            )}
          </div>
        </Spoiler>
        {edit && (
        <MultiSelectDropdown
          selected={displayedTraits}
          position="left"
          setSelected={onSelectTrait}
          isOpen={showHidden}
          setIsOpen={setShowHidden}
          list={data?.accountTraits?.list || []}
        >
          <div>
            <MButton size="xs" onClick={handleHidden} radius="sm" className="ml-3 my-4" leftIcon={<PlusIcon width={16} height={16} />} variant="outline" color="white">Add new trait</MButton>
          </div>
        </MultiSelectDropdown>
        )}
      </Collapse>
    </Paper>
  );
};

export default HqAccountTraits;
