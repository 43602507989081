/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex } from "@mantine/core";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useAccount, useWorkspaceMetricPreferences, useWorkspacePreferences,
} from "../../../../hooks/QueryHooks";
import ActiveUsers from "./components/ActiveUsers";
import TopN from "./components/TopN";
import TrendsContainer from "./components/TrendsContainer";
import useWorkspaceStore from "../../../../store/workspace/workspaceStore";
import SignalsContainer from "../../../../shared/signals/SignalsContainer";

const AccountOverview = () => {
  const [selectedSignal, setSelectedSignal] = useState<string | null>(null);

  const currentWorkspace = useWorkspaceStore((state) => state.workspace);

  const params = useParams<any>();
  const account: any = useAccount({
    accountId: params.accountId || "",
    workspaceId: currentWorkspace?.id,
    enabled: params.accountId !== "" && currentWorkspace !== "",
  });

  const prefs: any = useWorkspacePreferences({
    workspaceId: currentWorkspace?.id,
    enabled: !!currentWorkspace?.id,
  });
  const metrics: any = useWorkspaceMetricPreferences({
    workspaceId: currentWorkspace?.id,
    enabled: !!currentWorkspace?.id,
  });

  const tableHeight = useMemo(
    () => (window.innerHeight * 0.35),
    [],
  );

  const handleSelectSignal = (acc: any, signal: any, select: boolean, entity: any) => {
    setSelectedSignal(signal);
  };

  const onTabClick = (tab: string) => {
    setSelectedSignal(null);
  };

  return (
    <Flex>
      {account?.data?.external_id && (
        <>
          <div className="basis-[67%] max-h-full self-stretch">
            {prefs.isSuccess && metrics.isSuccess
            && (
            <TrendsContainer
              prefs={prefs}
              metrics={metrics?.data?.filter((m: any) => m.linked_entity === "accounts")}
              account={account?.data}
              currentWorkspace={currentWorkspace}
            />
            )}
            <SignalsContainer
              workspace={currentWorkspace}
              onAccountClick={handleSelectSignal}
              onTabClick={onTabClick}
              accountId={account?.data?.external_id}
              selectedSignal={selectedSignal}
              accountSignal
            />
          </div>
          <div className="basis-[33%] p-4 max-h-full self-stretch">
            <div className="mb-3">
              <TopN accountId={account?.data?.external_id} tableHeight={tableHeight} />
            </div>
            <ActiveUsers tableHeight={tableHeight} accountId={account?.data?.external_id} />
          </div>
        </>
      )}
    </Flex>
  );
};

export default AccountOverview;
