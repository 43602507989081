/* eslint-disable jsx-a11y/interactive-supports-focus */
import { Stack, Text } from "@mantine/core";
import Pill from "../../../../shared/ui/badges/Pill";
import ProfileImg from "../../../../shared/ui/icons/ProfileImg";

type Props = {
  name?: string,
  desc?: string,
  img?: string,
  mrr?: string,
  onClick?: any,
  selected?: boolean,
  id?: string,
};

const AvatarWithMrr = ({
  id,
  selected,
  mrr,
  name,
  img,
  desc,
  onClick,
} : Props) => (
  <button
    type="button"
    className={`${onClick && "cursor-pointer"} ease-linear px-3 py-2 ${selected ? "bg-purple-500 hover:bg-purple-500" : "hover:bg-gray-200"} rounded-lg duration-200 flex items-center gap-3`}
    onKeyDown={() => {
      onClick(id, selected);
    }}
    onClick={() => {
      onClick(id, selected);
    }}
  >
    <ProfileImg desc={desc} img={img} name={name} size="md" />
    <Stack spacing={2} className="overflow-hidden group" justify="center">
      <Text truncate size="sm" fw={600} c={selected ? "text.1" : "text-9"}>{name || "-"}</Text>
      <Pill
        color="green"
        rounded
        size="sm"
        className=" min-w-[40px] items-center justify-center"
        text={`$${mrr || 0}`}
      />
    </Stack>
  </button>
);

export default AvatarWithMrr;
