import { SegmentedControl } from "@mantine/core";

type Props = {
  tab: string,
  setTab: any,
  onTabClick: any,
};

const InboxTabs = ({ tab, setTab, onTabClick }: Props) => (
  <SegmentedControl
    value={tab}
    w={200}
    onChange={(value: any) => {
      setTab(value);
      onTabClick(null);
    }}
    data={[
      { label: "Accounts", value: "accounts" },
      { label: "Users", value: "users" },
    ]}
  />
);

export default InboxTabs;
