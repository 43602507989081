import {
  Flex, Paper, Stack, Text,
} from "@mantine/core";
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useAccountContacts, useViews } from "../../../../hooks/QueryHooks";
import Pill from "../../../../shared/ui/badges/Pill";
import ProfileImg from "../../../../shared/ui/icons/ProfileImg";
import ActiveUsers from "../../account-details/account-overview/components/ActiveUsers";
import TopN from "../../account-details/account-overview/components/TopN";
import KeyRole from "../../account-details/components/KeyRoles";
import HqAccountTraits from "./HqAccountTraits";
import HqUserTraits from "./HqUserTraits";
import UserActivityTablsSm from "./UserActivityTableSm";

type Props = {
  entity: any,
  workspaceId: any,
  entityRelation?: any,
};

const HqDetails = ({ entity, workspaceId, entityRelation = "accounts" }: Props) => {
  const params = useParams();
  const { data }: any = useViews({
    workspaceId,
  });

  const contacts: any = useAccountContacts({
    workspaceId,
    accountId: entity?.external_id,
    enabled: entity?.external_id !== "" && entityRelation === "accounts",
  });

  const defaultView = useMemo(() => (
    data?.filter((view: any) => view?.is_precreated)?.reduce((ob: any, val: any) => {
      ob[val?.entity_relation] = val;
      return ob;
    }, {} as any)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [data?.length]);

  let path: string;
  if (entityRelation === "accounts") {
    path = `/home/${params?.workspaceId}/view/${defaultView?.accounts?.id}/account/${entity?.external_id}/overview`;
  } else {
    path = `/home/${params?.workspaceId}/view/${defaultView?.users?.id}/user/${entity?.external_id}`;
  }

  const tableHeight = useMemo(
    () => (window.innerHeight * 0.35),
    [],
  );

  return (
    <Paper p={20} bg="white" radius="md" shadow="sm" key={entityRelation}>
      <Stack spacing={20}>
        <Flex gap={15}>
          <ProfileImg img={entity?.avatar_url} name={entity?.name} size="lg" />
          <Stack spacing={0.5} className="overflow-hidden group" justify="center">
            <Flex gap={10} justify="center" align="center">
              <Text truncate size="lg" fw={600} c="text.9">{entity?.name || "-"}</Text>
              {entityRelation === "accounts" && (
              <Pill
                color="green"
                rounded
                size="md"
                className=" min-w-[40px] items-center justify-center !font-semibold"
                text={`$${entity?.mrr || 0} MRR`}
              />
              )}
            </Flex>
            <Link
              className="text-xs text-purple-700 hover:text-purple-900 font-semibold "
              to={path}
            >
              View profile
            </Link>
          </Stack>
        </Flex>
        {entityRelation === "accounts"
          ? (
            <>
              <HqAccountTraits account={entity} />
              <ActiveUsers
                defaultOpen
                headingClass="text-xs py-1.5 pl-1 uppercase text-gray-500 font-semibold tracking-wider"
                tableHeight={tableHeight}
                accountId={entity?.external_id}
              />
              <TopN
                defaultOpen={false}
                accountId={entity?.external_id}
                headingClass="text-xs py-1.5 pl-1 uppercase text-gray-500 font-semibold tracking-wider"
                tableHeight={tableHeight - 50}
              />
              <KeyRole
                defaultOpen={false}
                key={`${entity?.external_id}`}
                collapsible
                containerClass="w-full h-auto border-2 shadow-sm"
                accountId={entity?.external_id}
                contacts={contacts?.data}
                csm={entity?.csm_id}
                ae={entity?.ae_id}
                owner={entity?.account_champion_id}
              />
            </>
          ) : (
            <>
              <HqUserTraits contact={entity} />
              <UserActivityTablsSm
                defaultOpen
                headingClass="text-xs py-1.5 pl-1 uppercase text-gray-500 font-semibold tracking-wider"
                tableHeight={tableHeight}
                userId={entity?.external_id}
              />
            </>
          )}
      </Stack>
    </Paper>
  );
};

export default HqDetails;
