import React from "react";
import { NavLink } from "react-router-dom";

type Props = {
  to: string,
  children: React.ReactNode,
  className? : string,
  LeadingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>,
};

const Tab = ({
  to,
  children,
  className,
  LeadingIcon,
} : Props) => {
  let linkStyle = "border-white flex items-center text-gray-500 hover:text-gray-700 border-b-2 hover:border-gray-600 py-2 px-2 text-sm";
  const activeLinkStyle = "flex items-center text-purple-500 hover:text-purple-700 border-b-2 border-purple-500 py-2 px-2 text-sm";

  if (className) {
    linkStyle = `${linkStyle} ${className}`;
  }

  return (
    <NavLink
      to={to}
    >
      {({ isActive }) => (
        <div className={isActive ? activeLinkStyle : linkStyle}>
          <div className="flex items-center">
            {LeadingIcon && (
            <LeadingIcon className="mr-3 flex-shrink-0 h-5 w-5" />
            )}
            {children}
          </div>
        </div>
      )}
    </NavLink>
  );
};

export default Tab;
