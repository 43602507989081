import { Group, Tooltip, useMantineTheme } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import React from "react";
import { NavLink } from "react-router-dom";

type Props = {
  text?: string, className? : string, to: string,
  NavIcon: React.ComponentType<React.SVGProps<SVGSVGElement>>,
  activeClassName?: string, count?: number,
  menu?: boolean,
  label?: string,
};

const NavigationLink = ({
  text,
  label,
  to = "#",
  count,
  className,
  activeClassName,
  NavIcon,
  menu = true,
} : Props) => {
  const { hovered, ref } = useHover();
  const theme = useMantineTheme();
  let linkStyle = `group ${menu ? "justify-between" : "justify-evenly"} text-gray-500 flex-nowrap ease-linear duration-300 border-l-6 border-transparent hover:text-gray-900 hover:bg-gray-100`;
  let activeLinkStyle = `group ${menu ? "justify-between" : "justify-evenly"} text-purple-600 flex-nowrap ease-linear duration-300 border-l-6 bg-purple-50 border-purple-600 hover:text-purple-800 hover:bg-purple-100`;

  if (className) {
    linkStyle = `${linkStyle} ${className}`;
  }

  if (activeClassName) {
    activeLinkStyle = `${activeLinkStyle} ${activeClassName}`;
  }

  return (
    <NavLink
      to={to}
    >
      {({ isActive }) => (
        <Tooltip opened={hovered && !menu} withinPortal label={label} position="right" zIndex={1000}>
          <Group
            ref={ref}
            c={theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[8]}
            align="center"
            position="apart"
            px="sm"
            py="sm"
            className={isActive ? activeLinkStyle : linkStyle}
          >
            <div className="flex items-center font-semibold text-sm">
              <NavIcon className={`${menu ? "mr-2" : ""} stroke-purple-400 flex-shrink-0 h-5 w-5 rounded-full`} />
              {text}
            </div>
            {count && (<span className={`px-3 py-1 text-[12px] text-gray-700 rounded-full ${isActive ? `${hovered ? "bg-purple-200" : "bg-purple-100"} text-purple-800 ` : `${hovered ? "bg-white text-gray-900" : "bg-gray-50"}`}`}>{count}</span>)}
          </Group>
        </Tooltip>
      )}
    </NavLink>
  );
};

export default NavigationLink;
