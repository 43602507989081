import {
  ClipboardIcon, EnvelopeIcon,
} from "@heroicons/react/20/solid";
import {
  Box,
  Center, SegmentedControl,
} from "@mantine/core";
import { ReactComponent as SlackIcon } from "../../../../../assets/images/slack.svg";

type Props = {
  selectedFilter: string;
  setSelectedFilter: (value: string) => void;
  selectedState: string;
  setSelectedState: (value: string) => void;
};

const SignalControls = ({
  selectedFilter,
  setSelectedFilter,
  selectedState,
  setSelectedState,
}: Props) => (
  <div className="sm:flex items-center px-5 py-2">
    <div className="sm:flex-auto items-center">
      <h2 className="text-[16px] font-semibold text-gray-800">
        Signals
      </h2>
    </div>
    <div className="flex gap-3 py-1 justify-end flex-wrap items-end">
      <SegmentedControl
        color="main.6"
        size="sm"
        value={selectedFilter}
        onChange={setSelectedFilter}
        data={[
          {
            value: "all",
            label: (
              <Center>
                <Box>All</Box>
              </Center>
            ),
          },
          {
            value: "send_to_slack",
            label: (
              <Center>
                <SlackIcon className="w-4 h-4" />
                <Box ml={10}>Slack</Box>
              </Center>
            ),
          },
          {
            value: "send_to_inbox",
            label: (
              <Center>
                <ClipboardIcon className="w-4 h-4" />
                <Box ml={10}>Inbox Only</Box>
              </Center>
            ),
          },
          {
            value: "send_to_email",
            label: (
              <Center>
                <EnvelopeIcon className="w-4 h-4" />
                <Box ml={10}>Email</Box>
              </Center>
            ),
          },
        ]}
      />
      <SegmentedControl
        value={selectedState}
        color="main.6"
        size="sm"
        onChange={setSelectedState}
        data={[
          { value: "all", label: "All" },
          { value: "active", label: "On" },
          { value: "paused", label: "Off" },
        ]}
      />
    </div>
  </div>
);

export default SignalControls;
