/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
import { format, parseISO, to } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { ICellTextProps } from "ka-table/props";
import React from "react";

/**
 * Custom cell component.
 */
const AutomationTimestampCell: React.FC<ICellTextProps> = React.memo(
  ({
    column, dispatch, rowKeyValue, ...props
  }) => {
    const value = props.rowData[`${column.key}`];
    const parsedDate = parseISO(`${`${value}Z`}`);
    const formattedDate = format(parsedDate, "MMM d, yyyy, h:mm:ss a");
    return <span className="whitespace-nowrap text-gray-500 capitalize w-full text-ellipsis overflow-hidden block !px-2 !py-2">{formattedDate}</span>;
  },
);

export default AutomationTimestampCell;
