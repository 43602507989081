import React from "react";
import { Outlet } from "react-router-dom";
import TopBar from "./components/TopBar";

const OnboardingPage = () => (
  <div className="w-full h-full bg-gray-100 text-white flex items-center justify-center overflow-auto">
    <TopBar />
    <Outlet />
  </div>

);

export default OnboardingPage;
