import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { UnstyledButton } from "@mantine/core";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MoreViews from "./MoreViews";

type Props = {
  type: string;
  NavIcon: any;
  views: any;
  mainView: any;
};

const classNames = (...classes: any[]) => classes.filter(Boolean).join(" ");

const ViewDropdown = ({
  type, NavIcon, views, mainView,
}: Props) => {
  const [moreView, setMoreView] = useState<boolean>(false);
  const params: any = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col overflow-x-visible min-w-0 border-l border-gray-200">
      <div
        className={classNames(
          params.viewId === mainView?.id
            ? "bg-purple-50"
            : "",
          "w-full space-y-1 min-w-0",
        )}
      >
        <UnstyledButton
          type="button"
          onClick={() => {
            if (
              location.pathname
              !== `/home/${params.workspaceId}/view/${mainView?.id}`
            ) {
              navigate(`/home/${params.workspaceId}/view/${mainView?.id}`);
            }
          }}
          className={classNames(
            params.viewId === mainView?.id
              ? "bg-purple-100 text-purple-600"
              : "text-gray-500 hover:bg-gray-100 hover:text-gray-900 border-transparent",
            "w-full min-w-0 capitalize overflow-x-visible flex items-center pl-3 py-2 font-semibold text-sm",
          )}
        >
          <NavIcon className="mr-1 stroke-purple-400 h-5 w-5 flex-shrink-0" aria-hidden="true" />
          {type}
        </UnstyledButton>
        <div className="min-w-0 pl-4">
          <MoreViews
            isOpen={moreView}
            setIsOpen={setMoreView}
            type={type}
            list={views}
          >
            <div
              className={`w-full min-w-0 ${
                !moreView ? "text-gray-500" : "text-purple-900"
              } cursor-pointer hover:bg-gray-100 text-sm hover:text-gray-900 whitespace-nowrap capitalize flex items-center pl-3 py-2 font-semibold border-gray-200 border-l`}
            >
              <PlusCircleIcon
                className="mr-1.5 stroke-purple-400 w-[15px] h-[15px] flex-shrink-0"
                aria-hidden="true"
              />
              More views
              <ChevronDownIcon
                className={classNames(
                  !moreView ? "-rotate-90" : "rotate-90",
                  "ml-auto mr-2 h-4 w-4 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-900",
                )}
              />
            </div>
          </MoreViews>
        </div>
      </div>
    </div>
  );
};

export default ViewDropdown;
