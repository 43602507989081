/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar, Flex, Select, Text,
} from "@mantine/core";
import React, {
  forwardRef,
  useEffect, useMemo, useRef, useState,
} from "react";
import { useWorkspaceMembers } from "../../hooks/QueryHooks";
import useWorkspaceStore from "../../store/workspace/workspaceStore";
import BasicSelect from "../ui/inputs/select/BasicSelect";

type Props = {
  handleChange: (
    operator: string,
    value: any,
    type: string,
    entityType: string,
    selectedField?: any,
  ) => void;
  fieldSelect: boolean;
  op?: string;
  value?: any;
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  avatar_url?: string;
  email?: string;
  name: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({
    avatar_url, email, name, ...others
  }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Flex gap={8} align="center" className="overflow-hidden">
        <Avatar src={avatar_url} color="main.6" radius="xl" size="sm" alt={name}>{name ? name[0] : ""}</Avatar>
        <div>
          <Text truncate size="sm">{name}</Text>
          <Text truncate size={10} opacity={0.65}>
            {email}
          </Text>
        </div>
      </Flex>
    </div>
  ),
);

const MemberFilter = React.memo((props: Props) => {
  const {
    op,
    value,
    handleChange,
    fieldSelect,
  } = props;

  const workspaceId = useWorkspaceStore((state) => state.workspace?.id);

  const { data: workspaceMembers }: any = useWorkspaceMembers({
    workspaceId,
    enabled: !!workspaceId,
    retry: 2,
  });

  const Ops = useMemo(
    () => [
      { name: "is equal to", operator: "eq", id: 1 },
      { name: "is not equal to", operator: "ne", id: 2 },
    ],
    [],
  );
  const [selectOp, setSelectOp] = useState<any>(Ops.find((item) => item.operator === op) || { id: -1, name: "" });
  const [selectValue, setSelectValue] = useState<any>(value || "");
  const firstRender = useRef(true);

  const csmList = useMemo(() => workspaceMembers?.users?.map((member: any) => ({
    value: member.user_id,
    label: `${member.first_name} ${member.last_name}`,
    name: `${member.first_name} ${member.last_name}`,
    email: member.email,
    avatar_url: member.avatar_url,
  })) || [], [workspaceMembers?.users?.length]);

  useEffect(() => {
    if (!firstRender.current) {
      if (
        selectOp?.operator && selectValue
      ) {
        handleChange(
          selectOp.operator,
          selectValue,
          "string",
          "column",
        );
      }
    }
    firstRender.current = false;
  }, [selectValue, selectOp]);

  return (
    <>
      <BasicSelect
        disabled={fieldSelect}
        containerClass="min-w-[170px]"
        placeholder="Select Condition"
        list={Ops}
        selectedItem={selectOp}
        setSelectedItem={setSelectOp}
      />
      <Select
        value={selectValue}
        placeholder="Enter value here"
        itemComponent={SelectItem}
        onChange={(val: any) => setSelectValue(val)}
        data={csmList}
        searchable
        maxDropdownHeight={400}
        nothingFound="Nobody here"
        filter={(val: any, item: any) => (
          item?.name?.toLowerCase()?.includes(val?.toLowerCase()?.trim())
            || item?.email?.toLowerCase()?.includes(val?.toLowerCase()?.trim()))}
      />
    </>
  );
});

export default MemberFilter;
