import React from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

type Props = {
  className? : string
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
  containerClass?: string,
  value: string | number,
  border?: boolean,
};

const InlineSearch = ({
  onChange = () => {},
  className,
  containerClass,
  value,
  border = false,
} : Props) => {
  let searchStyle = `text-sm placeholder:text-xs text-gray-500 ${border ? "border rounded-md border-gray-300 focus:border-purple-500" : "border-none"} w-full pl-9 hover:text-gray-700 placeholder:hover:text-gray-700 focus:text-gray-900 sm:text-sm focus:ring-transparent`;
  if (className) {
    searchStyle = `${searchStyle} ${className}`;
  }

  return (
    <div className={`relative mt-1 rounded-md w-full ${containerClass}`}>
      <div
        className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
        aria-hidden="true"
      >
        <MagnifyingGlassIcon className="mr-3 h-4 w-4 text-gray-500" aria-hidden="true" />
      </div>
      <input
        onChange={onChange}
        value={value}
        type="text"
        className={searchStyle}
        placeholder="Search"
      />
    </div>
  );
};

export default InlineSearch;
