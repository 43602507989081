/* eslint-disable react-hooks/exhaustive-deps */
import { Transition } from "@headlessui/react";
import { CalendarDaysIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  Fragment, useEffect, useRef, useState,
} from "react";
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-lone-blocks */
import { format, isWeekend } from "date-fns";
/* @ts-ignore */
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import HeButton from "../buttons/HeButton";

function customDayContent(day: any) {
  let extraDot = null;
  if (isWeekend(day)) {
    extraDot = (
      <div
        style={{
          height: "5px",
          width: "5px",
          borderRadius: "100%",
          background: "orange",
          position: "absolute",
          top: 2,
          right: 2,
        }}
      />
    );
  }
  return (
    <div>
      {extraDot}
      <span>{format(day, "d")}</span>
    </div>
  );
}

type Props = {
  state: any,
  setState: any,
};

const DateRangeDropdown = ({
  state, setState,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  return (
    <div ref={ref} className="relative inline-block text-left">
      <div className="flex flex-col">
        <h4 className="text-gray-500 text-xs">Select date range</h4>
        <HeButton className="text-xs items-center" text={`${format(state.selection.startDate, "do MMM yy")} → ${format(state.selection.endDate, "do MMM yy")}`} size="sm" LeadingIcon={CalendarDaysIcon} TrailingIcon={ChevronDownIcon} type="button" onClick={() => { setIsOpen((currentState) => !currentState); }} />
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        show={isOpen}
      >
        <div id="heFilterModal" className="absolute border border-gray-200 flex flex-col gap-2 min-h-0 right-0 z-40 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <DateRangePicker
            onChange={(item: any) => setState({ ...state, ...item })}
            showSelectionPreview
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[state.selection]}
            direction="horizontal"
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            dayContentRenderer={customDayContent}
            ariaLabels={{
              monthPicker: "month picker",
              yearPicker: "year picker",
              prevButton: "previous month button",
              nextButton: "next month button",
            }}
          />
          <div className="ml-auto block p-3">
            <HeButton text="Set Range" size="sm" LeadingIcon={CalendarDaysIcon} type="button" onClick={() => { setIsOpen(false); }} />
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default DateRangeDropdown;
