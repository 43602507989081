import { Text } from "@mantine/core";
import { useLogoutFunction } from "@propelauth/react";
import React from "react";
import { useNavigate } from "react-router-dom";

type SelectWorkspace = {
  other?: boolean,
};

const SignOutMistake = ({ other }: SelectWorkspace) => {
  const logout = useLogoutFunction();
  const navigate = useNavigate();
  return (
    <Text size="sm" c="text.4">
      Got here by mistake?
      {" "}
      <Text span c="main.6" className="cursor-pointer" onClick={() => { logout(true); }}>Sign out</Text>
      {other && (
      <>
        {" "}
        or
        {" "}
        <Text span c="main.6" className="cursor-pointer" onClick={() => { navigate("/workspaces"); }}>select a different workspace.</Text>
      </>
      )}
    </Text>
  );
};

export default SignOutMistake;
