/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
import { Badge } from "@mantine/core";
import { format, parseISO } from "date-fns";
import { ICellTextProps } from "ka-table/props";
import React from "react";

/**
 * Custom cell component.
 */
const StatusCell: React.FC<ICellTextProps> = React.memo(
  ({
    column, dispatch, rowKeyValue, ...props
  }) => {
    const value = props.rowData[`${column.key}`];
    return <Badge color={value === "error" ? "red.6" : "green.7"}>{value}</Badge>;
  },
);

export default StatusCell;
