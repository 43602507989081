/* eslint-disable react-hooks/exhaustive-deps */
import {
  CheckCircleIcon, StarIcon, XCircleIcon,
} from "@heroicons/react/24/outline";
import {
  Divider, Flex,
  Menu,
  Stack, Text,
} from "@mantine/core";
import { RichTextEditor } from "@mantine/tiptap";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import DOMPurify from "dompurify";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { IconTrafficLights } from "@tabler/icons-react";
import { queryClient, useAllPlaybooks, useViews } from "../../hooks/QueryHooks";
import useWorkspaceStore from "../../store/workspace/workspaceStore";
import MButton from "../ui/buttons/MButton";
import ProfileImg from "../ui/icons/ProfileImg";
import { updateSignalRun } from "../../services/inbox/inboxEndpoints";
import notify from "../../utils/notify";

type Props = {
  account: any,
  title: string,
  signalId?: string,
  entityRelation?: string,
  externalId?: string,
  playbookId?: string,
  status?: string,
};

const SignalInfo = ({
  account, title, signalId, externalId, entityRelation = "accounts", playbookId, status,
}: Props) => {
  const workspace = useWorkspaceStore((state) => state.workspace);

  const views: any = useViews({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
  });

  const statusMutation = useMutation({
    mutationFn: updateSignalRun,
  });

  const onSelect = (stat: any) => {
    if (signalId) {
      statusMutation.mutate({
        signal_run_id: signalId,
        workspace_id: workspace.id,
        status: stat,
      }, {
        onSuccess: () => {
          queryClient.invalidateQueries(["inbox"]);
          notify({
            messageList: [`Signal status set to ${status}`],
            type: "info",
          });
        },
      });
    }
  };

  const defaultView = useMemo(() => (
    views?.data?.filter((item: any) => item?.is_precreated)?.reduce((ob: any, val: any) => {
      ob[val?.entity_relation] = val;
      return ob;
    }, {} as any)
  ), [views?.data]);

  const playbooks: any = useAllPlaybooks({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
  });

  const selectedPlaybook = useMemo(() => (
    playbooks?.data?.filter((item: any) => item?.id === playbookId)?.[0]
  ), [playbookId, playbooks?.data, signalId]);

  const navigate = useNavigate();
  const params = useParams();
  let path: string;
  if (entityRelation === "user") {
    path = `/home/${params?.workspaceId}/view/${params?.viewId ? params?.viewId : defaultView?.users?.id}/user/${externalId}`;
  } else if (entityRelation === "accounts") {
    path = `/home/${params?.workspaceId}/view/${params?.viewId ? params?.viewId : defaultView?.accounts?.id}/account/${externalId}/overview`;
  }

  const sanitizedHTML = DOMPurify.sanitize(selectedPlaybook?.content);
  const editor = useEditor({
    editable: false,
    extensions: [
      Underline,
      Link,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3, 4],
        },
        bulletList:
        {
          HTMLAttributes: {
            class: "list-disc list-outside",
          },
        },
        orderedList:
        {
          HTMLAttributes: {
            class: "list-decimal list-outside",
          },
        },
      }),
    ],
    content: sanitizedHTML,
  });

  editor?.commands?.setContent(sanitizedHTML);
  return (
    <Stack p={16} key={signalId} pb={1} spacing={12} className="self-stretch">
      <Flex justify="space-between" align="center" className="self-stretch">
        <Flex gap={12} onClick={() => { navigate(path); }} align="center" className="hover:bg-gray-200 cursor-pointer rounded-md p-2 pr-5">
          <ProfileImg desc={account?.email} img={account?.avatar_url} name={account?.name} size="lg" />
          <Text size="lg" fw={600}>{account?.name}</Text>
        </Flex>
        <Menu shadow="md" width={180} position="bottom-end">
          <Menu.Target>
            <div>
              <MButton size="sm" leftIcon={<IconTrafficLights width={20} />} color="gray" variant="outline" className="hover:bg-gray-100">Status</MButton>
            </div>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>Status</Menu.Label>
            <Menu.Item className={status === "starred" ? "bg-purple-500 text-white hover:bg-purple-700" : ""} onClick={() => { onSelect("starred"); }} icon={<StarIcon width={20} />}>Star</Menu.Item>
            <Menu.Item className={status === "done" ? "bg-purple-500 text-white hover:bg-purple-700" : ""} onClick={() => { onSelect("done"); }} icon={<CheckCircleIcon width={20} />}>Done</Menu.Item>
            <Menu.Item className={status === "dismissed" ? "bg-purple-500 text-white hover:bg-purple-700" : ""} onClick={() => { onSelect("dismissed"); }} icon={<XCircleIcon width={20} />}>Dismissed</Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Flex>
      <Text c="text.9" size="xl" fw={600}>{title}</Text>
      {playbookId && (
      <RichTextEditor
        key={signalId}
        className="border-none p-0 -m-4"
        editor={editor}
      >
        <RichTextEditor.Content />
      </RichTextEditor>
      )}
      <Divider my="sm" />
    </Stack>
  );
};

export default SignalInfo;
