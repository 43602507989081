/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { useDebouncedValue } from "@mantine/hooks";
import React, {
  useEffect, useMemo, useRef, useState,
} from "react";
import uuid from "react-uuid";
import { TextInput } from "@mantine/core";
import useWorkspaceStore from "../../store/workspace/workspaceStore";
import StringDropdown from "../ui/dropdown/StringDropdown";
import BasicSelect from "../ui/inputs/select/BasicSelect";
import FormInput from "../ui/inputs/text/FormInput";

type Props = {
  handleChange: (
    operator: string,
    value: any,
    type: string,
    entityType: string,
    selectedField?: any,
  ) => void;
  fieldSelect: boolean;
  op?: string;
  value?: any;
  selectedField?: any;
};

const StringFilter = React.memo((props: Props) => {
  const {
    op,
    value,
    handleChange,
    fieldSelect,
    selectedField,
  } = props;

  const workspaceId = useWorkspaceStore((state) => state.workspace?.id);

  const Ops = useMemo(
    () => [
      { name: "is equal to", operator: "eq", id: 1 },
      { name: "is not equal to", operator: "ne", id: 2 },
      { name: "starts with", operator: "starts_with", id: 3 },
      { name: "ends with", operator: "ends_with", id: 4 },
      { name: "contains", operator: "contain", id: 5 },
      { name: "does not contain", operator: "does_not_contain", id: 6 },
      { name: "known", operator: "known", id: 7 },
      { name: "unknown", operator: "unknown", id: 8 },
    ],
    [],
  );
  const [selectOp, setSelectOp] = useState<any>(Ops.find((item) => item.operator === op) || { id: -1, name: "" });
  const [selectValue, setSelectValue] = useState<any>(value || "");
  const [debouncedVal] = useDebouncedValue(selectValue, 700);
  const [dropdown, setDropdown] = useState<boolean>(false);
  const firstRender = useRef(true);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const currentElement = inputRef.current;
    if (currentElement) {
      currentElement.addEventListener("focus", () => { setDropdown(true); });
    }

    // Cleanup - remove event listeners
    return () => {
      if (currentElement) {
        currentElement.removeEventListener("focus", () => { setDropdown(true); });
      }
    };
  }, [inputRef]);

  useEffect(() => {
    if (!firstRender.current) {
      if (
        selectOp?.operator === "known"
      || selectOp?.operator === "unknown"
      || (!!selectOp?.operator)
      ) {
        handleChange(
          selectOp.operator,
          debouncedVal,
          "string",
          "column",
        );
      }
    }
    firstRender.current = false;
  }, [debouncedVal, selectOp]);

  return (
    <>
      <BasicSelect
        disabled={fieldSelect}
        containerClass="min-w-[170px]"
        placeholder="Select Condition"
        list={Ops}
        selectedItem={selectOp}
        setSelectedItem={setSelectOp}
      />
      {selectOp?.operator !== "known" && selectOp?.operator !== "unknown" ? (
        selectOp?.operator === "eq" || selectOp?.operator === "ne" ? (
          <StringDropdown
            isOpen={dropdown}
            setIsOpen={setDropdown}
            field={selectedField}
            entityType={selectedField?.includes("accounts.") ? "accounts" : "contacts"}
            workspaceID={workspaceId}
            value={debouncedVal}
            setValue={setSelectValue}
          >
            <TextInput
              disabled={!selectOp?.operator}
              placeholder="Enter value here"
              classNames={{
                input: "placeholder:text-sm text-sm",
              }}
              ref={inputRef}
              id={uuid()}
              value={selectValue}
              type="text"
              onChange={(e) => {
                setSelectValue(e.target.value);
                setDropdown(true);
              }}
            />
          </StringDropdown>
        ) : (
          <FormInput
            disabled={!selectOp?.operator}
            placeHolder="Enter value here"
            className="border-0 placeholder:text-gray-400"
            id={uuid()}
            value={selectValue}
            type="text"
            onChange={(e) => {
              setSelectValue(e.target.value);
            }}
          />
        )
      ) : (
        ""
      )}
    </>
  );
});

export default StringFilter;
