/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { useAuthInfo } from "@propelauth/react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAllAutomations, useViews } from "../../hooks/QueryHooks";
import useViewStore from "../../store/view/viewStore";

const NewView = () => {
  const authInfo = useAuthInfo();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setView } = useViewStore((state: any) => ({
    setView: state.setView,
  }));

  const { data: views } : any = useViews({
    workspaceId: searchParams.get("env_id") || "",
    enabled: !!searchParams.get("env_id") || false,
  });

  const { data: signals }: any = useAllAutomations({
    workspaceId: searchParams.get("env_id") || "",
    automationType: "signal",
    enabled: !!searchParams.get("env_id") || false,
  });

  const wiew = views?.views?.filter((item: any) => item?.is_precreated)?.find((val: any) => val?.entity_relation === searchParams.get("entity_type"));

  useEffect(() => {
    const selectedSignal = signals?.find((sig: any) => sig.id === searchParams.get("automation_id"));
    const selectedView = views?.filter((item: any) => item?.is_precreated)?.find((val: any) => val?.entity_relation === searchParams.get("entity_type"));
    if (searchParams.get("automation_id") && searchParams.get("env_id") && searchParams.get("entity_type")) {
      const selectedWorkspace: any = authInfo.orgHelper?.getOrg(searchParams.get("env_id") || "");
      if (selectedWorkspace && selectedSignal && selectedView) {
        setView({
          ...selectedView,
          name: selectedSignal?.name,
          filters: {
            ...selectedSignal?.filters,
          },
        });
        return navigate(`/home/${selectedWorkspace?.org_metadata?.unique_identifier}/view/${selectedView?.id}/new`);
      }
    }
    return () => {};
  }, [searchParams, views, signals]);
  return (
    <div />
  );
};

export default NewView;
