/* eslint-disable no-sparse-arrays */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import {
  BoltIcon, ChatBubbleBottomCenterTextIcon, HomeIcon, PlusCircleIcon,
  QuestionMarkCircleIcon, UsersIcon,
} from "@heroicons/react/24/outline";
import {
  Flex,
  Indicator,
  Navbar, ScrollArea, Text,
  createStyles, rem,
} from "@mantine/core";
import { useAuthInfo, useOrgHelper, useRedirectFunctions } from "@propelauth/react";
import { useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import uuid from "react-uuid";
import NavigationLink from "../../../shared/ui/links/NavigationLink";
import AvatarMenu from "./AvatarMenu";
import DropdownNavigationLink from "./DropdownNavigationLink";
import TopSearchBar from "./TopSearchBar";
import GettingStartedLink from "../../../shared/ui/links/GettingStartedLink";
import { queryClient, useInbox } from "../../../hooks/QueryHooks";

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
  },

  header: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },

  footer: {
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

const LeftSideBar = ({
  views, menu, setMenu, workspace,
}: { views: any[], menu: boolean, setMenu: any, workspace: any, }) => {
  const orgs: any = useOrgHelper().orgHelper?.getOrgs();
  const params = useParams();
  const selectedWorkspace: any = useMemo(() => orgs?.find(
    (item: any) => item.org_metadata.unique_identifier === params.workspaceId,
  ) || undefined, [orgs?.length, params.workspaceId]);

  const [count, setCount] = useState<number>(0);
  const user = useAuthInfo().user?.userId;

  useInbox({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
    entityRelation: "accounts",
    notificationType: "inbox",
    owner: [user || "null"],
    retry: 0,
    onSuccess: (data: any) => {
      setCount(data?.length || 0);
    },
    onError: () => {
      queryClient.setQueryData(["inbox", workspace?.id, undefined, "accounts", undefined, undefined, undefined, undefined, "inbox", [user || "null"],
      ], (oldData: any) => ({
        ...oldData,
        data: {
          data: {
            results: [],
          },
        },
      }));
    },
  });
  const { classes } = useStyles();

  const redirect = useRedirectFunctions();
  const navigation = useMemo(() => ([
    {
      count, text: "Headquarters", to: "headquarters", icon: HomeIcon,
    },
    {
      text: "Relationships",
      icon: UsersIcon,
      view: views,
    },
    {
      text: "Signals", to: `/home/${params.workspaceId}/signals`, icon: BoltIcon,
    },
    {
      text: "Inbox", to: `/home/${params.workspaceId}/inbox`, icon: ChatBubbleBottomCenterTextIcon,
    },
  ]), [views, params.workspaceId, count]);
  return (
    <Navbar zIndex={5} className={`${menu ? "w-[260px]" : "w-[75px]"} overflow-x-visible ease-linear duration-100 absolute h-full left-0 ${classes.navbar}`}>
      <Navbar.Section pt="lg" className="border-b">
        <AvatarMenu
          name={selectedWorkspace?.orgName}
          menu={menu}
          desc={workspace?.tracking_website_domain}
          img={workspace?.workspace_logo}
        />
        <div
          onClick={() => { setMenu((state: any) => !state); }}
          className="w-[30px] h-[50px] absolute -right-4 bottom-5"
        >
          <ChevronDownIcon
            className={`${menu ? "rotate-90" : "-rotate-90"}
                ease-linear cursor-pointer fill-purple-400 border rounded-full bg-gray-500 hover:bg-gray-800 duration-100 w-full p-1`}
          />
        </div>
      </Navbar.Section>
      {menu && (
      <TopSearchBar />
      )}
      <Navbar.Section className="mt-2" grow component={ScrollArea}>
        <div>
          {!workspace?.getting_started_guide_dismissed && (<GettingStartedLink menu={menu} label="Getting Started" to="getting-started" text={menu ? "Getting Started" : undefined} />)}
          {navigation.map((item: any) => (
          // eslint-disable-next-line react/jsx-no-useless-fragment
            item?.view
              ? (
                <DropdownNavigationLink
                  key={uuid()}
                  view={item.view}
                  menu={menu}
                  setMenu={setMenu}
                  text={menu ? item.text : undefined}
                  NavIcon={item.icon}
                  label={item.text}
                />
              )
              : (
                <NavigationLink
                  menu={menu}
                  key={uuid()}
                  count={menu ? item.count : undefined}
                  NavIcon={item.icon}
                  to={item.to}
                  text={menu ? item.text : undefined}
                  label={item.text}
                />
              )
          ))}
        </div>
      </Navbar.Section>
      {menu && (
      <Navbar.Section>
        <Flex
          onClick={() => {
            redirect.redirectToOrgPage(workspace?.id);
          }}
          className="gap-1 cursor-pointer px-5 p-3 hover:bg-gray-100"
          align="center"
        >
          <PlusCircleIcon className="w-4 h-4 text-gray-400" />
          <Indicator size={8} offset={-12} position="middle-end" color="main.3" inline processing>
            <Text
              c="text.6"
              size="sm"
            >
              Invite Team
            </Text>
          </Indicator>
        </Flex>
        <Flex className="gap-1 cursor-pointer p-3 px-5 hover:bg-gray-100" align="center">
          <QuestionMarkCircleIcon className="w-4 h-4 text-gray-400" />
          <Text c="text.6" size="sm">
            <Link to="https://docs.hyperengage.io/getting-started/start-here" target="_blank">
              Help and docs
            </Link>
          </Text>
        </Flex>
      </Navbar.Section>
      )}
    </Navbar>
  );
};

export default LeftSideBar;
