import {
  ArrowDownIcon, ArrowUpIcon,
  MinusIcon, TrashIcon,
} from "@heroicons/react/20/solid";
import {
  ActionIcon,
  Col,
  Flex,
  Paper,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { format } from "date-fns";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis, YAxis,
} from "recharts";
import { IconCheck, IconPencil } from "@tabler/icons-react";
import { useState } from "react";
import Pill from "../../../../../shared/ui/badges/Pill";
import TruncatedTextTooltip from "../../../../../shared/ui/truncate/TruncatedText";

type Props = {
  title: string;
  data: any;
  type: string;
  deleteHandler: any;
  id: any;
  editTitleHandler: any;
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-gray-700 px-3 py-2 rounded-md">
        <p className="text-xs text-gray-400">{`${label}`}</p>
        <p className="text-xs text-white">{`Metric Count: ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const CustomChart = ({
  title, data, type, deleteHandler, id, editTitleHandler,
}: Props) => {
  const [heading, setHeading] = useState<string>(title);
  const [edit, setEdit] = useState(false);
  const theme = useMantineTheme();
  const transformedData = data.histogram_values.map((item: any) => ({
    date: format(new Date(item.date), "MMM d"),
    count: item.count,
  })).reverse();

  const Chart = type === "line" ? AreaChart : BarChart;
  const ChartElement: any = type === "line" ? Area : Bar;

  const extremeDates = [transformedData[0].date, transformedData[transformedData.length - 1].date];

  const selectPill = (delta: any) => {
    let color = "gray";
    let icon = MinusIcon;
    if ((delta) > 0 || delta === null || delta === "N/A") {
      icon = ArrowUpIcon;
      color = "green";
    } else if ((delta) < 0) {
      icon = ArrowDownIcon;
      color = "red";
    }
    return (
      <span className="whitespace-nowrap capitalize w-full text-ellipsis overflow-hidden block px-2">
        <Pill
          LeadingIcon={icon}
          text={((delta === null || delta === "N/A") ? "∞" : `${delta}%`)}
          color={color}
          rounded
          size="md"
        />
      </span>
    );
  };

  return (
    <Paper style={{ position: "relative" }}>
      <Col style={{ marginBottom: theme.spacing.sm }}>
        <Flex justify="space-between" gap={10}>
          {edit ? (
            <TextInput
              size="xs"
              placeholder="Enter title"
              classNames={{
                input: "text-xs font-semibold p-1 !py-0",
              }}
              value={heading}
              onChange={(e: any) => { setHeading(e.currentTarget.value); }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (title !== heading) {
                    editTitleHandler(id, e.currentTarget.value);
                  }
                  setEdit(false);
                }
              }}
              autoFocus
            />
          )
            : (
              <TruncatedTextTooltip label={heading} width={200} size="sm" className="whitespace-nowrap overflow-hidden text-ellipsis font-semibold" color={theme.colors.text[5]}>
                {heading}
              </TruncatedTextTooltip>
            )}
          <Flex gap={1}>
            {!edit ? (
              <ActionIcon onClick={() => { setEdit((state) => !state); }} color="purple" className="border-none hover:text-purple-600" size="sm" variant="outline">
                <IconPencil height={13} width={13} />
              </ActionIcon>
            ) : (
              <ActionIcon
                onClick={() => {
                  if (title !== heading) {
                    editTitleHandler(id, heading);
                  }
                  setEdit((state) => !state);
                }}
                color="green"
                className="border-none hover:text-green-600"
                size="sm"
                variant="outline"
              >
                <IconCheck height={13} width={13} />
              </ActionIcon>
            )}

            <ActionIcon onClick={() => { deleteHandler(id); }} color="red" className="border-none hover:text-red-600" size="sm" variant="outline">
              <TrashIcon height={13} width={13} />
            </ActionIcon>
          </Flex>
        </Flex>
        <Text className="flex text-[30px] font-bold gap-2 items-center" color={theme.colors.gray[9]}>
          {data.total_count}
          {" "}
          {selectPill(data.percentage_change)}
        </Text>
      </Col>
      <ResponsiveContainer width="100%" height={130}>
        <Chart data={transformedData}>
          <defs>
            <linearGradient id="colorCount" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={theme.colors.main[4]} stopOpacity={0.6} />
              <stop offset="95%" stopColor={theme.colors.main[2]} stopOpacity={0.2} />
            </linearGradient>
          </defs>
          <XAxis
            stroke={theme.colors.text[3]}
            tick={{ fontSize: 12 }}
            dataKey="date"
            ticks={extremeDates}
            domain={["dataMin", "dataMax"]}
            tickLine={false}
            interval="preserveStartEnd"
          />
          <YAxis hide />
          <Tooltip content={CustomTooltip} />
          <ChartElement dot={false} type="monotone" dataKey="count" fill="url(#colorCount)" stroke={theme.colors.main[4]} />
        </Chart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default CustomChart;
