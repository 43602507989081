import { BellAlertIcon } from "@heroicons/react/24/outline";
import {
  Card,
  Flex,
  Paper,
  Text,
} from "@mantine/core";
import { RichTextEditor } from "@mantine/tiptap";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { format } from "date-fns";
import DOMPurify from "dompurify";

type Props = {
  htmlContent: string;
  playName?: string;
};

const PlaybookPreview = ({ htmlContent, playName = "New Alert" }: Props) => {
  const sanitizedHTML = DOMPurify.sanitize(htmlContent);
  const editor = useEditor({
    editable: false,
    extensions: [
      Underline,
      TaskList.configure({
        HTMLAttributes: {
          class: "!pl-0",
        },
      }),
      TaskItem.configure({
        nested: true,
        HTMLAttributes: {
          class: "flex gap-3",
        },
      }),
      Link,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3, 4],
        },
        bulletList:
        {
          HTMLAttributes: {
            class: "list-disc list-outside",
          },
        },
        orderedList:
        {
          HTMLAttributes: {
            class: "list-decimal list-outside",
          },
        },
      }),
    ],
    content: sanitizedHTML,
  });

  editor?.commands?.setContent(sanitizedHTML);

  return (
    <Card p={40} className="bg-gray-100">
      <Paper withBorder shadow="xs">
        <Flex p="md" align="center" gap={5} className="w-full bg-purple-100">
          <BellAlertIcon className="w-4 h-4 mr-1 text-purple-600" />
          <Text c="main.5" weight={700}>{playName}</Text>
          <Text className="ml-auto text-xs text-gray-500">{format(new Date(), "hh:mm a")}</Text>
        </Flex>
        <RichTextEditor editor={editor}>
          <RichTextEditor.Content />
        </RichTextEditor>
      </Paper>
    </Card>
  );
};

export default PlaybookPreview;
