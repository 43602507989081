/* eslint-disable @typescript-eslint/no-unsafe-return */
import { create } from "zustand";

type WorkspaceStore = {
  workspace: any;
  setWorkspace: (workspace: any) => void;
};

const useWorkspaceStore = create<WorkspaceStore>((set) => ({
  // state data and actions will go here
  workspace: undefined as any,
  refetch: () => {},
  setWorkspace: (workspace: any) => set({ workspace }),
}));

export default useWorkspaceStore;
