/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Dialog } from "@headlessui/react";
import { useAuthInfo } from "@propelauth/react";
import { useMutation } from "@tanstack/react-query";
import React, {
  useEffect, useMemo, useRef, useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { queryClient, useViews } from "../../../hooks/QueryHooks";
import { createView } from "../../../services/view/viewEndpoints";
import HeButton from "../../../shared/ui/buttons/HeButton";
import Checkbox from "../../../shared/ui/inputs/checkbox/Checkbox";
import FormInput from "../../../shared/ui/inputs/text/FormInput";
import useWorkspaceStore from "../../../store/workspace/workspaceStore";
import notify from "../../../utils/notify";
import TypeSelect from "./TypeSelect";

type Props = {
  open: boolean;
  setOpen:
  | ((value: boolean) => void)
  | (((value: boolean) => void) & React.FormEvent<HTMLDivElement>);
  defaultType?: string;
  defaultName?: string;
  disableType?: boolean;
  onClick?: any;
};

const CreateView = ({
  onClick,
  open,
  setOpen,
  defaultType,
  defaultName,
  disableType = false,
}: Props) => {
  const [name, setName] = useState<string>(defaultName || "");
  const [type, setType] = useState<any>(defaultType || "");
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const currentUser = useAuthInfo().user;
  const currentWorkspace = useWorkspaceStore((state) => state.workspace);
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const { data: views } : any = useViews({
    workspaceId: currentWorkspace?.id,
    enabled: !!currentWorkspace?.id,
  });

  const createViewMutation = useMutation({
    mutationFn: createView,
  });

  const accountType = useMemo(
    () => ({
      ...views?.filter((item: any) => item?.is_precreated)?.find((val: any) => val?.entity_relation === "accounts"),
      owner_id: currentUser?.userId,
      workspace_id: currentWorkspace?.id,
      is_precreated: false,
    }),
    [currentUser?.userId, currentWorkspace?.id, views],
  );

  const contactType = useMemo(
    () => ({
      ...views?.filter((item: any) => item?.is_precreated)?.find((val: any) => val?.entity_relation === "users"),
      owner_id: currentUser?.userId,
      workspace_id: currentWorkspace.id,
      is_precreated: false,
    }),
    [currentUser?.userId, currentWorkspace.id, views],
  );

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open, setOpen]);

  const onTypeClick = (select: string) => {
    setType(select);
  };

  const submitHandler = () => {
    if (currentWorkspace.id) {
      let viewData: any;
      if (type === "accounts") {
        viewData = {
          ...accountType,
          view_description: "",
          view_name: name,
          is_public: isPublic,
          is_precreated: false,
        };
      } else if (type === "users") {
        viewData = {
          ...contactType,
          view_name: name,
          view_description: "",
          is_public: isPublic,
          is_precreated: false,
        };
      }

      createViewMutation.mutate(viewData, {
        onSuccess: (data: any) => {
          queryClient.setQueryData(["views", currentWorkspace?.id], (oldData: any) => ({
            ...oldData,
            data: {
              ...oldData.data,
              views: [...oldData.data.views,
                { ...data?.data, owner_id: currentUser?.userId },
              ],
            },
          }));
          if (process.env.REACT_APP_HE_WORKSPACE_KEY && process.env.REACT_APP_HE_DATA_KEY) {
            window?.hyperengage(
              "track",
              "new_view_created",
              {
                properties: {
                  view_name: name,
                  is_public: isPublic,
                  created_by: currentUser?.userId,
                  workspace_id: currentWorkspace?.id,
                  entity_type: type,
                },
              },
            );
          }
          notify({
            heading: "New View Created",
            type: "success",
            messageList: ["You have successfully created a new view."],
          });
          navigate(`/home/${currentWorkspace?.unique_identifier}/view/${data?.data.id}`);
        },
        onError: (error: any) => {
          if (error.response?.status !== 0) {
            notify({
              heading: "Unknown error.",
              type: "failure",
              messageList: [
                "An unknown error occurred while creating the new view. Please try again later or contact our support team for assistance.",
              ],
            });
          }
        },
      });
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <div className="fixed inset-0 backdrop-blur-sm z-50 bg-gray-700/50 text-white flex flex-col items-center gap-10 justify-center">
        <Dialog.Panel>
          <section
            ref={ref}
            className="mx-10 flex flex-col rounded-lg border border-gray-900 px-7 pt-7 pb-5 w-full max-w-[650px] gap-3 bg-white"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <h2 className="font-bold text-[30px] mb-5">New View</h2>
            <FormInput
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              id="view name"
              label="View name"
              type="text"
            />
            <div className="flex w-full items-center justify-between">
              <TypeSelect
                selected={type === "accounts"}
                disabled={defaultType === "users" && disableType}
                onClick={onTypeClick}
                type="accounts"
              />
              <TypeSelect
                selected={type === "users"}
                disabled={defaultType === "accounts" && disableType}
                onClick={onTypeClick}
                type="users"
              />
            </div>
            <div className="flex justify-between align-center mt-5 gap-4">
              <div className="px-2 flex items-center">
                <Checkbox
                  checked={isPublic}
                  onChange={() => setIsPublic(!isPublic)}
                  id="isPublic"
                >
                  Do you want your view to be public?
                </Checkbox>
              </div>
              <div className="flex items-center gap-2">
                <button
                  type="button"
                  className="border-none text-purple-600 hover:text-purple-800"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Back
                </button>
                <HeButton
                  color="purple"
                  disabled={!type || !name}
                  text="Create view"
                  size="sm"
                  onClick={() => {
                    if (onClick) {
                      onClick(name, type, isPublic);
                      setOpen(false);
                    } else {
                      submitHandler();
                    }
                  }}
                />
              </div>
            </div>
          </section>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default CreateView;
