/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { Flex, Text, Tooltip } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import classNames from "../../../utils/classNames";
import ProfileImg from "../icons/ProfileImg";

type Props = {
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "basic",
  name?: string,
  rounded?: boolean,
  desc?: string,
  img?: string,
  to?: string,
  headingContainerClass?: string,
  containerClass?: string,
  headingClass?: string,
};

const Profile = ({
  size = "md",
  rounded = false,
  name,
  img,
  desc,
  headingContainerClass,
  headingClass,
  containerClass,
  to,
} : Props) => {
  let headingStyle = "whitespace-nowrap overflow-hidden text-ellipsis capitalize group-hover:underline text-gray-900 font-semibold";
  let descStyle = "whitespace-nowrap lowercase overflow-hidden text-ellipsis text-gray-500 group-hover:underline ";
  const navigate = useNavigate();
  let containerStyle = "overflow-hidden group flex flex-col justify-center";
  const { hovered, ref } = useHover();

  if (size === "xs" || size === "sm") {
    headingStyle += " text-sm";
    descStyle += " text-sm";
    containerStyle = "flex flex-row gap-2 items-center";
  } else if (size === "md") {
    headingStyle += " text-sm";
    descStyle += " text-sm";
  } else if (size === "lg") {
    headingStyle += " text-basic";
    descStyle += " text-sm";
  } else if (size === "xl") {
    headingStyle += " text-[20px]";
    descStyle += " text-lg";
  }
  headingStyle = classNames(headingStyle, headingClass);
  containerStyle = classNames(containerStyle, headingContainerClass);
  return (
    <div
      className={`${to && "cursor-pointer"} ease-linear overflow-hidden p-1 py-2 rounded-lg w-full hover:bg-gray-200 duration-200 flex items-center gap-3 ${containerClass}`}
      role="link"
      onKeyDown={(e) => {
        if (to && e.key === "enter") {
          navigate(to);
        }
      }}
      onClick={() => {
        if (to) {
          navigate(to);
        }
      }}
    >
      <ProfileImg desc={desc} img={img} name={name} size={size === "basic" ? "md" : size} rounded={rounded} />
      <Tooltip
        withinPortal
        opened={hovered}
        label={(
          <Flex className={containerStyle}>
            {name && <Text className="text-sm text-white">{name}</Text>}
            {desc && <Text className="text-xs text-gray-400">{desc}</Text>}
          </Flex>
        )}
        position="top"
      >
        <div ref={ref} className={containerStyle}>
          {name && <Text truncate className={headingStyle}>{name}</Text>}
          {desc && <Text truncate className={descStyle}>{desc}</Text>}
        </div>
      </Tooltip>
    </div>
  );
};

export default Profile;
