/* eslint-disable no-nested-ternary */
import React from "react";
import {
  Divider, Flex, Paper, Text,
} from "@mantine/core";
import { ReactComponent as HeIcon } from "../../../../../assets/images/heSmP.svg";

interface SlackPreviewProps {
  signal: any;
  traits: any;
  metrics: any;
}

const SlackPreview: React.FC<SlackPreviewProps> = ({ signal, traits, metrics }: any) => (
  <Flex gap={12} className="p-5 pl-4">
    <HeIcon className="mt-2 w-6 h-6" />
    <div>
      <div className="mb-1">
        <Flex align="center" gap="xs" className="mb-1">
          <Text align="left" weight={700}>Hyperengage</Text>
          <Text align="left" size={10} className="bg-gray-200 text-gray-700 p-0.5 font-semibold rounded-sm">App</Text>
        </Flex>
        <Text align="left" weight={600}>{signal?.name}</Text>
        <Text size="sm" className="mb-1">
          Here are 1 of
          {" "}
          {signal?.responseData?.length}
          {" "}
          {signal?.entity_relation}
          {" "}
          that match.
        </Text>
        <Divider className="my-2" />
      </div>
      <Paper className="bg-inherit">
        {[...signal.metrics, ...signal.selected_fields]?.length > 0
          ? [...signal.metrics, ...signal.selected_fields].map((val: any) => (
            <p className="mb-1">
              <strong>
                {{ ...traits, ...metrics }[val]?.display_name}
                :
              </strong>
              {" "}
              {signal?.responseData?.[0]?.[val] ? (signal?.responseData?.[0]?.[val]?.value ? signal?.responseData?.[0]?.[val]?.value : signal?.responseData?.[0]?.[val]) : "-"}
            </p>
          )) : (
            <Text size="sm" className="text-gray-500">
              No data to show
            </Text>
          )}
      </Paper>
    </div>
  </Flex>
);

export default SlackPreview;
