/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */

import { ICellTextProps } from "ka-table/props";
import React, { useMemo } from "react";
import { Flex, Progress, Text } from "@mantine/core";
import { useParams } from "react-router-dom";
import Avatar from "../ui/avatar/Avatar";
import { useViews } from "../../hooks/QueryHooks";
import useWorkspaceStore from "../../store/workspace/workspaceStore";

const AvatarCell: React.FC<ICellTextProps> = React.memo(
  ({
    column, dispatch, rowKeyValue, ...props
  }) => {
    const workspace = useWorkspaceStore((state) => state.workspace);
    const views: any = useViews({
      workspaceId: workspace?.id,
      enabled: !!workspace?.id,
    });

    const value = props.rowData[`${column.key}`];
    const params: any = useParams();
    const defaultView = useMemo(() => (
      views?.data?.filter((item: any) => item?.is_precreated)?.reduce((ob: any, val: any) => {
        ob[val?.entity_relation] = val;
        return ob;
      }, {} as any)
    ), [views?.data]);

    let path;
    if ((value?.relation === "user" || column.key.includes("users")) && value?.externalId) {
      path = value?.accountId ? `/home/${params?.workspaceId}/view/${params?.viewId ? params?.viewId : defaultView?.users?.id}/account/${value?.accountId}/user/${value?.externalId}`
        : `/home/${params?.workspaceId}/view/${params?.viewId ? params?.viewId : defaultView?.users?.id}/user/${value?.externalId}`;
    } else if ((value?.relation === "accounts" || column.key.includes("accounts")) && value?.externalId) {
      path = `/home/${params?.workspaceId}/view/${params?.viewId ? params?.viewId : defaultView?.accounts?.id}/account/${value?.externalId}/overview`;
    }
    return (
      <span className="whitespace-nowrap capitalize w-full text-ellipsis overflow-hidden block">
        <Avatar
          name={value?.name}
          desc={value?.desc}
          img={value?.img}
          size="basic"
          to={path}
          rounded
        />
      </span>
    );
  },
);

export default AvatarCell;
