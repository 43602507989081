/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Flex,
  Modal,
  Paper,
  Select,
  Stack,
  Text,
  Textarea,
} from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { IconPencil } from "@tabler/icons-react";
import {
  useSlackChannels, useSlackIntegration, useSlackUsers, useTraitsAndMetrics,
} from "../../../../../hooks/QueryHooks";
import { ReactComponent as SlackIcon } from "../../../../../assets/images/slack.svg";
import useWorkspaceStore from "../../../../../store/workspace/workspaceStore";
import MButton from "../../../../../shared/ui/buttons/MButton";
import notify from "../../../../../utils/notify";
import MultiSelectDropdown from "../../../../../shared/ui/dropdown/MultiSelectDropdown";
import SlackPreview from "./SlackPreview";

type Props = {
  onCompleteAction: (action: any, type: string) => void;
  opened: boolean;
  close: () => void;
  data?: any;
  setSelectedFields?: any;
  setSelectedMetrics: any;
  signal?: any;
};

const assignedChoices = [
  {
    value: "",
    label: "None",
  },
  {
    value: "csm",
    label: "Assigned CSM",
  }, {
    value: "ae",
    label: "Assigned AE",
  }];

const SlackModal = ({
  onCompleteAction, opened, close, data, setSelectedFields, signal, setSelectedMetrics,
}: Props) => {
  const workspace: any = useWorkspaceStore((state) => state.workspace);
  const [showHidden, setShowHidden] = useState(false);
  // const [radio, setRadio] = useState(data?.message_type === "direct" ? "direct" : "channel"); // ["channel", "direct"
  const [error, setError] = useState<boolean>(false);
  const [radio, setRadio] = useState("channel"); // ["channel", "direct"]
  const [assignedTo, setAssignedTo] = useState(data?.assign_to || "");
  const [subject, setSubject] = useState(data?.message || "");
  const [to, setTo] = useState(data?.slack_user_id || "");
  const [channel, setChannel] = useState(data?.channel_id || "");

  const traitsAndMetrics: any = useTraitsAndMetrics({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
  });

  const filteredTraits = useMemo(
    () => (signal?.entity_relation === "accounts" ? traitsAndMetrics?.data?.accountTraits?.list?.filter((item: any) => !item.exclude_display) : traitsAndMetrics?.data?.contactTraits?.list?.filter((item: any) => !item.exclude_display)),
    [signal?.entity_relation, traitsAndMetrics?.isSettled],
  );

  const filteredMetrics = useMemo(
    () => (signal?.entity_relation === "accounts" ? traitsAndMetrics?.data?.accountMetrics?.list?.filter((item: any) => !item.exclude_display) : traitsAndMetrics?.data?.contactMetrics?.list?.filter((item: any) => !item.exclude_display)),
    [signal?.entity_relation, traitsAndMetrics?.isSettled],
  );

  const onSelectColumn = (item: any) => {
    if (item?.attribute_type?.includes("metric")) {
      setSelectedMetrics(signal?.metrics.includes(item.name) ? signal?.metrics.filter((name: any) => name !== item.name) : [...signal.metrics, item.name]);
    } else {
      setSelectedFields(signal?.selected_fields.includes(item.name)
        ? signal?.selected_fields.filter((name: any) => name !== item.name)
        : [...signal.selected_fields, item.name]);
    }
  };

  const { isError, isSettled }: any = useSlackIntegration({
    workspaceId: workspace.id,
    enabled: true,
    retry: false,
  });

  const { data: users }: any = useSlackUsers({
    workspaceId: workspace.id,
    enabled: !!workspace.id && !isError && isSettled,
    select: (us: any) => us?.data?.users?.map((item: any) => ({
      value: item?.id,
      label: item?.name,
    })),
    retry: false,
  });
  const { data: channels }: any = useSlackChannels({
    workspaceId: workspace.id,
    enabled: !!workspace.id && !isError && isSettled,
    select: (ch: any) => ch?.data?.channels?.map((item: any) => ({
      value: item?.id,
      label: item?.normalised_name,
    })),
    retry: false,
  });

  useEffect(() => {
    if (data?.message_type === "direct") {
      setRadio("direct");
    } else {
      setRadio("channel");
    } if (data?.assign_to) {
      setAssignedTo(data?.assign_to);
    } if (data?.message) {
      setSubject(data?.message);
    } if (data?.slack_user_id) {
      setTo(data?.slack_user_id);
    } if (data?.channel_id) {
      setChannel(data?.channel_id);
    }
  }, [data]);

  return (
    <Modal
      padding="lg"
      classNames={{
        title: "text-gray-900 text-xl font-bold",
      }}
      size={700}
      opened={opened}
      centered
      onClose={() => {
        close();
      }}
      title={(
        <Flex align="center">
          <SlackIcon className="w-6 h-6 mr-4 text-gray-600" />
          <Stack spacing={0}>
            <Text>Slack | Send message</Text>
            <Text size="xs" c="text.5" weight={500}>
              Send a message to a Slack user or channel.
            </Text>
          </Stack>
        </Flex>
)}
    >
      {radio === "channel" ? (
        <Select
          classNames={{
            label: "text-gray-500 font-bold mb-1 text-xs",
          }}
          className="flex-1"
          data={channels || []}
          label="Select Channel"
          size="sm"
          placeholder="Choose Channel"
          searchable
          onChange={(value: string) => {
            setChannel(value);
          }}
          value={channel}
          error={error && channel === "" ? "You must select a channel" : null}
        />
      ) : (
        <>
          <Select
            classNames={{
              label: "text-gray-500 font-bold mb-1 text-xs",
            }}
            className="flex-1"
            data={users || []}
            label="Select User"
            size="sm"
            placeholder="Choose User"
            searchable
            onChange={(value: string) => {
              setTo(value);
            }}
            value={to}
            error={error && to === "" ? "You must select a user" : null}
          />
          <Select
            classNames={{
              label: "text-gray-500 font-bold mb-1 text-xs",
            }}
            className="flex-1"
            data={channels || []}
            label="Select fallback channel"
            size="sm"
            placeholder="Choose Channel"
            searchable
            onChange={(value: string) => {
              setChannel(value);
            }}
            value={channel}
            error={error && channel === "" ? "You must select a fallback channel" : null}
          />
        </>
      )}
      <Select
        onChange={(value: string) => { setAssignedTo(value); }}
        value={assignedTo}
        size="sm"
        classNames={{
          label: "text-gray-500 font-bold mb-1 text-xs",
        }}
        placeholder="Please select"
        data={assignedChoices}
        label="Assigned to"
      />
      <Textarea
        classNames={{
          label: "text-gray-500 font-bold mb-1 text-xs",
        }}
        label="Message"
        placeholder="Enter an alert message"
        value={subject}
        onChange={(event) => setSubject(event.currentTarget.value)}
      />
      <MultiSelectDropdown
        selected={[...signal.selected_fields, ...signal.metrics]}
        setSelected={onSelectColumn}
        isOpen={showHidden}
        setIsOpen={setShowHidden}
        list={filteredTraits}
        metricsList={filteredMetrics}
        position="bottom-end"
      >
        <div>
          <MButton
            color="white"
            onClick={() => { setShowHidden((state) => !state); }}
            leftIcon={<IconPencil />}
            w="100%"
            mt={10}
          >
            Select Additional Traits
          </MButton>
        </div>
      </MultiSelectDropdown>
      <Text className="text-gray-500 font-bold text-xs my-2">Preview</Text>
      <Paper withBorder className="bg-gray-50" shadow="xs">
        <SlackPreview
          signal={signal}
          traits={signal?.entity_relation === "accounts" ? traitsAndMetrics?.data?.accountTraits?.obj : traitsAndMetrics?.data?.contactTraits?.obj}
          metrics={signal?.entity_relation === "accounts" ? traitsAndMetrics?.data?.accountMetrics?.obj : traitsAndMetrics?.data?.contactMetrics?.obj}
        />
        {/* <Flex p="md" align="center" gap={5} className="w-full bg-purple-100">
          <SlackIcon className="w-4 h-4 mr-2 text-gray-600" />
          <Badge color="main.5">Hyperengage Alert</Badge>
          <Text className="ml-auto text-xs text-gray-500">{format(new Date(), "hh:mm a")}</Text>
        </Flex>
        <Text p="md" lineClamp={4} size="sm" c="text.5" className="border-t border-gray-300">{subject || "Enter a slack message"}</Text> */}
      </Paper>
      <Flex justify="flex-end" gap={5} className="mt-4">
        <MButton
          onClick={() => {
            close();
          }}
          variant="outline"
          className="hover:bg-red-100"
          color="red"
        >
          Cancel
        </MButton>
        <MButton
          color="purple"
          onClick={
              () => {
                if (subject !== "") {
                  if (radio === "channel" && channel !== "") {
                    onCompleteAction({
                      type: "send_to_slack",
                      message_type: "channel",
                      assign_to: assignedTo || null,
                      channel_id: channel,
                      message: subject || "",
                    }, "send_to_slack");
                  } else if (radio === "direct" && to !== "" && channel !== "") {
                    onCompleteAction({
                      type: "send_to_slack",
                      message_type: "direct",
                      assign_to: assignedTo || null,
                      slack_user_id: to,
                      channel_id: channel,
                      message: subject || "",
                    }, "send_to_slack");
                  } else {
                    setError(true);
                    return;
                  }
                  notify({
                    messageList: ["Slack action added successfully"],
                    type: "info",
                  });
                  close();
                } else {
                  setError(true);
                }
              }
          }
        >
          Save Action
        </MButton>
      </Flex>
    </Modal>
  );
};

export default SlackModal;
