import { BoltIcon } from "@heroicons/react/20/solid";
import { Outlet, useNavigate } from "react-router-dom";
import MButton from "../../../shared/ui/buttons/MButton";
import SignalTabs from "./components/SignalTabs";

const SignalsPage = () => {
  const navigate = useNavigate();
  return (
    <div className="px-12 py-8 overflow-auto relative h-full bg-gray-100">
      <div className="max-w-10xl mx-auto">
        <div className="flex mt-2 mb-5 justify-between items-center">
          <h2 className="text-gray-900 text-[30px] font-bold">Your Signals</h2>
          <div className="flex gap-2">
            <MButton
              size="sm"
              onClick={() => navigate("new")}
              color="white"
              className="text-sm"
              leftIcon={<BoltIcon className="w-4 h-4" />}
            >
              New Signal
            </MButton>
          </div>
        </div>
      </div>
      <div className="mx-auto">
        <SignalTabs />
        <div className="py-1 bg-white border-2 border-gray-200 rounded-md">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SignalsPage;
