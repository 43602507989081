/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { ClipboardIcon } from "@heroicons/react/20/solid";
import {
  Flex,
  Modal,
  Select,
  Stack,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";
import MButton from "../../../../../shared/ui/buttons/MButton";
import notify from "../../../../../utils/notify";
import LabelWithTooltip from "../../../../../shared/label/LabelWithTooltip";

const assignedChoices = [
  {
    value: "",
    label: "None",
  },
  {
    value: "csm",
    label: "Assigned CSM",
  }, {
    value: "ae",
    label: "Assigned AE",
  }];

type Props = {
  onCompleteAction: (action: any, type: string) => void;
  opened: boolean;
  close: () => void;
  data?: any;
};

const InboxModal = ({
  onCompleteAction, opened, close, data,
}: Props) => {
  const [error, setError] = useState<boolean>(false);
  const [assignedTo, setAssignedTo] = useState(data?.assign_to || "");

  useEffect(
    () => {
      if (data?.assign_to) {
        setAssignedTo(data?.assign_to);
      }
    },
    [data],
  );

  return (
    <Modal
      padding="lg"
      classNames={{
        title: "text-gray-900 text-xl font-bold",
      }}
      size={700}
      opened={opened}
      centered
      onClose={() => {
        close();
      }}
      title={(
        <Flex align="center">
          <ClipboardIcon className="w-6 h-6 mr-2 text-gray-600" />
          <Stack spacing={0}>
            <Text>Inbox | Send message</Text>
            <Text size="xs" c="text.5" weight={500}>
              Send a signal to the hyperengage notifications inbox.
            </Text>
          </Stack>
        </Flex>
)}
    >
      <Select
        withinPortal
        onChange={(value: string) => { setAssignedTo(value); }}
        value={assignedTo}
        size="sm"
        classNames={{
          label: "text-gray-500 font-bold mb-1",
        }}
        placeholder="Please select"
        data={assignedChoices}
        label={<LabelWithTooltip label="Assign to" info="Assign inbox alert to relevant team members" />}
      />
      <Flex justify="flex-end" gap={5} className="mt-4">
        <MButton
          onClick={() => {
            close();
          }}
          variant="outline"
          className="hover:bg-red-100"
          color="red"
        >
          Cancel
        </MButton>
        <MButton
          color="purple"
          onClick={
              () => {
                if (assignedTo) {
                  onCompleteAction({
                    type: "send_to_inbox",
                    assign_to: assignedTo,
                    assign_to_key: `${assignedTo}_`,
                  }, "send_to_inbox");
                } else {
                  onCompleteAction({
                    type: "send_to_inbox",
                    assign_to: null,
                    assign_to_key: null,
                  }, "send_to_inbox");
                }
                notify({
                  messageList: ["Inbox action added successfully"],
                  type: "info",
                });
                close();
              }
          }
        >
          Save Action
        </MButton>
      </Flex>
    </Modal>
  );
};

export default InboxModal;
