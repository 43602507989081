import { Paper, ScrollArea, Text } from "@mantine/core";
import { Link, useParams } from "react-router-dom";
import uuid from "react-uuid";
import ProfileImg from "../../../../../shared/ui/icons/ProfileImg";
import classNames from "../../../../../utils/classNames";

type Props = {
  accountIds: string[],
};

const AccountInfo = ({ accountIds }: Props) => {
  const { viewId }: any = useParams();

  return (
    <Paper
      shadow="xs"
      radius="sm"
      className="rounded-md flex-shrink-0 flex flex-col w-[240px]"
    >
      <div
        className={classNames("flex justify-between w-full py-4 border-b items-center font-bold")}
      >
        <Text size="sm" color="gray.6" className="font-semibold px-4">
          ACCOUNTS
        </Text>
      </div>
      <ScrollArea h={157} className="flex flex-col gap-5 py-2">
        {accountIds?.length ? accountIds?.map((item: any) => (
          <Link key={uuid()} to={`../view/${viewId}/account/${item?.external_id}/overview`} className="flex gap-2 items-center p-2 px-3 hover:bg-gray-100">
            <ProfileImg size="md" name={item?.name} img={item?.avatar_url} />
            <span className="capitalize text-blue-600">{item?.name}</span>
          </Link>
        )) : <Text className="text-gray-500 p-4">No Accounts linked to this User</Text>}
      </ScrollArea>
    </Paper>
  );
};

export default AccountInfo;
