import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAllIntegrations, useCurrentUser } from "../../../hooks/QueryHooks";
import useSelectWorkspace from "../../../hooks/useSelectWorkspace";
import { createIntegration } from "../../../services/integrationEndpoint";
import IntegrationContainer from "../../../shared/integration/IntegrationContainer";
import SignOutMistake from "../../../shared/sign-out/SignOutMistake";
import ApiIcon from "../../../shared/ui/icons/ApiIcon";
import SegmentIcon from "../../../shared/ui/icons/SegmentIcon";
import notify from "../../../utils/notify";
import InviteTeam from "./components/InviteTeam";

const AddIntegration = () => {
  const selectedWorkspace: any = useSelectWorkspace();
  const [segmentStatus, setSegmentStatus] = useState<string>("Connect");
  const { data: integrations }: any = useAllIntegrations({
    workspaceId: selectedWorkspace?.data?.id,
    enabled: !!selectedWorkspace?.data?.id,
    onError: () => {
      notify({
        heading: "Failed to fetch Integrations",
        messageList: ["Please try again later or contact support to help you resolve this issue"],
        type: "failure",
      });
    },
  });
  const [nativeStatus, setNativeStatus] = useState<string>("Connect");
  const [nativeBadge, setNativeBadge] = useState<string>("");
  const [segmentBadge, setSegmentBadge] = useState<string>("");
  const [openInvite, setOpenInvite] = useState<boolean>(false);
  const navigate = useNavigate();
  const user: any = useCurrentUser({});
  const segmentIntegration = useMemo(() => integrations?.filter((item: any) => item?.source_name === "segment_api"), [integrations]);
  const nativeIntegration = useMemo(() => integrations?.filter((item: any) => item?.source_name === "native"), [integrations]);

  const segmentClick = async () => {
    if (!segmentIntegration?.length && user) {
      try {
        await createIntegration({
          workspace_identifier: selectedWorkspace?.data?.unique_identifier,
          source_name: "segment_api",
          workspace_id: selectedWorkspace?.data?.id,
          created_by_user_id: user.data.user_id,
          is_active: true,
        });
      } catch (err) {
        return;
      }
    } else if (segmentIntegration[0].events_recieved) {
      return;
    }
    navigate("segment-api");
  };

  const apiClick = async () => {
    if (!nativeIntegration?.length) {
      try {
        await createIntegration({
          workspace_identifier: selectedWorkspace?.data?.unique_identifier,
          source_name: "native",
          workspace_id: selectedWorkspace?.data?.id,
          created_by_user_id: user.data.user_id,
          is_active: true,
        });
      } catch (err) {
        return;
      }
    } else if (nativeIntegration[0].events_recieved) {
      return;
    }
    navigate("hyperengage-api");
  };

  useEffect(() => {
    if (segmentIntegration?.length) {
      if (segmentIntegration[0].setup_complete) {
        setSegmentStatus("Completed");
      } else {
        setSegmentStatus("Complete setup");
        setSegmentBadge("Setup pending");
      }
    }
  }, [segmentIntegration]);

  useEffect(() => {
    if (nativeIntegration?.length) {
      if (nativeIntegration[0].setup_complete) {
        setNativeStatus("Completed");
      } else {
        setNativeStatus("Complete setup");
        setNativeBadge("Setup pending");
      }
    }
  }, [nativeIntegration]);

  return (
    <>
      <InviteTeam
        open={openInvite}
        workspaceId={selectedWorkspace?.data?.id}
        setOpen={setOpenInvite}
      />
      <section className="flex flex-col overflow-auto rounded-lg border-2 border-gray-200 p-12 gap-2 bg-white">
        <h2>Connect with Hyperengage</h2>
        <h4 className="text-gray-700">
          Connect your data sources to decrease your effort and automate your workflows.
        </h4>
        <div className="flex gap-6 mt-5 justify-center">
          <IntegrationContainer
            Icon={<ApiIcon className="!w-[50px] !h-[50px]" />}
            title="Hyperengage Tracking"
            desc="Use our lightweight javascript SDK to start sending product usage data."
            onClick={apiClick}
            text={nativeStatus}
            disabledConnect={nativeIntegration?.length && nativeIntegration[0].setup_complete}
            badge={nativeBadge}
          />
          <IntegrationContainer
            title="Segment"
            Icon={<SegmentIcon className="shrink-0 !w-[50px] !h-[50px]" />}
            desc="Integrate with Hyperengage to import your data here for better management"
            onClick={segmentClick}
            disabledConnect={segmentIntegration?.length && segmentIntegration[0].setup_complete}
            text={segmentStatus}
            badge={segmentBadge}
          />
        </div>
        <div className="flex flex-col gap-1 mt-2 -mb-4">
          <SignOutMistake other />
          <p className="font-sm text-gray-500 text-sm">
            Need help?
            {" "}
            <button type="button" className="border-none text-purple-500 hover:text-purple-800" onClick={() => { setOpenInvite(true); }}>
              Invite a team member
            </button>
            {" "}
            to do the integrations for you.
          </p>
        </div>
      </section>
    </>
  );
};

export default AddIntegration;
