/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { UserIcon } from "@heroicons/react/20/solid";
import { TextInput } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { useMutation } from "@tanstack/react-query";
import HeButton from "../../../../shared/ui/buttons/HeButton";
import { updateViewInApi } from "../../../../services/view/viewEndpoints";
import { queryClient, useCurrentUser } from "../../../../hooks/QueryHooks";
import useWorkspaceStore from "../../../../store/workspace/workspaceStore";
import notify from "../../../../utils/notify";

const AccountHeader = ({ currentView }: { currentView?: any }) => {
  const firstRender = useRef(true);
  const [name, setName] = useState(currentView?.view_name);
  const workspace = useWorkspaceStore((state) => state.workspace);
  const { data: user }: any = useCurrentUser({});
  const [debouncedName] = useDebouncedValue(name, 500);
  const updateViewMutation = useMutation({
    mutationFn: updateViewInApi,
  });

  const handleViewSave = () => {
    updateViewMutation.mutate({
      ...currentView,
      view_name: debouncedName,
      workspace_id: workspace?.id,
    }, {
      onSuccess: (data: any) => {
        queryClient.setQueryData(["views", workspace?.id], (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData.data,
            views: oldData?.data?.views
              ?.map((item: any) => (item.id === data?.data?.id ? data?.data : item)),
          },
        }));
      },
      onError: (error: any) => {
        if (error.response?.status !== 0) {
          notify({
            heading: "Unknown error.",
            type: "failure",
            messageList: [
              "An unknown error occurred while creating the new view. Please try again later or contact our support team for assistance.",
            ],
          });
        }
      },
    });
  };

  useEffect(() => {
    if (debouncedName !== currentView?.view_name && !firstRender.current) {
      handleViewSave();
    } else {
      firstRender.current = false;
    }
  }, [debouncedName, currentView?.view_name]);

  return (
    <div className="mt-2 mb-5 inline-block">
      <TextInput
        value={name}
        onChange={(event: any) => setName(event.currentTarget.value)}
        classNames={{
          input: "!bg-inherit text-gray-900 text-[30px] font-bold border-none hover:!bg-gray-200",
          root: "w-content",
        }}
        onKeyDown={(event: any) => {
          if (event.key === "Enter") {
            event.preventDefault();
            event.currentTarget.blur();
            handleViewSave();
          }
        }}
        styles={{ input: { width: name ? `${(name.length * 16) + 40}px` : "auto" } }}
        readOnly={currentView?.is_precreated}
      />
    </div>
  );
};

export default AccountHeader;
