import React from "react";
import TruncatedTextTooltip from "../ui/truncate/TruncatedText";

type Props = {
  children: React.ReactNode | string,
  title: string,
};

const Trait = ({ children, title }: Props) => (
  <div className="flex flex-col basis-[230px] gap-2 px-2 py-2 text-sm text-gray-500 text-ellipsis overflow-hidden">
    <TruncatedTextTooltip width={230} label={title?.split("_")?.join(" ")} size="sm" c="text.5" weight={600}>{title?.split("_")?.join(" ")}</TruncatedTextTooltip>
    {children}
  </div>
);

export default Trait;
