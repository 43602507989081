/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  LoadingOverlay,
  Paper,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import useWorkspaceStore from "../../store/workspace/workspaceStore";
import { ReactComponent as NoDataIllus } from "../../assets/illustrations/Frame 14084.svg";

const EmptySignalPlaceholder = ({
  message, height, className, isLoading = false,
}: any) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const workspace = useWorkspaceStore((state) => state.workspace);
  return (
    <Paper
      p="lg"
      shadow="sm"
      h={height}
      style={{
        textAlign: "center",
        backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
      }}
      className={`flex relative items-center justify-center flex-col ${className || ""}}`}
    >
      {/* <NoDataIllus className="w-[140px] h-[140px] mt-5" /> */}
      <LoadingOverlay loaderProps={{ size: "md" }} c="main.5" visible={isLoading} overlayBlur={5} />
      <Text weight={600} style={{ marginBottom: "5px", color: theme.colors.text[9] }}>
        No Signals Found
      </Text>
      <Text weight={400} style={{ color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7] }}>
        {message || (
        <>
          Looks like there were no signals triggered recently.
          <Text span className="block -mt-1">
            Try changing the filters or
            {" "}
            <Text
              span
              color="main.5"
              className="cursor-pointer"
              onClick={() => {
                navigate(`/home/${workspace?.unique_identifier}/signals/new`);
              }}
            >
              create your first signal
            </Text>
          </Text>
        </>
        )}
      </Text>
    </Paper>
  );
};

export default EmptySignalPlaceholder;
