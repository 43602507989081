/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TextInput } from "@mantine/core";

import { isEmail, useForm } from "@mantine/form";
import React from "react";
import { addUserToWorkspace } from "../../../../services/workspaceMemberEndpoint";
import HeButton from "../../../../shared/ui/buttons/HeButton";
import notify from "../../../../utils/notify";

type Props = {
  open: boolean,
  setOpen: ((value: boolean) => void)
  | (((value: boolean) => void) & React.FormEvent<HTMLDivElement>),
  workspaceId: string,
};

const InviteTeam = ({ open, setOpen, workspaceId }: Props) => {
  const form = useForm({
    initialValues: {
      email: "",
    },
    validate: {
      email: isEmail("Please provide a valid email address (e.g., example@example.com)."),
    },
  });
  if (!open) {
    return null;
  }

  const submitHandler = async () => {
    form.validate();
    if (form.isValid()) {
      try {
        await addUserToWorkspace({
          workspace_id: workspaceId,
          email: form.values.email,
          role_name: "Member",
        });
        notify({
          type: "success",
          messageList: ["Invite Sent Successfully"],
        });
        setOpen(false);
      } catch (err: any) {
        if (err.response?.status !== 0) {
          notify({
            heading: "Unknown Error",
            type: "failure",
            messageList: ["An unknown error occurred. Please try again later or contact our support team for assistance."],
          });
        }
      }
    }
  };

  return (
    <div className="absolute inset-0 z-[9000] backdrop-blur-sm bg-gray-700/50 text-white flex flex-col items-center gap-10 justify-center" onClick={() => { setOpen(false); }}>
      <form className="mx-10 flex flex-col rounded-lg border border-gray-900 px-7 pt-7 pb-5 w-full max-w-[700px] gap-1 bg-white" onClick={(e) => { e.stopPropagation(); }}>
        <h2 className="font-semibold">Invite a Team Member</h2>
        <h4>
          Need help getting set up? Invite your RevOps leader to connect
          the CRM or a developer to install Hyperengage in to your website.
        </h4>
        <TextInput classNames={{ input: "text-sm" }} {...form.getInputProps("email")} className="mt-2 mb-2" id="email" placeholder="example@example.com" />
        <div className="flex justify-end align-center mt-5 gap-4">
          <button type="button" className="border-none text-purple-600 hover:text-purple-800" onClick={() => { setOpen(false); }}>
            Back
          </button>
          <HeButton color="purple" text="Send invite" size="sm" onClick={submitHandler} />
        </div>
      </form>
    </div>
  );
};

export default InviteTeam;
