/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { Select } from "@mantine/core";
import {
  format,
  isValid,
  parseISO,
} from "date-fns";
import {
  useEffect, useLayoutEffect, useMemo, useRef, useState,
} from "react";
import uuid from "react-uuid";
import dateConverter from "../../utils/dateConverter";
import FormInput from "../ui/inputs/text/FormInput";

type Props = {
  handleChange: (
    operator: string,
    value: any,
    type: string,
    entityType: string,
  ) => void;
  fieldSelect: boolean;
  op?: string;
  value?: any;
};

const DateFilter = (props: Props) => {
  const {
    op,
    value,
    handleChange,
    fieldSelect,
  } = props;

  const [changeType, setChangeType] = useState<any>(
    !op || (op === "within" || op === "before" || op === "relative")
      ? "relative" : "absolute",
  );

  const ops = useMemo(
    () => (changeType === "relative" ? [
      { label: "within", value: "relative" },
      { label: "before", value: "before" },
      { label: "after", value: "after" },
    ]
      : [
        { label: "is equal to", value: "on" },
        { label: "is greater than", value: "after" },
        { label: "is less than", value: "before" },
        { label: "is in range", value: "between" },
      ]),
    [changeType],
  );

  const timeFrame = useMemo(
    () => [
      "Last day",
      "Last 7 days",
      "Last 30 days",
      "Last 90 days",
      "Last 180 days",
      "Last 365 days",
      "Next day",
      "Next 7 days",
      "Next 30 days",
      "Next 90 days",
      "Next 180 days",
      "Next 365 days",
    ].map((key) => ({ label: key, value: key })),
    [],
  );
  const [relativeRange, setRelativeRange] = useState<string>(value
    && changeType === "relative" ? (value?.includes("day") ? value : undefined) : undefined);
  const [firstDate, setFirstDate] = useState<any>(value?.start ? format(parseISO(value.start), "yyyy-MM-dd")
    : (value && changeType === "absolute" && isValid(parseISO(value)) ? format(parseISO(value), "yyyy-MM-dd") : undefined));
  const [secondDate, setSecondDate] = useState<any>(value?.end ? format(parseISO(value?.end), "yyyy-MM-dd") : undefined);
  const [selectOp, setSelectOp] = useState<any>(ops.find((item) => item.value === op)?.value || "");
  const firstRender = useRef(true);

  useLayoutEffect(() => {
    if ((op === "within" || op === "before" || op === "relative") && changeType !== "relative") {
      setSelectOp(undefined);
    } else if ((op === "eq" || op === "gt" || op === "lt" || op === "range") && changeType !== "absolute") {
      setSelectOp(undefined);
    }
  }, [changeType]);

  useEffect(() => {
    if (!firstRender.current) {
      if (changeType === "relative" && !!selectOp && !!relativeRange) {
        handleChange(
          selectOp,
          relativeRange,
          "timestamp",
          "column",
        );
      }
      if (changeType === "absolute" && !!selectOp) {
        if (selectOp !== "range" && !!firstDate) {
          handleChange(
            selectOp,
            dateConverter(new Date(firstDate)),
            "timestamp",
            "column",
          );
        } else if (selectOp === "range" && !!firstDate && !!secondDate) {
          handleChange(
            selectOp,
            {
              start: dateConverter(new Date(firstDate)),
              end: dateConverter(new Date(secondDate)),
            },
            "timestamp",
            "column",
          );
        }
      }
    } firstRender.current = false;
  }, [firstDate, secondDate, selectOp, relativeRange]);

  return (
    <>
      <Select
        placeholder="Select Change Type"
        data={[
          { value: "relative", label: "relative date" },
          { value: "absolute", label: "absolute date" },
        ]}
        value={changeType}
        onChange={(val: any) => setChangeType(val)}
      />
      {changeType === "relative" ? (
        <>
          <Select
            placeholder="Select Interval"
            data={ops}
            value={selectOp}
            onChange={(val: any) => setSelectOp(val)}
          />
          <Select
            placeholder="Last ..."
            data={timeFrame}
            value={relativeRange}
            onChange={(val: any) => setRelativeRange(val)}
          />
        </>
      ) : (
        <>
          <Select
            placeholder="Select Operation"
            data={ops}
            value={selectOp}
            onChange={(val: any) => setSelectOp(val)}
            disabled={fieldSelect}
          />
          <FormInput
            disabled={!selectOp}
            type="date"
            className="border-0"
            placeHolder="Select Date"
            id={uuid()}
            value={firstDate}
            onChange={(e: any) => {
              setFirstDate(e.target.value);
            }}
          />
          {selectOp === "range" && (
            <FormInput
              type="date"
              className="border-0"
              placeHolder="Select end date"
              id={uuid()}
              value={secondDate}
              onChange={(e: any) => {
                setSecondDate(e.target.value);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default DateFilter;
