/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import axios from "axios";

import notify from "../utils/notify";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 0) {
        notify({
          heading: "Unknown Error",
          type: "failure",
          messageList: [
            "An unknown error occurred. Please try again later or contact our support team for assistance. We apologize for any inconvenience caused.",
          ],
        });
      }
    }
    return Promise.reject(error);
  },
);

const authorizedApiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export { apiClient, authorizedApiClient };
