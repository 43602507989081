/* eslint-disable no-nested-ternary */
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { Flex } from "@mantine/core";
import React from "react";
import StepModal from "./StepModal";

type Props = {
  onClick?: (stepNo: number) => any,
  isOpen?: boolean,
  children: React.ReactNode,
  desc: React.ReactNode,
  stepNo: number,
  status: string,
  last?: boolean,
  title: string,
};

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

const StepWithProgressbar = ({
  onClick = () => {},
  isOpen = false,
  children,
  desc,
  status,
  last = false,
  stepNo,
  title,
}: Props) => (
  <li key={stepNo} className={classNames(last ? "pb-10" : "", "relative")}>
    {status === "complete" ? (
      <>
        {!last ? (
          <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-purple-600" aria-hidden="true" />
        ) : null}
        <div className="flex flex-col gap-2 pb-6">
          <Flex className="justify-between items-center" onClick={() => onClick(stepNo)}>
            <div className="group relative flex items-start">
              <span className="flex h-9 items-center">
                <span className="relative z-4 flex h-8 w-8 items-center justify-center rounded-full bg-purple-600 group-hover:bg-purple-800">
                  <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                </span>
              </span>
              <span className="ml-4 flex min-w-0 flex-col">
                <span className="text-sm capitalize text-gray-800 font-medium">
                  {title}
                </span>
                <span className="text-sm text-gray-500">{desc}</span>
              </span>
            </div>
            <button type="button" className="border-none w-7 justify-self-end text-gray-600 hover:text-gray-900 shrink-0">{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}</button>
          </Flex>
          <StepModal isOpen={isOpen}>{children}</StepModal>
        </div>
      </>
    ) : status === "current" ? (
      <>
        {!last ? (
          <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
        ) : null}
        <div className="flex flex-col gap-3 pb-6">
          <Flex className="justify-between items-center" onClick={() => onClick(stepNo)}>
            <div className="group relative flex items-start" aria-current="step">
              <span className="flex h-9 items-center" aria-hidden="true">
                <span className="relative z-4 flex h-8 w-8 items-center justify-center rounded-full border-2 border-purple-600 bg-white">
                  <span className="h-2.5 w-2.5 rounded-full bg-purple-600" />
                </span>
              </span>
              <span className="ml-4 flex min-w-0 flex-col">
                <span className="text-sm font-medium text-purple-600 capitalize">
                  {title}
                </span>
                <span className="text-sm text-gray-500">{desc}</span>
              </span>
            </div>
            <button type="button" className="border-none w-7 justify-self-end text-gray-600 hover:text-gray-900 shrink-0">{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}</button>
          </Flex>
          <StepModal isOpen={isOpen}>{children}</StepModal>
        </div>
      </>
    ) : (
      <>
        {!last ? (
          <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
        ) : null}
        <div className="flex flex-col gap-3 pb-6">
          <Flex className="justify-between items-center" onClick={() => onClick(stepNo)}>
            <div className="group relative flex items-start">
              <span className="flex h-9 items-center" aria-hidden="true">
                <span className="relative z-4 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                  <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                </span>
              </span>
              <span className="ml-4 flex min-w-0 flex-col">
                <span className="text-sm font-medium text-gray-800 capitalize">
                  {title}
                </span>
                <span className="text-sm text-gray-500">{desc}</span>
              </span>
            </div>
            <button type="button" className="border-none w-7 shrink-0 justify-self-end text-gray-600 hover:text-gray-900">
              <div className="sr-only" />
              {isOpen ? <ChevronUpIcon className="w-7" /> : <ChevronDownIcon />}
            </button>
          </Flex>
          <StepModal isOpen={isOpen}>{children}</StepModal>
        </div>
      </>
    )}
  </li>
);

export default StepWithProgressbar;
