import {
  Group,
  RingProgress,
  Tooltip, useMantineTheme,
} from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { NavLink } from "react-router-dom";

const ProgressRing = ({ percent, menu }: any) => (
  <RingProgress
    size={20}
    thickness={3}
    className={`${menu ? "mr-1" : ""}`}
    sections={[{ value: percent, color: percent === 100 ? "green" : "main.5" }]}
  />
);

type Props = {
  text?: string, className? : string, to: string,
  activeClassName?: string, count?: number,
  menu?: boolean,
  label?: string,
};

const GettingStartedLink = ({
  text,
  label,
  to = "#",
  count,
  className,
  activeClassName,
  menu = true,
} : Props) => {
  const { hovered, ref } = useHover();
  const theme = useMantineTheme();
  let linkStyle = `group ${menu ? "justify-between" : "justify-evenly"} text-gray-500 flex-nowrap ease-linear duration-300 border-l-6 border-transparent hover:text-gray-900 hover:bg-gray-100`;
  let activeLinkStyle = `group ${menu ? "justify-between" : "justify-evenly"} text-purple-600 flex-nowrap ease-linear duration-300 border-l-6 bg-purple-50 border-purple-600 hover:text-purple-800 hover:bg-purple-100`;

  if (className) {
    linkStyle = `${linkStyle} ${className}`;
  }

  if (activeClassName) {
    activeLinkStyle = `${activeLinkStyle} ${activeClassName}`;
  }

  return (
    <NavLink
      to={to}
    >
      {({ isActive }) => (
        <Tooltip opened={hovered && !menu} withinPortal label={label} position="right" zIndex={1000}>
          <Group
            ref={ref}
            c={theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[8]}
            align="center"
            position="apart"
            px="sm"
            py="sm"
            className={isActive ? activeLinkStyle : linkStyle}
          >
            <div className="flex items-center font-semibold text-sm">
              <ProgressRing percent={100} menu={menu} />
              {text}
            </div>
            {count && (<span className={`px-3 py-1 text-[12px] text-gray-700 rounded-full ${isActive ? `${hovered ? "bg-purple-200" : "bg-purple-100"} text-purple-800 ` : `${hovered ? "bg-white text-gray-900" : "bg-gray-50"}`}`}>{count}</span>)}
          </Group>
        </Tooltip>
      )}
    </NavLink>
  );
};

export default GettingStartedLink;
