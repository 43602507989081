/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */

import { ICellTextProps } from "ka-table/props";
import React, { useState } from "react";
import {
  Flex, Progress, Switch, Text,
} from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { updateAutomation } from "../../services/automation/automationEndpoints";
import { queryClient } from "../../hooks/QueryHooks";
import useWorkspaceStore from "../../store/workspace/workspaceStore";
import notify from "../../utils/notify";

const SignalActivityCell: React.FC<ICellTextProps> = React.memo(
  ({
    column, dispatch, rowKeyValue, ...props
  }) => {
    const value = props.rowData[`${column.key}`];
    const [checked, setChecked] = useState(value === "active");
    const workspace = useWorkspaceStore((state) => state.workspace);
    const updateActivityMutation = useMutation({
      mutationFn: updateAutomation,
      onSuccess: (data: any) => {
        queryClient.setQueryData(["all_automations", workspace?.id, props.rowData?.automation_type], (oldData: any) => ({
          ...oldData,
          data: oldData?.data
            ?.map((item: any) => (item.id === props.rowData.id ? data?.data : item)),
        }));
        notify({
          type: "success",
          messageList: [`${checked ? "Signal is now active." : "Signal paused."}`],
        });
      },
      onError: (err: any) => {
        if (err.response?.status !== 0) {
          notify({
            heading: "Unknown error.",
            type: "failure",
            messageList: [
              "An unknown error occurred while updating the signal. Please try again later or contact our support team for assistance.",
            ],
          });
        }
      },
    });
    return (
      <Flex align="center" gap={5}>
        <Switch
          checked={checked}
          size="md"
          onChange={() => {
            setChecked(!checked);
            updateActivityMutation.mutate({
              automation_id: props.rowData.id,
              status: !checked ? "active" : "paused",
              workspace_id: workspace?.id,
            });
          }}
          onLabel="ON"
          offLabel="OFF"
        />
      </Flex>
    );
  },
);

export default SignalActivityCell;
