/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
import { Text } from "@mantine/core";
import { ICellTextProps } from "ka-table/props";
import React from "react";
import uuid from "react-uuid";

/**
 * Custom cell component.
 */
const IconsGroupCell: React.FC<ICellTextProps> = React.memo(
  ({
    column, dispatch, rowKeyValue, ...props
  }) => {
    const value = props.rowData[`${column.key}`];
    return (
      <div className="flex flex-row gap-1 items-center text-gray-400">
        {value.map((name: any, index: number) => (
          <>
            <name.Icon key={uuid()} className="h-[17px] w-[17px] hover:scale-110" />
            <span>
              <Text className="text-xs font-semibold text-gray-700" c="text.5">
                {name.name}
                {" "}
                <Text span className="mx-1">{index !== value.length - 1 ? "&" : ""}</Text>
                {" "}
              </Text>
            </span>
          </>
        ))}
      </div>
    );
  },
);
export default IconsGroupCell;
