/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
import { PlusIcon } from "@heroicons/react/20/solid";
import { BuildingOfficeIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { Menu } from "@mantine/core";
import { useAuthInfo } from "@propelauth/react";
import React, {
  useMemo,
  useState,
} from "react";
import { NavLink, useParams } from "react-router-dom";
import CreateView from "./CreateView";

type Props = {
  list?: any[],
  type: string,
  children?: React.ReactNode,
  isOpen: any,
  setIsOpen: any,
};

const MoreViews = ({
  list = [], type, isOpen = false, setIsOpen, children,
}: Props) => {
  const params: any = useParams();
  const currentUser = useAuthInfo().user;
  const [createView, setCreateView] = useState<boolean>(false);
  const myList = useMemo(() => list
    ?.filter((item: any) => item?.owner_id === currentUser?.userId), [list]);
  const othersList = useMemo(() => list
    ?.filter((item: any) => item?.owner_id !== currentUser?.userId), [list]);

  const ViewIcon = type === "users" ? UserCircleIcon : BuildingOfficeIcon;

  return (
    <>
      {createView && <CreateView open={createView} setOpen={setCreateView} />}
      <Menu onChange={setIsOpen} zIndex={5} closeOnClickOutside withArrow withinPortal position="right" arrowPosition="center" opened={isOpen}>
        <Menu.Target>
          {children}
        </Menu.Target>
        <Menu.Dropdown className="divide-y divide-gray-200 border flex-col min-w-[250px] border-gray-300 fle z-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <button className="flex text-sm items-center text-purple-600 hover:text-purple-900 font-semibold px-2 gap-1 py-2" type="button" onClick={() => { setCreateView(true); }}>
            <PlusIcon className="w-4 h-4" />
            Create a new view
          </button>
          <h3 className="px-4 bg-gray-100 py-2 text-sm text-gray-500 italic font-bold ">My Views</h3>
          <div className="max-h-[210px] overflow-y-auto">
            {myList?.length ? myList.map((subItem: any) => (
              <NavLink
                to={`/home/${params.workspaceId}/view/${subItem.id}`}
                key={`/home/${params.workspaceId}/view/${subItem.id}`}
              >
                {({ isActive }) => (
                  <div className={`
                  hover:bg-gray-50 flex items-center justify-between 
                   ${isActive ? "text-purple-600 bg-purple-50 text-sm font-semibold px-6 py-2 " : "text-gray-700 text-sm font-semibold px-6 py-2 hover:text-gray-900"}
                   `}
                  >
                    {subItem.view_name}
                    <ViewIcon className="h-5 w-5" />
                  </div>
                )}
              </NavLink>
            )) : <h3 className="px-4 bg-gray-50 py-5 text-sm text-gray-400 font-semibold ">Create a new view to begin</h3>}
          </div>
          <h3 className="px-4 bg-gray-100 py-2 text-sm text-gray-500 italic font-bold ">Created by others</h3>
          <div className="max-h-[210px] overflow-y-auto">
            {othersList?.length ? othersList.map((subItem: any) => (
              <NavLink
                to={`/home/${params.workspaceId}/view/${subItem.id}`}
                key={`/home/${params.workspaceId}/view/${subItem.id}`}
              >
                {({ isActive }) => (
                  <div className={`
                  hover:bg-gray-50 flex items-center justify-between 
                   ${isActive ? "text-purple-600 bg-purple-50 text-sm font-semibold px-6 py-2 " : "text-gray-700 text-sm font-semibold px-6 py-2 hover:text-gray-900"}
                   `}
                  >
                    {subItem.view_name}
                    <ViewIcon className="h-5 w-5" />
                  </div>
                )}
              </NavLink>
            )) : <h3 className="px-4 bg-gray-50 py-5 text-sm text-gray-400 font-semibold ">No views created by other users yet</h3>}
          </div>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};

export default MoreViews;
