/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */

import { ArrowDownIcon, ArrowUpIcon, MinusIcon } from "@heroicons/react/20/solid";
import { ICellTextProps } from "ka-table/props";
import React from "react";
import Avatar from "../ui/avatar/Avatar";

const MemberCell: React.FC<ICellTextProps> = React.memo(
  ({
    column, dispatch, rowKeyValue, ...props
  }) => {
    const value = props.rowData[`${column.key}`];
    return (
      <span className="whitespace-nowrap capitalize w-full text-ellipsis overflow-hidden block">
        <Avatar
          name={value?.name}
          desc={value?.desc}
          img={value?.img}
          size="basic"
          rounded
        />
      </span>
    );
  },
);

export default MemberCell;
