/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  LoadingOverlay,
  Paper,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { ReactComponent as NoDataIllus } from "../../assets/illustrations/Frame 14084.svg";

const EmptySignalBodyPlaceholder = ({
  message, height, className, isLoading = false,
}: any) => {
  const theme = useMantineTheme();
  return (
    <Paper
      p="lg"
      h={height}
      style={{
        textAlign: "center",
        backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
      }}
      className={`flex relative items-center justify-center flex-col w-full border-none ${className || ""}}`}
    >
      {/* <NoDataIllus className="w-[140px] h-[140px] mt-5" /> */}
      <LoadingOverlay loaderProps={{ size: "md" }} c="main.5" visible={isLoading} overlayBlur={2} />
      <Text weight={600} style={{ marginBottom: "5px", color: theme.colors.text[9] }}>
        No Signal Selected.
      </Text>
      <Text weight={400} style={{ color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7] }}>
        {message || (
        <Text span className="block -mt-1">Select a Signal to begin looking at playbooks, comments, and actions</Text>
        )}
      </Text>
    </Paper>
  );
};

export default EmptySignalBodyPlaceholder;
