import { Text, Tooltip } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import React from "react";

type Props = {
  label: string,
  info: string,
  required?: boolean
};

const LabelWithTooltip = ({
  label, info, required = false,
}: Props) => (
  <div className="flex items-center gap-1">
    {label}
    {required && <Text span c="red.5">*</Text>}
    <Tooltip withinPortal multiline label={info}>
      <IconInfoCircle className="text-gray-400 w-[15px] cursor-context-menu" />
    </Tooltip>
  </div>
);

export default LabelWithTooltip;
