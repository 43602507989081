/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import {
  Container, Flex, LoadingOverlay, Text, Transition,
} from "@mantine/core";
import SignalsContainer from "../../../shared/signals/SignalsContainer";
import HqDetails from "../headquarters/components/HqDetails";
import useWorkspaceStore from "../../../store/workspace/workspaceStore";
import { useAccount, useContact, useCurrentUser } from "../../../hooks/QueryHooks";

const transitionStyles = {
  in: { opacity: 1, transform: "scaleX(1)" },
  out: { opacity: 0, transform: "scaleX(0)" },
  common: { transformOrigin: "right" },
  transitionProperty: "transform, opacity",
};

const InboxPage = () => {
  const [selected, setSelected] = useState(null);
  const [selectedSignal, setSelectedSignal] = useState(null);
  const workspace = useWorkspaceStore((state) => state.workspace);
  const [isOpen, setIsOpen] = useState(false);
  const [entityType, setEntityType] = useState("accounts");

  const account = useAccount(
    {
      workspaceId: workspace?.id,
      accountId: selected || "",
      enabled: !!workspace?.id && !!selected && entityType === "accounts",
    },
  );
  const contact = useContact(
    {
      workspaceId: workspace?.id,
      contactId: selected || "",
      enabled: !!workspace?.id && !!selected && entityType === "users",
    },
  );
  const handleSelectSignal = (acc: any, signal: any, select: boolean, entity: any) => {
    setEntityType(entity);
    if (select) {
      setSelected(null);
      setSelectedSignal(null);
      setIsOpen(false);
    } else {
      setSelected(acc);
      setSelectedSignal(signal);
      if (!isOpen) {
        setIsOpen(true);
      }
    }
  };

  const onTabClick = (tab: string) => {
    setEntityType(tab);
    setSelectedSignal(null);
    setSelected(null);
    setIsOpen(false);
  };

  return (
    <Container fluid className="px-12 py-8 overflow-auto w-full relative h-full bg-gray-100 ">
      <Text pt={0} mb={14} c="text.9" size={30} fw={700}>
        Your Inbox
      </Text>
      <Flex className="w-full self-stretch" gap={12}>
        <SignalsContainer showTitle={false} onTabClick={onTabClick} selectedSignal={selectedSignal} workspace={workspace} onAccountClick={handleSelectSignal} bodyClass="!h-[calc(100vh-240px)] max-h-full" />
        <Transition mounted={isOpen} transition={transitionStyles} duration={200} timingFunction="ease">
          {(styles: any) => (
            <div className="basis-[550px]" style={{ ...styles, position: "relative" }}>
              <LoadingOverlay loaderProps={{ size: "md" }} c="main.5" visible={account.isLoading} overlayBlur={2} />
              <HqDetails
                workspaceId={workspace?.id}
                entityRelation={entityType}
                entity={entityType === "accounts" ? account?.data : contact?.data}
              />
            </div>
          )}
        </Transition>
      </Flex>
    </Container>
  );
};
export default InboxPage;
