import React, { useState } from "react";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import HeButton from "../../../../shared/ui/buttons/HeButton";

type Props = {
  textToCopy: string,
};

const CopyButton = ({ textToCopy }: Props) => {
  const [show, setShow] = useState<string>("Copy code");

  return (
    <div className="relative">
      <HeButton
        LeadingIcon={PaperClipIcon}
        size="sm"
        text={show}
        className="w-[124px]"
        onClick={async () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          await navigator.clipboard.writeText(textToCopy);
          setShow("Copied !");
          setTimeout(() => {
            setShow("Copy code");
          }, 3000);
        }}
        color="white"
      />
    </div>
  );
};

export default CopyButton;
