/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */

import { ICellTextProps } from "ka-table/props";
import React from "react";
import { getValueForRow } from "../../utils/tableUtils";
import { ReactComponent as HeIcon } from "../../assets/images/heSm.svg";

const renderSourceIcon = (url: string) => {
  let temp = url;
  if (temp.includes("segment")) {
    temp = "segment.com";
  } else if (temp.includes("salesforce")) {
    temp = "salesforce.com";
  } else if (temp.includes("stripe")) {
    temp = "stripe.com";
  } else if (url === "hyperengage") {
    return <HeIcon height={30} />;
  }
  let iconUrl = `https://fav.gs/${url.length > 0 ? url : "temp-mail.org"}`;
  if (url.startsWith("http")) {
    url = url.replaceAll("http://", "").replaceAll("https://", "");
    iconUrl = `https://fav.gs/${url}`;
  }
  if (!iconUrl.endsWith(".com")) {
    iconUrl += ".com";
  }
  return (
    <img height={16} src="https://fav.gs/segment" alt="" />
  );
};

const SourceCell: React.FC<ICellTextProps> = React.memo(
  ({
    column, dispatch, rowKeyValue, ...props
  }) => {
    const value = props.rowData[`${column.key}`];
    const [source, medium] = value ? value.split("|||") : ["", "", ""];
    return (
      <span className="whitespace-nowrap lowercase flex items-center w-full text-ellipsis overflow-hidden">
        {renderSourceIcon(source)}
        <div className="ml-3">
          <div data-tip={value} className="ka-cell-text lowercase">
            <span>
              {medium ? ` ${medium} ` : ""}
              {" "}
            </span>
            {medium?.length > 0 ? "/ " : ""}
            {source ? `${source}` : "Direct"}
          </div>
        </div>
      </span>
    );
  },
);

export default SourceCell;
