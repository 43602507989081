/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { addDays, differenceInDays } from "date-fns";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MultiSelect, Select } from "@mantine/core";
import { getCompanyEventList, getCompanyTrends } from "../../../../services/companyEndpoint";
import DateRangeDropdown from "../../../../shared/ui/dropdown/DateRangeDropdown";
import classNames from "../../../../utils/classNames";
import dateConverter, { dateFormatter } from "../../../../utils/dateConverter";
import TrendsChart from "./components/TrendsChart";
import useWorkspaceStore from "../../../../store/workspace/workspaceStore";

const AccountTrends = () => {
  const workspaceId = useWorkspaceStore((state) => state.workspace?.id);
  const [events, setEvents] = useState<any>([]);
  const [dateRange, setDateRange] = useState<any>({
    selection: {
      startDate: addDays(new Date(), -6),
      endDate: new Date(),
      key: "selection",
    },
  });
  const [selectedEvents, setSelectedEvents] = useState<any>();
  const [data, setData] = useState<any>([]);
  const params: any = useParams();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const currentData = await getCompanyEventList({
          external_id: params.accountId,
          workspace_identifier: workspaceId,
        });
        if (currentData.data) {
          setEvents(currentData.data?.results
            ?.map((event: any) => ({ value: event.event_name, label: event.event_name })) || []);
          setSelectedEvents([currentData.data?.results[0]?.event_name]);
        }
      } catch {
        setEvents([]);
      }
    };
    fetchEvents();
  }, [params.accountId, workspaceId]);

  useEffect(() => {
    if (selectedEvents?.length && selectedEvents[0]) {
      const getTable = async () => {
        let bucketSize: any;
        const difference = differenceInDays(
          dateRange.selection.endDate,
          dateRange.selection.startDate,
        );
        if (difference < 14) {
          bucketSize = "day";
        } else if (difference < 63) {
          bucketSize = "week";
        } else if (difference < 365) {
          bucketSize = "month";
        } else {
          bucketSize = "year";
        }
        try {
          const graphData = await getCompanyTrends({
            workspace_identifier: workspaceId,
            external_id: params.accountId,
            event_names: selectedEvents,
            bucket_size: bucketSize,
            from_date: dateConverter(dateRange.selection.startDate),
            to_date: dateConverter(dateRange.selection.endDate),
          });
          setData(graphData.data.results.map((dates: any) => ({
            ...dates,
            date: dateFormatter(dates.date),
          })));
        } catch (e) {
          setData([]);
        }
      };
      getTable();
    }
  }, [params.accountId, selectedEvents, dateRange, workspaceId]);

  return (
    <div className="p-[15px] h-full flex flex-col gap-3">
      <div className="ml-auto px-4 flex gap-2">
        <div className="flex flex-col">
          <h4 className="text-gray-500 text-xs">Select events</h4>
          <MultiSelect
            value={selectedEvents}
            searchable
            onChange={setSelectedEvents}
            data={events}
          />
        </div>
        <DateRangeDropdown
          state={dateRange}
          setState={setDateRange}
        />
      </div>
      {selectedEvents?.length && selectedEvents[0]
        ? <TrendsChart data={data} />
        : <h4 className="text-lg text-gray-500 w-[80%] self-center h-full text-center my-10 flex items-center justify-center font-bold italic rounded-md bg-gray-50">Please select an event from the dropdown list to begin looking at trends </h4>}
    </div>
  );
};

export default AccountTrends;
