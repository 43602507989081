import { Overlay, Text } from "@mantine/core";
import Pill from "../ui/badges/Pill";
import ConnectButton from "../ui/buttons/ConnectButton";

type Props = {
  disabledConnect?: boolean,
  integrationDisabled?: boolean,
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => any,
  text?: string,
  badge: string,
  title: string,
  desc: string,
  Icon: any,
  containerClassName?: string,
};

const IntegrationContainer = ({
  onClick, disabledConnect = false, containerClassName, integrationDisabled = false, text = "Connect", badge, title, desc, Icon,
}: Props) => (
  <div className={`flex relative w-[320px] h-[250px] duration-100 items-center justify-center gap-2 p-[20px] rounded-lg border-2 border-gray-200 ${containerClassName || ""}`}>
    {badge?.length > 0
    && <div className="absolute right-4 top-4"><Pill color="red" text={badge} /></div>}
    {integrationDisabled && (
    <Overlay blur={2} color="text.2" center opacity={0.3} zIndex={1}>
      <Text align="center" className="px-9 py-3 italic font-semibold capitalize bg-gray-800 rounded-md opacity-80" size="lg" c="text.1">
        Coming Soon...
      </Text>
    </Overlay>
    )}
    <div className="flex flex-col gap-3 justify-start text-left">
      <div className="-ml-1">{Icon}</div>
      <h2 className="text-left text-base">{title}</h2>
      <p className="text-gray-600 text-sm text-left tracking-tight">
        {desc}
      </p>
      <div>
        <ConnectButton text={text} onClick={onClick} disabled={disabledConnect} />
      </div>
    </div>
  </div>
);

export default IntegrationContainer;
