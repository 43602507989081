/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
import notify from "../../utils/notify";
import { authorizedApiClient } from "../config";
import {
  CreateWorkspace,
  CreateWorkspaceResponse,
  GetWorkspace,
  GetWorkspaceResponse,
  UpdateWorkspace,
  UpdateWorkspaceResponse,
  UserPreferenceResponse,
  WorkspacePreferenceResponse,
  WorkspaceTraitResponse,
  WorkspaceTraitsQuery,
  WorkspaceTrendsQuery,
  WorkspaceTrendsResponse,
} from "./workspaceEndpoints.interface";

const showErrorAlert = (err: any) => notify({
  heading: "Unknown Error",
  type: "failure",
  messageList: [
    "Kindly contact Hyperengage support to help you resolve this issue.",
  ],
});

export const getWorkspaceTraitsAndMetrics = async ({ workspaceId }: WorkspaceTraitsQuery) => {
  try {
    const response = await authorizedApiClient.post<WorkspaceTraitResponse>(
      `/workspace/${workspaceId}/traits`,
    );
    return response.data;
  } catch (err) {
    showErrorAlert(err);
  }
};

export const getWorkspaceTrendsAgaintQuery = async (
  {
    workspaceId, entityType, query, signal,
  }: WorkspaceTrendsQuery,
) => {
  try {
    const response = await authorizedApiClient.post<WorkspaceTrendsResponse>(
      `/workspace/${workspaceId}/trend_search`,
      {
        entity_type: entityType,
        ...query,
      },
      {
        ...(signal?.token ? {
          cancelToken: signal?.token,
        } : {}),
      },
    );
    return response.data;
  } catch (err: any) {
    if (err?.message === "Component unmounted or effect re-ran") {
      throw new Error("Component unmounted or effect re-ran");
    }
    showErrorAlert(err);
    return err;
  }
};

export const createWorkspace = async ({
  is_default_workspace, workspace_name, workspace_owner_id, workspace_time_zone, organisation_id,
}: CreateWorkspace) => authorizedApiClient.post<CreateWorkspaceResponse>("/workspace/", {
  workspace_name,
  workspace_owner_id,
  workspace_time_zone,
  organisation_id,
  is_default_workspace,
});

export const getWorkspace = async ({ workspace_id }: GetWorkspace) => authorizedApiClient.get<GetWorkspaceResponse>(`/workspace/${workspace_id}`);

export const updateWorkspace = async ({
  workspace_id,
  tracking_website_domain,
  setup_complete,
  workspace_logo,
  workspace_time_zone,
  workspace_default_currency,
  getting_started_guide_dismissed,
  is_default_workspace,
}: UpdateWorkspace) => authorizedApiClient.put<UpdateWorkspaceResponse>(`/workspace/${workspace_id}`, {
  tracking_website_domain,
  getting_started_guide_dismissed,
  setup_complete,
  workspace_logo,
  workspace_time_zone,
  workspace_default_currency,
  is_default_workspace,
});

export const getDefaultWorkspacePreferences = async () => authorizedApiClient.get<WorkspacePreferenceResponse>("/workspace/preferences/workspace");

export const getDefaultUserPreferences = async () => authorizedApiClient.get<UserPreferenceResponse>("/workspace/preferences/user");
