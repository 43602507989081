import {
  ActionIcon, Badge, Card, Flex, Modal, Text, Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconEdit, IconInfoCircle, IconPlus, IconTrash,
} from "@tabler/icons-react";
import React from "react";
import MButton from "../../../../../shared/ui/buttons/MButton";

type Props = {
  action: string;
  desc: string;
  Image: any;
  onClick: any;
  enabled?: boolean;
  complete?: boolean;
  onDelete?: any;
  info?: any;
};

const ActionCard = ({
  action, desc, Image, onClick, onDelete, enabled = true, complete = false, info,
}: Props) => {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <Card withBorder shadow="sm" w={300} h={300} className="flex relative items-center justify-center flex-col">
      <Modal
        opened={opened}
        onClose={close}
        title={<Text weight={700}>Delete Action</Text>}
        centered
      >
        <Text>Are you sure you want to delete this action?</Text>
        <Flex justify="flex-end" gap={5} className="mt-4">
          <MButton
            onClick={() => {
              close();
            }}
            variant="outline"
            className="hover:bg-red-100"
            color="red"
          >
            Cancel
          </MButton>
          <MButton
            color="purple"
            onClick={
              () => {
                onDelete();
                close();
              }
            }
          >
            Confirm
          </MButton>
        </Flex>
      </Modal>
      {complete
    && (
    <Flex gap={2} className="absolute right-[10px] top-[10px]">
      <ActionIcon size="sm" color="main.3" variant="light" className="bg-purple-50" radius="lg" disabled={!enabled} onClick={onClick}>
        <IconEdit className="cursor-pointer" />
      </ActionIcon>
      <ActionIcon size="sm" color="red.3" variant="light" className="bg-red-50" radius="lg" disabled={!enabled} onClick={open}>
        <IconTrash className="cursor-pointer" />
      </ActionIcon>
    </Flex>
    )}
      {info
    && (
      <div className="absolute left-3 top-[10px] cursor-context-menu">
        <Tooltip withinPortal multiline w={300} label={info}>
          <ActionIcon className="" size="sm" color="gray.4" variant="light" radius="lg">
            <IconInfoCircle />
          </ActionIcon>
        </Tooltip>
      </div>
    )}
      <Card.Section className="flex items-center justify-center">
        <Image className="h-12 w-12 text-gray-700" />
      </Card.Section>
      <Card.Section className="flex flex-col items-center justify-center">
        <Text size="sm" weight={700} c="text.9">{action}</Text>
        <Text size="xs" c="text.5">{desc}</Text>
      </Card.Section>
      <Card.Section className="flex mt-2 flex-col items-center justify-center">
        {!complete && (
        <ActionIcon color="main.3" variant="light" className={!enabled ? "bg-gray-50" : "bg-purple-50"} radius="lg" disabled={!enabled} onClick={onClick}>
          <IconPlus className="cursor-pointer" />
        </ActionIcon>
        )}
        {complete && (
        <Badge color="purple" className="mt-2">
          Complete
        </Badge>
        )}
      </Card.Section>
    </Card>
  );
};

export default ActionCard;
