import { BuildingOffice2Icon, UserCircleIcon } from "@heroicons/react/20/solid";
import { Text } from "@mantine/core";
import React from "react";

type Props = {
  total: number;
  entityRelation?: "accounts" | "users";
};

const ConditionsMeet = ({
  total,
  entityRelation,
}: Props) => (
  <Text c="text.5" weight={400} size="xs" className="my-2">
    <Text span weight={700} align="center">
      {entityRelation === "accounts" ? <BuildingOffice2Icon className="inline-block mr-1 w-4 h-4" /> : <UserCircleIcon className="inline-block mr-1 w-4 h-4" />}
      {total}
      {entityRelation === "accounts" ? " Accounts" : " Users"}
    </Text>
    {" "}
    currently meet these conditions
  </Text>
);

export default ConditionsMeet;
