import { authorizedApiClient } from "../config";
import {
  CreateMetricRequest,
  CreateMetricResponse,
  ReadMetricResponse,
  UpdateMetricRequest,
  UpdateMetricResponse,
  DeleteMetricResponse,
  ReadAllMetricsResponse,
  ReadMetricRequest,
  ReadAllMetricsRequest,
} from "./metricEndpoint.interface";

export const createMetric = async ({ workspace_id, ...metric }: CreateMetricRequest) => authorizedApiClient.post<CreateMetricResponse>(`/${workspace_id}/metric`, metric);

export const readMetric = async ({ workspace_id, metric_id }: ReadMetricRequest) => authorizedApiClient.get<ReadMetricResponse>(`/${workspace_id}/metric/${metric_id}`);

export const updateMetric = async ({ workspace_id, metric_id, ...metric }: UpdateMetricRequest & ReadMetricRequest) => authorizedApiClient.put<UpdateMetricResponse>(`/${workspace_id}/metric/${metric_id}`, metric);

export const deleteMetric = async ({ workspace_id, metric_id }: ReadMetricRequest) => authorizedApiClient.delete<DeleteMetricResponse>(`/${workspace_id}/metric/${metric_id}`);

export const readAllMetrics = async ({ workspace_id, skip = 0, limit = 20 }: ReadAllMetricsRequest) => authorizedApiClient.get<ReadAllMetricsResponse>(`/${workspace_id}/metric/all?skip=${skip}&limit=${limit}`);
