import React, { useState } from "react";
import SignalControls from "./components/SignalControls";
import SignalsTable from "./components/SignalsTable";

const SignalsOverviewPage = () => {
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [selectedState, setSelectedState] = useState("all");
  return (
    <>
      <SignalControls
        selectedFilter={selectedFilter}
        selectedState={selectedState}
        setSelectedFilter={setSelectedFilter}
        setSelectedState={setSelectedState}
      />
      <SignalsTable
        selectedFilter={selectedFilter}
        selectedState={selectedState}
      />
    </>
  );
};

export default SignalsOverviewPage;
