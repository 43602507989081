// import { format } from 'date-fns';
import {
  endOfDay,
  endOfToday,
  format,
  formatDistance,
  startOfDay,
  startOfToday,
  subDays,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

/* eslint-disable no-bitwise */
const dateConverter = (date: any) => {
  const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
  return new Date(date - tzoffset)
    .toISOString()
    .slice(0, -1)
    .split("T")
    .join(" ");
};

export const dateFormatter = (date: any, type?: any) => {
  const localTime = zonedTimeToUtc(new Date(date.replace(" ", "T")), "UTC");
  if (type === "month") {
    return format(localTime, "d MMM");
  }
  if (type === "last") {
    return formatDistance(localTime, new Date(), {
      addSuffix: true,
    });
  }
  return format(localTime, "d MMM yyyy");
};

export function setTimeRange(range: any) {
  const dateFormat = "yyyy-MM-dd HH:mm:ss.SSSSSS";
  const now = new Date();

  let start; let
    end;
  switch (range) {
    case "yesterday":
      start = startOfDay(subDays(now, 1));
      end = endOfDay(subDays(now, 1));
      break;
    case "today":
      start = startOfToday();
      end = endOfToday();
      break;
    case "last_7_days":
      start = startOfDay(subDays(now, 6));
      end = endOfDay(now);
      break;
    case "last_30_days":
      start = startOfDay(subDays(now, 29));
      end = endOfDay(now);
      break;
    case "last_90_days":
      start = startOfDay(subDays(now, 89));
      end = endOfDay(now);
      break;
    case "last_180_days":
      start = startOfDay(subDays(now, 179));
      end = endOfDay(now);
      break;
    case "last_365_days":
      start = startOfDay(subDays(now, 364));
      end = endOfDay(now);
      break;
    case "all_time":
      start = new Date(0); // January 1, 1970
      end = endOfDay(now);
      break;
    default:
      throw new Error("Invalid range specified");
  }

  const startStr = format(start, dateFormat);
  const endStr = format(end, dateFormat);

  return {
    metrics_time_window_start: startStr,
    metrics_time_window_end: endStr,
  };
}

const timeWindowsOrder = [
  "today",
  "yesterday",
  "last_7_days",
  "last_30_days",
  "last_90_days",
  "last_180_days",
  "last_365_days",
  "all_time",
];

export const getLargerTimeWindow = (currentTimeWindow: string, key?: string) => {
  if (!key) return currentTimeWindow;
  const currentIndex = timeWindowsOrder.indexOf(currentTimeWindow);
  const keyIndex = timeWindowsOrder.indexOf(key);

  return keyIndex > currentIndex ? key : currentTimeWindow;
};

export default dateConverter;
