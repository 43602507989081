/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { subDays } from "date-fns";
import { ITableProps, kaReducer } from "ka-table";
import {
  hideLoading, loadData, showLoading, updateData, updatePageIndex, updatePagesCount,
} from "ka-table/actionCreators";
import { ActionType, SortingMode } from "ka-table/enums";
import { DispatchFunc } from "ka-table/types";
import {
  useEffect, useLayoutEffect, useMemo, useRef, useState,
} from "react";
import { useParams } from "react-router-dom";
import uuid from "react-uuid";
import useLocalStorageState from "use-local-storage-state";
import {
  ActionIcon, Collapse, Flex, Paper, Select, Text,
} from "@mantine/core";
import { AdjustmentsVerticalIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { getEntityActivity } from "../../../../services/userActivityEndpoint";
import KaTable, { IActionPayload } from "../../../../shared/table/KaTable";
import dateConverter from "../../../../utils/dateConverter";
import EmptyTablePlaceholder from "../../../../shared/table/EmptyTablePlaceHolder";
import useWorkspaceStore from "../../../../store/workspace/workspaceStore";

type Props = {
  userId: string,
  tableHeight: number,
  containerClass?: string,
  headingClass?: string,
  defaultOpen?: boolean,
};

const UserActivityTablsSm = ({
  userId, tableHeight, containerClass, headingClass,
  defaultOpen = true,
}: Props) => {
  const workspace = useWorkspaceStore((state) => state.workspace);
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const tableRef = useRef<any>(null);

  const [tableProps, changeTableProps] = useState<any>({
    columns: [
      {
        key: "event",
        dataType: "object",
        title: "Action Performed",
        isResizable: true,
        isSortable: false,
        colGroup: { style: { minWidth: 50 } },
      }, {
        key: "_timestamp",
        dataType: "string",
        title: "Time",
        isResizable: true,
        colGroup: { style: { minWidth: 50 } },
        isSortable: true,
      },
    ],
    columnResizing: true,
    rowKeyField: "randomId",
    height: tableHeight,
    singleAction: loadData(),
    sortingMode: SortingMode.SingleRemote,
    loading: {
      enabled: true,
      text: "Loading",
    },
  });

  const kaDispatch: DispatchFunc = (action: any) => {
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
  };

  useLayoutEffect(() => {
    if (tableHeight) {
      changeTableProps((prevState: ITableProps) => ({
        ...prevState,
        height: tableHeight,
      }));
    }
  }, [tableHeight]);

  const [noResults, setNoResults] = useState<boolean>(false);
  const params: any = useParams();
  const [total, setTotal] = useState<any>(0);

  const fetchData = async (
    page: number,
  ) => {
    kaDispatch(showLoading());
    try {
      const response = await getEntityActivity({
        external_id: userId,
        workspace_identifier: workspace?.id,
        entity_type: "contacts",
        start_time: dateConverter(subDays(new Date(), 90)),
        offset: 0,
        limit: 5,
      });
      if (response?.data) {
        const temp = response?.data?.results.map((item: any) => {
          const value = { ...item };
          if (value?.event) {
            value.event = {
              event: value?.event,
              category: value?.event_category,
              ...value.traits,
            };
            delete (value.category);
            delete (value?.traits);
          }
          value.randomId = uuid();
          return value;
        });
        setTotal(response.data.total_hits);
        kaDispatch(updateData(temp));
        if (response.data.total_hits < ((tableProps?.paging?.pageIndex || 0) * (tableProps?.paging?.pageSize || 1))) {
          kaDispatch(updatePageIndex(0));
        }
        kaDispatch(updatePagesCount(Math.ceil(response.data.total_hits / (tableProps?.paging?.pageSize ?? 1)) || 0));
        kaDispatch(hideLoading());
      } else {
        setTotal(0);
        setNoResults(true);
        kaDispatch(updatePageIndex(0));
        kaDispatch(updateData([]));
        kaDispatch(updatePagesCount(1));
        kaDispatch(hideLoading());
      }
    } catch (err) {
      setTotal(0);
      setNoResults(true);
      kaDispatch(updatePageIndex(0));
      kaDispatch(updateData([]));
      kaDispatch(updatePagesCount(1));
      kaDispatch(hideLoading());
    }
  };

  useEffect(() => {
    if (userId) {
      fetchData(tableProps?.paging?.pageIndex || 0);
    }
  }, [tableProps?.paging?.pageIndex, userId]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Paper shadow="md" radius="md" className={`border-2 border-gray-200 ${containerClass || ""}`}>
      <Flex align="center" p="sm" className="border-b-2" justify="space-between">
        <Text size={20} c="text.9" fw={600} className={headingClass}>
          Recent Activity
        </Text>
        <Flex align="center" gap={5}>
          <ActionIcon onClick={handleToggle} color="dark" size="xs">
            <ChevronUpIcon style={{ transform: isOpen ? "rotate(0)" : "rotate(180deg)" }} />
          </ActionIcon>
        </Flex>

      </Flex>
      <Collapse in={isOpen}>
        {noResults ? (<EmptyTablePlaceholder height={tableHeight} />)
          : (
            <KaTable
              ref={tableRef}
              tableProps={tableProps}
              changeTableProps={changeTableProps}
              kaDispatch={kaDispatch}
              rowKeyField="randomId"
              itemType="Actions"
              pageRange={1}
              totalData={total}
            />
          )}
      </Collapse>
    </Paper>
  );
};

export default UserActivityTablsSm;
