/* eslint-disable react-hooks/exhaustive-deps */
import { capitalize } from "lodash";
import {
  useEffect, useLayoutEffect,
  useRef, useState,
} from "react";
import { Outlet, useParams } from "react-router-dom";
import { useAccount, useAccountContacts } from "../../../hooks/QueryHooks";
import ProfileHeader from "./components/AccountProfileHeader";
import AccountRoutes from "./components/AccountRoutes";
import useWorkspaceStore from "../../../store/workspace/workspaceStore";
import AccountCrumbs from "./components/AccountCrumbs";

const AccountDetailsPage = () => {
  const { accountId }: any = useParams();
  const workspaceId = useWorkspaceStore((state) => state.workspace?.id);

  const accountData: any = useAccount({
    workspaceId,
    accountId,
    enabled: accountId !== "",
  });
  const contacts: any = useAccountContacts({
    workspaceId,
    accountId,
    enabled: accountId !== "",
  });

  const [height, setHeight] = useState<any>(0);

  useEffect(() => {
    if (accountData?.data?.name) {
      document.title = `${accountData?.data.name ? `${capitalize(accountData?.data.name)} | ` : ""}Account | Hyperengage`;
    }
  }, [accountData?.data?.name]);

  const divRef = useRef<any>();

  useLayoutEffect(() => {
    const updateHeight = () => {
      if (divRef.current) {
        setHeight(window.innerHeight - divRef.current.offsetHeight - divRef.current.offsetTop);
      }
    };

    // Initial height update
    updateHeight();

    // Add event listener for window resize
    window.addEventListener("resize", updateHeight);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <div className="min-h-full relative bg-gray-50">
      <div ref={divRef} className="absolute left-0 top-0 right-0 z-[2] bg-gray-50">
        <AccountCrumbs className="mt-2" accountName={accountData?.data?.name} />
        <ProfileHeader contacts={contacts?.data} key={accountId} account={accountData?.data} />
        <AccountRoutes />
      </div>
      <div
        style={{
          height,
          marginTop:
         divRef?.current ? divRef.current.offsetHeight + divRef.current.offsetTop : 0,
        }}
        className="overflow-y-auto"
      >
        {divRef?.current && <Outlet context={[divRef]} />}
      </div>
    </div>
  );
};

export default AccountDetailsPage;
