import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { queryClient, useGetEvents } from "./QueryHooks";
import { updateWorkspace } from "../services/workspace/workspaceEndpoints";
import useWorkspaceStore from "../store/workspace/workspaceStore";

const useCheckEvents = ({
  token,
  integrationName, workspaceId,
}: { token: string, integrationName: string, workspaceId: string }) => {
  const [stillComputing, setStillComputing] = useState(false);
  const workspace = useWorkspaceStore((state) => state.workspace);
  const updateWorkspaceMutation = useMutation({
    mutationFn: updateWorkspace,
  });
  return useGetEvents({
    token,
    integration_name: integrationName,
    workspaceId,
    refetchInterval: stillComputing ? undefined : 10000,
    enabled: !!token && !!workspaceId,
    onSuccess: (data: any) => {
      if (data?.group?.length && data?.track?.length && data?.identify?.length) {
        setStillComputing(true);
        if (!workspace?.setup_complete) {
          updateWorkspaceMutation.mutate({
            workspace_id: workspaceId,
            setup_complete: true,
          }, {
            onSuccess: (dat: any) => {
              queryClient.setQueryData(["workspace", workspaceId], (oldData: any) => ({
                ...oldData,
                data: dat?.data,
              }));
            },
          });
        }
      }
    },
  });
};

export default useCheckEvents;
