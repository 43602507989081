import { ArrowTrendingUpIcon, CalendarIcon } from "@heroicons/react/20/solid";
import { ReactComponent as Overview } from "../../../../assets/images/account-overview.svg";
import { ReactComponent as Traits } from "../../../../assets/images/account-trait.svg";
import { ReactComponent as Users } from "../../../../assets/images/account-user.svg";
import Tab from "../../../../shared/ui/links/Tab";

const AccountRoutes = () => (
  <nav className="flex border-y-2 bg-white gap-3 w-full px-3">
    <Tab LeadingIcon={Overview} to="overview">Overview</Tab>
    <Tab LeadingIcon={Traits} to="traits">Traits</Tab>
    <Tab LeadingIcon={Users} to="users">Users</Tab>
    <Tab LeadingIcon={ArrowTrendingUpIcon} to="trends">Trends</Tab>
    <Tab LeadingIcon={CalendarIcon} to="events">Events</Tab>
  </nav>
);

export default AccountRoutes;
