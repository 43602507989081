import React from "react";
import BasicInput from "./BasicInput";

type Props = {
  id: string,
  disabled?:boolean,
  label?: string, hint?:string,
  type: "text" | "number" | "email" | "date", placeHolder?: string,
  error?: boolean, className? : string,
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
  LeadingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>,
  TrailingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>,
  value: string | number, containerClass?: string, readOnly?: boolean,
  autocomplete?: boolean,
  autoFocus?: boolean,
};

const FormInput = ({
  onChange,
  autoFocus,
  autocomplete,
  value,
  id,
  disabled,
  type,
  label,
  hint,
  placeHolder = "",
  error = false,
  className, containerClass,
  LeadingIcon, TrailingIcon,
  readOnly = false,
} : Props) => {
  let style = "";
  if (className) {
    style = `${style} ${className}`;
  }

  return (
    <div className={containerClass}>
      {label && (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label htmlFor={id} className="block text-sm font-semibold mb-1 text-gray-500">
        {label}
      </label>
      )}
      <BasicInput
        autocomplete={autocomplete}
        autofocus={autoFocus}
        id={id}
        disabled={disabled}
        type={type}
        className={style}
        LeadingIcon={LeadingIcon || undefined}
        TrailingIcon={TrailingIcon || undefined}
        placeHolder={placeHolder}
        onChange={onChange}
        value={value}
        error={error}
        readOnly={readOnly}
      />
      {hint && (
      <p className={`mt-2 text-xs font-normal ${error ? "text-red-500" : "text-gray-500"}`}>
        {hint}
      </p>
      )}
    </div>
  );
};

export default FormInput;
