import { PaperAirplaneIcon } from "@heroicons/react/20/solid";
import {
  Stack, Text, Textarea,
} from "@mantine/core";
import { useState } from "react";
import MButton from "../ui/buttons/MButton";

type Props = {
  onSubmit?: (comment: string) => any,
};

const AddComment = ({ onSubmit = () => {} }: Props) => {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");

  const onCommentSubmit = async () => {
    setLoading(true);
    await onSubmit(comment);
    setLoading(false);
    setComment("");
  };

  const handleCommentChange = (event: any) => {
    setComment(event.target.value);
  };

  return (
    <Stack px="lg" pb={10} spacing={10} className="self-stretch justify-self-end">
      <Text className="text-sm mb-1 mt-4" fw={600} color="text.4">Add Comments</Text>
      <Textarea
        value={comment}
        w="full"
        onChange={handleCommentChange}
        placeholder="Type your comment here..."
        disabled={loading}
        classNames={{
          input: "border-gray-200 h-[98px] border-2 rounded-md",
        }}
      />
      <MButton loading={loading} className="self-end" leftIcon={<PaperAirplaneIcon className="w-5 h-5" />} onClick={() => { onCommentSubmit(); }} size="xs" variant="outline" color="purple">
        Submit
      </MButton>
    </Stack>
  );
};

export default AddComment;
