/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion, ActionIcon, CopyButton, Flex, Image, Text, Tooltip, rem,
} from "@mantine/core";
import { useTimeout } from "@mantine/hooks";
import {
  IconAffiliate, IconAnalyze,
  IconCheck,
  IconCirclePlus,
  IconCopy,
  IconRefresh, IconRefreshAlert,
} from "@tabler/icons-react";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import SegmentDest from "../../../../assets/images/connectSegment.png";
import SegmentSett from "../../../../assets/images/segmentSetting.png";
import { useAllIntegrations } from "../../../../hooks/QueryHooks";
import { getIntegrationEvents, updateIntegration } from "../../../../services/integrationEndpoint";
import { updateWorkspace } from "../../../../services/workspace/workspaceEndpoints";
import HeButton from "../../../../shared/ui/buttons/HeButton";
import MButton from "../../../../shared/ui/buttons/MButton";
import notify from "../../../../utils/notify";

type Props = {
  data: {
    apiKey: any,
    workspace: any,
  },
  steps: {
    script: string,
    title: string,
    script2?:string,
    desc: string,
  }[] | [],
};

const SegmentSteps = ({ data, steps }: Props) => {
  const [check, setCheck] = useState<boolean>(true);
  const timeout = useTimeout(() => setCheck(true), 5000);
  const [segmentData, setSegmentData] = useState<any>({});
  const { data: integrations }: any = useAllIntegrations({
    workspaceId: data.workspace?.id,
    enabled: !!data.workspace?.id && !!data?.apiKey?.id,
    select: (int: any) => int?.data?.filter((item: any) => item?.source_name === "segment_api")[0],
    onError: () => {
      notify({
        heading: "Failed to fetch Integrations",
        messageList: ["Please try again later or contact support to help you resolve this issue"],
        type: "failure",
      });
    },
  });

  const getDataMutation = useMutation({
    mutationFn: getIntegrationEvents,
    onSuccess: (dat: any) => {
      setSegmentData(dat?.data);
    },
  });

  const checkEvents: any = () => {
    getDataMutation.mutate({
      workspace_id: data?.workspace?.id,
      integration_name: "segment_api",
      token: data.apiKey?.id,
    });
  };

  const navigate = useNavigate();

  return (
    <nav aria-label="Progress" className="flex flex-col h-full">
      {steps?.length ? (
        <Accordion defaultValue="connect" bg="white" variant="contained">
          <Accordion.Item value="connect">
            <Accordion.Control icon={<IconAffiliate size={rem(20)} color="green" />}>
              Connect Hyperengage to your Segment Source
            </Accordion.Control>
            <Accordion.Panel>
              <div className="flex flex-col gap-3">
                <Text size="sm" c="text.5">
                  Installing Hyperengage via Segment allows you to send the user identities, custom user properties, group events and custom events configured in Segment. As of now our integration is in Private Beta and not listed in Segment Catalog. Please use the following link to install Usermaven as a destination in your Segment workspace.
                </Text>
                <Flex gap={20} align="center">
                  <SyntaxHighlighter language="shell" customStyle={{ fontSize: "16px", color: "darkBlue" }} style={docco}>
                    https://app.segment.com/goto-my-workspace/destinations/catalog/actions-hyperengage
                  </SyntaxHighlighter>
                  <CopyButton timeout={2000} value=" https://app.segment.com/goto-my-workspace/destinations/catalog/actions-hyperengage">
                    {({ copied, copy }) => (
                      <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="right">
                        <ActionIcon variant="outline" color={copied ? "teal" : "gray"} onClick={copy}>
                          {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Flex>
              </div>
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="print">
            <Accordion.Control icon={<IconAnalyze size={rem(20)} color="blue" />}>
              Select Data Source
            </Accordion.Control>
            <Accordion.Panel>
              <div className="flex flex-col gap-3">
                <Text size="sm" c="text.5">
                  On the next step, you will be asked to select the data source. Select the source from which you want to send the data to Hyperengage, and then provide destination name in the next step.
                </Text>
                <Image className="border-2 rounded-lg" radius="lg" mt={10} src={SegmentDest} alt="Segment Dest" />
              </div>
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="camera">
            <Accordion.Control icon={<IconCirclePlus size={rem(20)} color="orange" />}>
              Add API Key and Workspace Identifier
            </Accordion.Control>
            <Accordion.Panel>
              <div className="flex flex-col gap-3">
                <Text size="sm" c="text.5">
                  After that you need to add the API Key and Server Token. Once done, turn on the "Enable Destination" switch.
                </Text>
                <Flex gap={5} align="center">
                  <Text className="text-sm text-gray-900 font-bold">API Key:</Text>
                  <SyntaxHighlighter language="text" customStyle={{ fontSize: "13px" }} style={docco}>
                    {`${data.apiKey?.server_analytics_key}`}
                  </SyntaxHighlighter>
                  <CopyButton timeout={2000} value={data.apiKey?.server_analytics_key}>
                    {({ copied, copy }) => (
                      <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="right">
                        <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
                          {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Flex>
                <Flex gap={5} align="center">
                  <Text className="text-sm text-gray-900 font-bold">Workspace Identifier:</Text>
                  <SyntaxHighlighter language="text" customStyle={{ fontSize: "13px" }} style={docco}>
                    {`${data.workspace?.unique_identifier}`}
                  </SyntaxHighlighter>
                  <CopyButton timeout={2000} value={data.workspace?.unique_identifier}>
                    {({ copied, copy }) => (
                      <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="right">
                        <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
                          {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Flex>
                <Image className="border-2 rounded-lg" radius="lg" mt={10} src={SegmentSett} alt="SegmentSett" />
              </div>
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="refresh">
            <Accordion.Control icon={<IconRefreshAlert size={rem(20)} color="red" />}>
              Await Data Receival in Hyperengage
              <Text span c="red">*</Text>
            </Accordion.Control>
            <Accordion.Panel>
              <div className="flex flex-col gap-3">
                <Text size="sm" c="text.5">
                  Click refresh icon to check whether data has been received successfully in our backend.
                </Text>
                <Flex gap={20} wrap="wrap" align="stretch">
                  <div className="border-2 p-3 rounded-lg min-w-[400px]">
                    {segmentData?.group?.length ? (
                      <div>
                        <h3 className="text-gray-600 text-xs italic">Account data received successfully. Check preview below :</h3>
                        <SyntaxHighlighter showLineNumbers language="javascript" customStyle={{ fontSize: "13px" }} style={docco}>
                          {Object.entries(segmentData?.group[0]).map(([key, val]) => (`${key}: "${val}"`)).join("\n")}
                        </SyntaxHighlighter>
                      </div>
                    ) : (
                      <div className="flex flex-col gap-2 items-center justify-center bg-violet-50 p-3">
                        <span className="flex h-3 w-3 relative">
                          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75" />
                          <span className="relative inline-flex rounded-full h-3 w-3 bg-purple-500" />
                        </span>
                        <p className="text-gray-700 text-xs">Waiting to receive account events...</p>
                      </div>
                    )}
                  </div>
                  <div className="border-2 p-3 rounded-lg min-w-[400px]">
                    {segmentData?.identify?.length ? (
                      <div>
                        <h3 className="text-gray-600 text-xs italic">User data received successfully. Check preview below :</h3>
                        <SyntaxHighlighter showLineNumbers language="html" customStyle={{ fontSize: "13px" }} style={docco}>
                          {Object.entries(segmentData?.identify[0]).map(([key, val]) => (`${key}: "${val}"`)).join("\n")}
                        </SyntaxHighlighter>
                      </div>
                    ) : (
                      <div className="flex flex-col gap-2 items-center justify-center bg-violet-50 p-3">
                        <span className="flex h-3 w-3 relative">
                          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75" />
                          <span className="relative inline-flex rounded-full h-3 w-3 bg-purple-500" />
                        </span>
                        <p className="text-gray-700 text-xs">Waiting to receive user events...</p>
                      </div>
                    )}
                  </div>
                  <div className="border-2 p-3 rounded-lg min-w-[400px]">
                    {segmentData?.track?.length ? (
                      <>
                        <h3 className="text-gray-600 text-xs italic">Events data received successfully. Check preview below :</h3>
                        <SyntaxHighlighter showLineNumbers language="javascript" customStyle={{ fontSize: "13px" }} style={docco}>
                          {Object.entries(segmentData?.track[0]).map(([key, val]) => (`${key}: "${val}"`)).join("\n")}
                        </SyntaxHighlighter>
                      </>
                    ) : (
                      <div className="flex flex-col gap-2 items-center justify-center bg-violet-50 p-3">
                        <span className="flex h-3 w-3 relative">
                          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75" />
                          <span className="relative inline-flex rounded-full h-3 w-3 bg-purple-500" />
                        </span>
                        <p className="text-gray-700 text-xs">Waiting to receive track events...</p>
                      </div>
                    )}
                  </div>
                </Flex>
                <MButton
                  ml="auto"
                  onClick={() => {
                    checkEvents();
                    setCheck(false);
                    timeout.start();
                  }}
                  w={150}
                  leftIcon={<IconRefresh />}
                  variant="filled"
                  disabled={!check}
                  color="purple"
                >
                  Refresh

                </MButton>
              </div>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      ) : (<h2 className="w-full h-full flex items-center justify-center animate-pulse text-primary-900 text-[25px]">Coming Soon...</h2>)}
      <div className="flex justify-end py-5 border-t-2 mt-auto px-12 sm:px-10">
        <span className="inline-flex gap-3 items-center">
          <HeButton text="Back" size="sm" color="white" onClick={() => { navigate(-1); }} />
          <HeButton
            text="Continue"
            disabled={!segmentData?.group?.length
              || !segmentData?.identify?.length || !segmentData?.track?.length}
            size="sm"
            color="purple"
            onClick={async () => {
              if (data?.workspace?.id) {
                await updateIntegration({
                  created_by_user_id: integrations?.created_by_user_id,
                  source_name: "segment_api",
                  id: integrations.id,
                  workspace_id: data.workspace?.id,
                  setup_complete: true,
                  mapping_complete: true,
                  events_received: true,
                  is_active: true,
                });
                await updateWorkspace({
                  workspace_id: data.workspace?.id,
                  setup_complete: true,
                });
                // if (process.env.REACT_APP_HE_WORKSPACE_KEY && process.env.REACT_APP_HE_DATA_KEY) {
                //   window?.hyperengage(
                //     "track",
                //     "onboarding_completed",
                //     {
                //       properties: {
                //         completed_by_user_id: integrations?.created_by_user_id,
                //         source_name: integrations.source_name,
                //         integration_id: integrations.id,
                //         workspace_id: data.workspace?.id,
                //       },
                //     },
                //   );
                // }
                navigate(`/home/${data.workspace?.unique_identifier}/headquarters`, { replace: true, state: { setupComplete: true } });
              }
            }}
          />
        </span>
      </div>
    </nav>
  );
};

export default SegmentSteps;
