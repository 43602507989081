import {
  Image, Title, Text, Button, Center, Flex,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import image from "../../assets/images/404.svg";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <Center className="p-[10%]">
      <Flex
        align="center"
        justify="center"
        gap={80}
      >
        <Image src={image} className="" />
        <div>
          <Title className="">Something is not right...</Title>
          <Text c="dimmed" size="lg">
            Page you are trying to open does not exist. You may have mistyped the address, or the
            page has been moved to another URL. If you think this is an error contact support.
          </Text>
          <Button
            onClick={() => {
              navigate("/workspaces");
            }}
            variant="outline"
            size="md"
            mt="xl"
            className=""
          >
            Get back to home page
          </Button>
        </div>
      </Flex>
    </Center>
  );
};

export default NotFoundPage;
