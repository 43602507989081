/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
import { Transition } from "@headlessui/react";
import { LightBulbIcon } from "@heroicons/react/20/solid";
import React, {
  Fragment, useEffect, useLayoutEffect, useMemo, useRef, useState,
} from "react";
import uuid from "react-uuid";
import HeButton from "../buttons/HeButton";
import BasicSelect from "../inputs/select/BasicSelect";

type Props = {
  list?: any[];
  onDone?: any;
  selectSort: any;
  selectSortDir: any;
  selectedColumns?: any[];
  children?: React.ReactNode;
  isOpen: any;
  setIsOpen: any;
};

const SortDropdown = ({
  list = [],
  isOpen = false,
  onDone,
  selectedColumns = [],
  setIsOpen,
  selectSortDir,
  children,
  selectSort,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [sort, setSort] = useState(selectSort);
  const [sortDir, setSortDir] = useState(selectSortDir);

  useLayoutEffect(() => {
    setSort(selectSort);
    setSortDir(selectSortDir);
  }, [selectSort]);

  const displayTraits: any = useMemo(
    () => list
      ?.filter((item: any) => selectedColumns.includes(item.name))
      .map((item) => ({
        id: uuid(),
        name: item?.display_name,
        type: item?.data_type,
        entityType: item?.attribute_type,
        value: item?.name,
      })),
    [list, selectedColumns],
  );
  const sortOrder: any = useMemo(() => {
    if (sort?.entityType === "metric_traits") {
      return [
        {
          name: "Number(1 → 9)",
          order: "asc",
          id: 1,
          type: "changeval",
        },
        {
          name: "Number(9 → 1)",
          order: "desc",
          id: 2,
          type: "changeval",
        },
        {
          name: "Change %(1 → 100)",
          order: "asc",
          id: 3,
          type: "growthval",
        },
        {
          name: "Change %(100 → 1)",
          order: "desc",
          id: 4,
          type: "growthval",
        },
      ];
    }
    if (sort.type === "string") {
      return [
        {
          name: "Text(A → Z)",
          order: "asc",
          id: 1,
          type: "property",
        },
        {
          name: "Text(Z → A)",
          order: "desc",
          id: 2,
          type: "property",
        },
      ];
    }
    if (sort.type === "string") {
      return [
        {
          name: "Text(A → Z)",
          order: "asc",
          id: 1,
          type: "property",
        },
        {
          name: "Text(Z → A)",
          order: "desc",
          id: 2,
          type: "property",
        },
      ];
    }
    if (sort.type === "datetime") {
      return [
        {
          name: "Date(New → Old)",
          order: "desc",
          id: 1,
          type: "property",
        },
        {
          name: "Date(Old → New)",
          order: "asc",
          id: 2,
          type: "property",
        },
      ];
    }
    if (sort.type === "int" || sort.type === "float") {
      return [
        {
          name: "Number(1 → 9)",
          order: "asc",
          id: 1,
          type: "property",
        },
        {
          name: "Number(9 → 1)",
          order: "desc",
          id: 2,
          type: "property",
        },
      ];
    }
    return [
      {
        name: "Ascending",
        order: "asc",
        id: 1,
        type: "property",
      },
      {
        name: "Descending",
        order: "desc",
        id: 2,
        type: "property",
      },
    ];
  }, [sort]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  return (
    <div ref={ref} className="relative inline-block text-left">
      {children}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        show={isOpen}
      >
        <div
          id="heSortModal"
          className="absolute border flex-col gap-3 p-3 border-gray-200 flex min-h-0 right-0 z-40 mt-2 w-[435px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="rounded-md bg-yellow-50 px-1 py-3 w-full">
            <div className="flex">
              <div className="flex-shrink-0">
                <LightBulbIcon
                  className="h-4 w-4 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-2">
                <h3 className="text-xs font-medium text-yellow-800">
                  Not seeing what you're looking for? Try adding columns to the
                  table.
                </h3>
              </div>
            </div>
          </div>
          <div className="flex gap-1 items-center justify-between">
            <BasicSelect
              containerClass="w-full"
              label="Sort By"
              selectedItem={displayTraits.find(
                (item: any) => item.value === sort?.value,
              )}
              list={displayTraits}
              setSelectedItem={(setSort)}
            />
            <BasicSelect
              containerClass="w-[80%]"
              label="Sort Order"
              list={sortOrder}
              selectedItem={sortOrder.find(
                (item: any) => (
                  item.order === sortDir?.order) && (item.type === sortDir?.type),
              ) || sortOrder[0]}
              setSelectedItem={setSortDir}
            />
          </div>
          <div className="flex justify-end pt-2 mt-2 border-t-2">
            <div className="flex gap-2 items-center ml-auto">
              <button
                type="button"
                onClick={() => {
                  setIsOpen(false);
                }}
                className="flex rounded-lg hover:text-purple-800 items-center p-1 text-xs font-medium text-purple-500"
              >
                Close
              </button>
              <HeButton
                type="button"
                onClick={() => {
                  onDone(sort, sortDir);
                  setIsOpen(false);
                }}
                text="Done"
                size="xs"
                className="p-2 px-4"
                color="purple"
              />
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default SortDropdown;
