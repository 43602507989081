/* eslint-disable @typescript-eslint/no-unused-vars */
import { Paper, Text } from "@mantine/core";
import Nango from "@nangohq/frontend";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SlackIcon } from "../../../assets/images/slack.svg";
import { queryClient } from "../../../hooks/QueryHooks";
import IntegrationLayout from "../../../shared/integration/IntegrationLayout";
import MButton from "../../../shared/ui/buttons/MButton";
import useWorkspaceStore from "../../../store/workspace/workspaceStore";
import notify from "../../../utils/notify";

const SlackPage = () => {
  const workspace: any = useWorkspaceStore((state) => state.workspace);
  const navigate = useNavigate();
  const nango: any = new Nango({ publicKey: process.env.REACT_APP_NANGO_KEY! });

  const slackClick = () => {
    nango.auth("slack", workspace.id, { user_scope: ["channels:read", "channels:write"] }).then(() => {
      notify({
        heading: "Integrated Slack",
        type: "success",
        messageList: ["You have successfully integrated Slack with Hyperengage"],
      });
      queryClient.invalidateQueries(["slack-integration", workspace?.id]);
      navigate(-1);
    }).catch(() => {
      notify({
        heading: "Integration Failed",
        type: "success",
        messageList: ["Failed to integrate Slack with Hyperengage, please try again later"],
      });
    });
  };

  return (
    <IntegrationLayout
      title="Slack"
      description="Connect Hyperengage to your Slack workspace to get real-time updates where your team works."
      workingDesc="The Hyperengage App for Slack helps you to stay up-to-date with your prospects and customers by delivering notifications about intent happening on your site in real-time. Subscribe to the account and contact lists that matter most and keep your whole team in sync."
      IntegrationIcon={SlackIcon}
    >
      <Paper p="md" withBorder className="flex flex-col bg-gray-50 gap-0.5">
        <Text weight={500}>Connect your Slack Account</Text>
        <Text c="text.5">Please connect your Slack account inorder to get started.</Text>
        <MButton color="purple" mt={10} onClick={() => { slackClick(); }}>
          Log in with Slack
        </MButton>
      </Paper>
    </IntegrationLayout>
  );
};

export default SlackPage;
