/* eslint-disable jsx-a11y/no-autofocus */
import React from "react";

type Props = {
  id: string, type: "text" | "number" | "email" | "date", placeHolder?: string, error?: boolean, className? : string
  LeadingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>,
  TrailingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>,
  onChange?: React.ChangeEventHandler<HTMLInputElement>, containerClass?: string,
  value: string | number, readOnly?: boolean,
  disabled?: boolean, autocomplete?: boolean,
  autofocus?: boolean,
};

const BasicInput = ({
  id,
  autofocus = false,
  autocomplete = true,
  disabled = false,
  onChange,
  value,
  type,
  placeHolder = "",
  error = false,
  className,
  containerClass,
  LeadingIcon,
  TrailingIcon,
  readOnly = false,
} : Props) => {
  let style: string = !error
    ? `block w-full rounded-md border-gray-300 ${readOnly ? "text-gray-600 bg-gray-100" : "text-gray-900"} shadow-sm hover:border-secondary-400 focus:ring-primary-400 focus:border-primary-400 focus:shadow-textBox sm:text-sm disabled:bg-gray-200 disabled:placeholder:text-gray-200 disabled:text-gray-200`
    : "block w-full rounded-md border-red-400 text-red-900 hover:border-red-600 hover:ring-red-600 focus:border-red-600 focus:shadow-textBox focus:shadow-red-100 focus:ring-red-400 sm:text-sm disabled:bg-gray-200";

  let container = "relative rounded-md shadow-sm w-full";

  if (LeadingIcon) {
    style = `${style} pl-10`;
  }

  if (TrailingIcon) {
    style = `${style} pr-10`;
  }

  if (className) {
    style = `${style} ${className}`;
  }

  if (containerClass) {
    container = `${container} ${containerClass}`;
  }

  return (
    <div className={container}>
      {LeadingIcon && (
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <LeadingIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
      )}
      <input
        autoFocus={autofocus}
        disabled={disabled}
        autoComplete={autocomplete ? "on" : "off"}
        id={id}
        name={id}
        value={value}
        onChange={onChange}
        type={type}
        className={style}
        placeholder={placeHolder}
        readOnly={readOnly}
      />
      {TrailingIcon && (
      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
        <TrailingIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      )}
    </div>
  );
};

export default BasicInput;
