import { useMemo } from "react";
import { Avatar } from "@mantine/core";
import { BuildingOfficeIcon } from "@heroicons/react/20/solid";
import avatarGenerator from "../../../utils/avatarGenerator";

type Props = {
  size?: "sm" | "md" | "lg" | "xl" | "xs",
  rounded?: boolean,
  img?: string,
  name?: string,
  desc?: string,
  width?:number,
  height?: number,
};

const ProfileImg = ({
  size = "md",
  width,
  height,
  rounded = false,
  img,
  desc,
  name,
} : Props) => {
  const colors = useMemo(() => ["dark", "red", "pink", "grape", "violet", "indigo", "cyan", "teal", "yellow", "orange"], []);
  const source = avatarGenerator({ img, desc: (desc || name), size: 80 });
  return (
    <div>
      {(name || desc) ? (
        <Avatar
          src={source}
          color={colors[(name || desc)!.charCodeAt(0) % 10]}
          size={size}
          w={width}
          h={height}
          radius={rounded ? "xl" : "md"}
        >
          {(name || desc) ? (name || desc)!.split(" ").map((item) => item[0]).join("").toUpperCase() : "?"}
        </Avatar>
      )
        : (<BuildingOfficeIcon />)}
    </div>
  );
};

export default ProfileImg;
