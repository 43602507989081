/* eslint-disable @typescript-eslint/no-floating-promises */
import { format } from "date-fns";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAccount, useTraitsAndMetrics } from "../../../../hooks/QueryHooks";
import TraitSource from "../../../../shared/trait/TraitSource";
import useWorkspaceStore from "../../../../store/workspace/workspaceStore";

const AccountTraits = () => {
  const { accountId }: any = useParams();
  const workspaceId = useWorkspaceStore((state) => state.workspace?.id);
  const { data: account }: any = useAccount({
    workspaceId,
    enabled: !!workspaceId,
    accountId,
  });
  const { data: traits }: any = useTraitsAndMetrics({
    workspaceId,
    enabled: !!workspaceId,
  });
  const shownTraits: any = useMemo(() => Object.entries(account || {})
    .reduce((obj: any, [trKey, trValue]: any) => {
      const selectedTrait = traits?.accountTraits?.obj[`accounts.${trKey}`];
      if (!selectedTrait?.exclude_display) {
        let data = {};
        if (selectedTrait?.data_type === "datetime") {
          data = {
            ...selectedTrait,
            val: trValue ? format(new Date(trValue), "do MMMM yyyy") : undefined,
          };
        } else {
          data = {
            ...selectedTrait,
            val: trValue,
          };
        }
        if (selectedTrait?.source === "native") {
          obj.native[trKey] = data;
        } else if (selectedTrait?.source === "segment") {
          obj.segment[trKey] = data;
        }
      }
      return obj;
    }, { segment: {}, native: {} }), [traits?.accountTraits, account]);

  return (
    <div className="px-12 py-8 relative flex flex-col gap-8 overflow-auto">
      <div className="flex mt-2 justify-between items-center">
        <h2 className="text-gray-900 text-[20px] font-semibold">Traits</h2>
      </div>
      {Object.keys(shownTraits?.native).length ? shownTraits?.native && <TraitSource source="hyperengage" data={shownTraits.native} /> : undefined}
      {Object.keys(shownTraits?.segment).length ? <TraitSource source="segment" data={shownTraits.segment} /> : undefined}
    </div>
  );
};

export default AccountTraits;
