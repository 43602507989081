import React from "react";

type Props = {
  className?: string, size?: string,
  rounded?: boolean,
  Src: React.ComponentType<React.SVGProps<SVGSVGElement>>,
  color?: "purple" | "gray" | "red",
};

const MediaIcon = ({
  size = "md",
  rounded = false,
  className,
  color = "purple",
  Src,
} : Props) => {
  let iconStyle = "p-[5px]";
  let iconSize = "";

  switch (color) {
    case ("purple"):
      iconStyle += " text-purple-500 fill-purple-500 bg-purple-100 hover:text-purple-700 focus:text-purple-500";
      break;
    case ("gray"):
      iconStyle += " text-gray-500 fill-gray-500 bg-gray-100 hover:text-gray-700 focus:text-gray-500";
      break;
    case ("red"):
      iconStyle += " text-red-500 fill-red-500 bg-red-100 hover:text-red-700 focus:text-red-500";
      break;
    default:
  }

  if (size === "sm") {
    iconSize = " h-6 w-6";
  } else if (size === "md") {
    iconSize = " h-8 w-8";
  } else if (size === "lg") {
    iconSize = " h-10 w-10";
  }

  iconStyle += rounded ? " rounded-full" : " rounded-md";

  if (className) {
    iconStyle = `${iconStyle} ${className}`;
  }

  return (
    <div className={iconStyle}>
      <Src className={iconSize} />
    </div>
  );
};

export default MediaIcon;
