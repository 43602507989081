import HeButton from "../../../../shared/ui/buttons/HeButton";
import PurpleLink from "../../../../shared/ui/links/PurpleLink";

type Props = {
  nextHandler: (event: React.MouseEvent<HTMLButtonElement>) => any,
  backHandler: (event: React.MouseEvent<HTMLButtonElement>) => any,
  disabledProgress?: boolean,
  helpTo?: string,
  visible?: boolean,
  backButtonVisible?: boolean,
  nextButtonVisible?: boolean,
};

const StepFooter = ({
  disabledProgress = false,
  nextHandler, backHandler,
  visible = true,
  helpTo = "#",
  backButtonVisible = true,
  nextButtonVisible = true,
}: Props) => (
  <div className="flex justify-between items-center">
    {visible ? (
      <>
        <p className="text-gray-500 text-sm">
          Not Working?
          {" "}
          <PurpleLink to={helpTo}>Open docs</PurpleLink>
        </p>
        <span className="inline-flex gap-3 items-center">
          {backButtonVisible && <HeButton text="Back" size="sm" color="white" onClick={backHandler} />}
          {nextButtonVisible && <HeButton text="Next" disabled={disabledProgress} size="sm" color="purple" onClick={nextHandler} />}
        </span>
      </>
    ) : null}
  </div>
);

export default StepFooter;
