/* eslint-disable import/prefer-default-export */
import { authorizedApiClient } from "./config";

type GlobalSearch = {
  workspace_identifier: string,
  search_query: string,
  limit?: number,
  offset?: number,
};

export const getGlobalSearch = async ({
  workspace_identifier,
  search_query,
  limit = 100,
  offset = 0,
}: GlobalSearch) => authorizedApiClient.post(`/${workspace_identifier}/utils/global_search`, {
  search_query,
  limit,
  offset,
});
