/* eslint-disable react/jsx-props-no-spreading */
import {
  UnstyledButton,
  UnstyledButtonProps,
  Group,
  Avatar,
  Text,
  createStyles,
} from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  user: {
    display: "block",
    width: "100%",
    padding: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0],
    },
  },
}));

interface UserButtonProps extends UnstyledButtonProps {
  image?: string;
  name?: string;
  email?: string;
  icon?: React.ReactNode;
  minimized?: boolean;
}

const UserButton = ({
  image, name, email, icon, minimized = false, ...others
}: UserButtonProps) => {
  const { classes } = useStyles();

  return (
    <UnstyledButton className={classes.user} {...others}>
      <Group noWrap spacing={8}>
        <Avatar color="purple" src={image} radius="md">
          {name?.charAt(0)}
        </Avatar>

        <div className={`${minimized && "hidden"}`} style={{ flex: 1 }}>
          <Text size="sm" truncate weight={500}>
            {name}
          </Text>

          <Text color="dimmed" truncate size="xs">
            {email}
          </Text>
        </div>

        {!minimized ? (icon || <IconChevronRight size="0.9rem" stroke={1.5} />) : ""}
      </Group>
    </UnstyledButton>
  );
};

export default UserButton;
