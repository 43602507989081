import React from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";

type Props = {
  color?: string, text: string, size?: string, rounded?: boolean,
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
  Icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>,
  LeadingIcon?: any,
  className?: string,
};
const Pill = ({
  color = "gray",
  text,
  size = "md",
  rounded = false,
  className = "",
  onClick,
  LeadingIcon,
  Icon = XMarkIcon,
} : Props) => {
  let pillStyle = `whitespace-nowrap inline-flex w-min items-center  ${rounded ? "rounded-full" : "rounded-md"} font-medium `;
  let colorStyle = "";

  switch (color) {
    case ("red"):
      colorStyle = "text-red-700 bg-red-100";
      break;
    case ("green"):
      colorStyle = "text-emerald-700 bg-emerald-100";
      break;
    case ("purple"):
      colorStyle = "text-purple-700 bg-purple-100";
      break;
    case ("orange"):
      colorStyle = "text-orange-700 bg-orange-100";
      break;
    case ("gray"):
      colorStyle = "text-gray-700 bg-gray-100";
      break;

    default:
  }

  if (size === "sm") {
    pillStyle += " py-0.5 px-2 text-xs";
  } else if (size === "md") {
    pillStyle += " py-0.5 px-2 text-xs";
  } else if (size === "lg") {
    pillStyle += " py-0.5 px-2 text-lg";
  }

  pillStyle += ` ${colorStyle}`;

  return (
    <div className={pillStyle + className}>
      {LeadingIcon && <LeadingIcon className={`${size === "sm" ? "h-3 w-3" : "h-4 w-4"} text-${color}-500`} />}
      {text}
      {onClick
      && (
      <Icon onClick={(e: any) => { onClick(e); }} className={`hover:cursor-pointer h-4 w-4 text-${color}-500 ml-0.5 -mr-1 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full focus:outline-none`} />
      )}
    </div>
  );
};

export default Pill;
