import React from "react";
import { Link } from "react-router-dom";

type Props = {
  to: string,
  children: React.ReactNode,
  className? : string, size?: string,
  LeadingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>,
  TrailingIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>,
};

const Breadcrumb = ({
  to,
  children,
  size = "md",
  className,
  LeadingIcon,
  TrailingIcon,
} : Props) => {
  let linkStyle = "flex capitalize items-center text-gray-700 hover:text-gray-900 focus:text-gray-500";
  let leadingIconStyle = "";
  let trailingIconStyle = "";
  if (size === "sm") {
    linkStyle += " py-2 text-xs";
    leadingIconStyle = "-ml-1 mr-2 h-5 w-5";
    trailingIconStyle = "ml-2 -mr-1 h-5 w-5";
  } else if (size === "md") {
    linkStyle += " py-2 text-sm";
    leadingIconStyle = "-ml-1 mr-3 h-5 w-5";
    trailingIconStyle = "ml-3 -mr-1 h-5 w-5";
  } else if (size === "lg") {
    linkStyle += " px-6 py-3 text-base";
    leadingIconStyle = "-ml-1 mr-3 h-5 w-5";
    trailingIconStyle = "ml-3 -mr-1 h-5 w-5";
  }

  if (className) {
    linkStyle = `${linkStyle} ${className}`;
  }

  return (
    <Link
      to={to}
      replace
      className={linkStyle}
    >
      {LeadingIcon && (
      <LeadingIcon className={`${leadingIconStyle} ${children ? "" : "ml-0 mr-0"}`} aria-hidden="true" />
      )}
      {children}
      {TrailingIcon && (
      <TrailingIcon className={`${trailingIconStyle}  ${children ? "" : "ml-0 mr-0"}`} aria-hidden="true" />
      )}
    </Link>
  );
};

export default Breadcrumb;
