import React from "react";
import { Paper, Divider } from "@mantine/core";
import AccountProfileAvatar from "./AccountProfileAvatar";
import AccountHeaderMetrics from "./AccountHeaderMetrics";
import { dateFormat } from "../../../../utils/tableUtils";

const LeftAccountBox = ({ account }: any) => (
  <Paper
    shadow="xs"
    radius="sm"
    className="flex-shrink-0"
    style={{ width: "300px", height: "215px" }}
  >
    <div className="flex flex-col h-full">
      <div>
        <AccountProfileAvatar
          name={account?.name}
          img={account?.avatar_url}
          id={account?.external_id}
          joined={account?.created_at && dateFormat("normal", account.created_at)}
        />
      </div>
      <Divider className="border-gray-200" />
      <div>
        <AccountHeaderMetrics
          totalUsers={account?.total_user_count}
          lastSeen={account?.last_seen && dateFormat("last", account.last_seen)}
        />
      </div>
    </div>
  </Paper>
);

export default LeftAccountBox;
