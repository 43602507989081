import { SegmentedControl } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SignalTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState<string>("overview");

  useEffect(() => {
    if (!location.pathname.includes("log")) {
      setTab("overview");
    } else { setTab("logs"); }
  }, [location.pathname, tab]);
  return (
    <SegmentedControl
      value={tab}
      onChange={(value: any) => {
        setTab(value);
        navigate(value);
      }}
      data={[
        { label: "Overview", value: "overview" },
        { label: "Logs", value: "logs" },
      ]}
    />
  );
};

export default SignalTabs;
