/* eslint-disable import/prefer-default-export */
import { subDays } from "date-fns";
import qs from "qs";
import { authorizedApiClient } from "./config";
import dateConverter from "../utils/dateConverter";

type GetTraits = {
  workspace_identifier: string;
};

export const getAccountTraits = async ({ workspace_identifier }: GetTraits) => authorizedApiClient.get(`/companies/${workspace_identifier}/traits`);

type GetAccounts = {
  entity_type: string;
  workspace_identifier: string;
  include_count?: boolean;
  page?: number;
  per_page?: number;
  selected_fields: string[];
  filter?: any;
  sort_by: string;
  sort_direction: string;
};

export const getAccountsAgainstQuery = async ({
  entity_type,
  workspace_identifier,
  include_count = true,
  page = 1,
  per_page = 5,
  selected_fields,
  filter = {},
  sort_by,
  sort_direction,
}: GetAccounts) => authorizedApiClient.post(`/workspace/${workspace_identifier}/trend_search`, {
  entity_type,
  include_count,
  page,
  per_page,
  selected_fields,
  filter,
  sort_by,
  sort_direction,
});

type GetAccountById = {
  workspace_identifier: string;
  external_id: string;
};

export const getAccountById = async ({
  workspace_identifier,
  external_id,
}: GetAccountById) => authorizedApiClient.get(`/${workspace_identifier}/company/${external_id}`);

type EventList = {
  workspace_identifier: string,
  search_query?: string,
  from_date?: string,
  to_date?: string,
};

export const getEventList = async ({
  workspace_identifier,
  search_query,
  from_date = dateConverter(new Date("1-1-1970")),
  to_date = dateConverter(new Date()),
}: EventList) => authorizedApiClient.get(`/global_utils/${workspace_identifier}/event_list`, {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    withCredentials: true,
  },
  params: {
    from_date,
    to_date,
    search_query,
  },
});

type CompanyEventList = {
  workspace_identifier: string,
  external_id: string,
  search_query?: string,
  from_date?: string,
  to_date?: string,
};

export const getCompanyEventList = async ({
  workspace_identifier,
  search_query,
  external_id,
  from_date = dateConverter(new Date("1-1-1970")),
  to_date = dateConverter(new Date()),
}: CompanyEventList) => authorizedApiClient.get(`/${workspace_identifier}/company/events/events_list/${external_id}`, {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    withCredentials: true,
  },
  params: {
    from_date,
    to_date,
    search_query,
  },
});

type CompanyEventActivity = {
  workspace_identifier: string,
  external_id: string,
  event_name: string,
  from_date?: string,
  to_date?: string,
  offset?: number,
  limit?: number
};

export const getCompanyEventActivity = async ({
  workspace_identifier,
  event_name,
  external_id,
  from_date = dateConverter(new Date("1-1-1970")),
  to_date = dateConverter(new Date()),
  offset = 0,
  limit = 20,
}: CompanyEventActivity) => authorizedApiClient.get(`/${workspace_identifier}/company/events/events_activity/${external_id}`, {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    withCredentials: true,
  },
  params: {
    from_date,
    to_date,
    event_name,
    offset,
    limit,
  },
});

type CompanyTrends = {
  workspace_identifier: string,
  external_id: string,
  event_names: string[],
  from_date?: string,
  to_date?: string,
  bucket_size: "day" | "week" | "month" | "year"
};

export const getCompanyTrends = async ({
  workspace_identifier,
  event_names,
  external_id,
  from_date = dateConverter(subDays(new Date(), 30)),
  to_date = dateConverter(new Date()),
  bucket_size,
}: CompanyTrends) => authorizedApiClient.get(`/${workspace_identifier}/company/events/trend_search/${external_id}`, {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    withCredentials: true,
  },
  params: {
    event_names,
    from_date,
    to_date,
    bucket_size,
  },
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
});

type CompanyMetrics = {
  workspace_identifier: string,
  external_id: string,
  event_name: string[],
  from_date?: string,
  to_date?: string,
  bucket_size: "day" | "week" | "month" | "year"
};

export const getCompanyMetrics = async ({
  workspace_identifier,
  event_name,
  external_id,
  from_date = dateConverter(subDays(new Date(), 30)),
  to_date = dateConverter(new Date()),
  bucket_size,
}: CompanyMetrics) => authorizedApiClient.get(`/${workspace_identifier}/company/events/metrics_graph/${external_id}`, {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    withCredentials: true,
  },
  params: {
    event_name,
    from_date,
    to_date,
    bucket_size,
  },
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
});

type TopN = {
  workspace_identifier: string,
  account_id: string,
  table_name?: string[],
  event_type?: string,
  retention_period?: "week" | "month" | "year"
};

export const getTopNFeatures = async ({
  workspace_identifier,
  account_id,
  table_name,
  event_type = "track",
  retention_period = "week",
}: TopN) => authorizedApiClient.get(`/${workspace_identifier}/company/metrics/top_n/${account_id}`, {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    withCredentials: true,
  },
  params: {
    table_name,
    event_type,
    retention_period,
  },
});

type AssignKeyRole = {
  assign_to_user_id: string;
  key_role_id: string;
  account_id: string;
  workspace_identifier: string,
};

export const assignKeyRole = async ({
  assign_to_user_id,
  key_role_id,
  account_id,
  workspace_identifier,
}: AssignKeyRole) => authorizedApiClient.post(`/${workspace_identifier}/assign_key_roles`, {
  assign_to_user_id,
  key_role_id,
  account_id,
});

type RemoveKeyRole = {
  key_role_id: string;
  account_id: string;
  workspace_identifier: string,
};

export const removeKeyRole = async ({
  key_role_id,
  account_id,
  workspace_identifier,
}: RemoveKeyRole) => authorizedApiClient.delete(`/${workspace_identifier}/assign_key_roles`, {
  data: {
    key_role_id,
    account_id,
  },
});
