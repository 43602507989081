import { Flex } from "@mantine/core";
import React from "react";
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
import { IconTrash } from "@tabler/icons-react";
import { ICellTextProps } from "ka-table/props";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { deleteAutomation } from "../../services/automation/automationEndpoints";
import notify from "../../utils/notify";
import { queryClient } from "../../hooks/QueryHooks";
import useWorkspaceStore from "../../store/workspace/workspaceStore";

/**
 * Custom cell component.
 */
const DeleteSignalCell: React.FC<ICellTextProps> = React.memo(
  ({
    column, dispatch, rowKeyValue, ...props
  }) => {
    const workspace = useWorkspaceStore((state) => state.workspace);

    const deleteAutomationMutation = useMutation({
      mutationFn: deleteAutomation,
      onSuccess: () => {
        queryClient.setQueryData(["automations"], (old: any) => {
          const newAutomations = old.filter(
            (automation: any) => automation.id !== props.rowData.id,
          );
          return {
            ...old,
            data: newAutomations,
          };
        });
        notify({
          messageList: ["Automation deleted successfully"],
          type: "success",
        });
      },
    });

    const handleDelete = () => {
      deleteAutomationMutation.mutate({
        automation_id: props.rowData.id,
        workspace_id: workspace.id,
      });
    };
    return (
      <Flex
        gap={20}
        align="center"
        onClick={() => {
          handleDelete();
        }}
        className="group w-fit duration-100 cursor-pointer ease-linear hover:bg-gray-200 -m-2 p-2 rounded-md"
      >
        <IconTrash className="h-[17px] w-[17px] text-red-500 group-hover:block duration-100 ease-linear hover:text-red-600" />
      </Flex>
    );
  },
);
export default DeleteSignalCell;
