/* eslint-disable import/prefer-default-export */
import { authorizedApiClient } from "./config";

type GetContacts = {
  entity_type: string;
  workspace_identifier: string;
  include_count?: boolean;
  page?: number;
  per_page?: number;
  selected_fields: string[];
  filter?: any;
  sort_by: string;
  sort_direction: string;
};

export const getContactsAgainstQuery = async ({
  entity_type,
  workspace_identifier,
  include_count = true,
  page = 1,
  per_page = 5,
  selected_fields,
  filter = {},
  sort_by,
  sort_direction,
}: GetContacts) => authorizedApiClient.post(`/workspace/${workspace_identifier}/trend_search`, {
  entity_type,
  include_count,
  page,
  per_page,
  selected_fields,
  filter,
  sort_by,
  sort_direction,
});

type GetContactById = {
  workspace_identifier: string;
  external_id: string;
};

export const getContactById = async ({
  workspace_identifier,
  external_id,
}: GetContactById) => authorizedApiClient.get(`/${workspace_identifier}/user_contacts/${external_id}`);

type GetContactsByAccountId = {
  workspace_identifier: string;
  company_id: string;
};

export const getContactsByAccountId = async ({
  workspace_identifier,
  company_id,
}: GetContactsByAccountId) => authorizedApiClient.get(`/${workspace_identifier}/user_contacts/all/${company_id}`);
