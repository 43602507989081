/* eslint-disable react/jsx-props-no-spreading */
import {
  Flex,
  Text,
} from "@mantine/core";
import { forwardRef } from "react";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  Icon?: any;
  label: string;
}

const CustomSelectInput = forwardRef<HTMLDivElement, ItemProps>(
  ({
    Icon, label, ...others
  }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Flex gap={8} align="center" className="overflow-hidden">
        <Icon className="w-4 h-4" color="main.6" radius="xl" />
        <div>
          <Text truncate size="sm">{label}</Text>
        </div>
      </Flex>
    </div>
  ),
);

export default CustomSelectInput;
