import React from "react";
import {
  Flex, Stack, Text, Tooltip,
} from "@mantine/core";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import Dash from "../../../../shared/placeholder/Dash";

type Props = {
  lastSeen?: string,
  totalUsers?: number,
};

const AccountHeaderMetrics = ({ lastSeen, totalUsers }: Props) => (
  <Flex gap={40} py="sm" px="lg">
    <Stack spacing={4}>
      <div className="flex items-center">
        <Text c="text.4" size="sm" className="mr-2 font-semibold">Last Seen</Text>
        <Tooltip withinPortal label="The last time we received an event for this Company."><InformationCircleIcon className="w-4 h-4 cursor-help hover:text-gray-500 text-gray-400" /></Tooltip>
      </div>
      {lastSeen ? (<Text c="text.6" size="sm" className="font-semibold">{lastSeen}</Text>) : <Dash className="mt-2" />}
    </Stack>
    <Stack spacing={4}>
      <div className="flex items-center">
        <Text c="text.4" size="sm" className="mr-2 font-semibold">Total users</Text>
      </div>
      {totalUsers ? (<Text c="text.6" size="sm" className="font-semibold">{totalUsers}</Text>) : <Dash className="mt-2" />}
    </Stack>
  </Flex>
);

export default AccountHeaderMetrics;
