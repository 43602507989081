/* eslint-disable react-hooks/exhaustive-deps */
import {
  CheckIcon,
  ChevronDownIcon,
  CubeTransparentIcon,
  EyeIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { NewspaperIcon, PresentationChartBarIcon } from "@heroicons/react/24/outline";
import {
  Menu,
  Paper, Text,
} from "@mantine/core";
import {
  useEffect, useMemo, useState,
} from "react";
import uuid from "react-uuid";
import { ReactComponent as He } from "../../../assets/images/heSm.svg";
import { ReactComponent as Segment } from "../../../assets/images/segmentAvatar.svg";
import MButton from "../buttons/MButton";
import AttributeList from "./AttributeList";
import SourceList from "./SourceList";

type Props = {
  list?: any[];
  selected: any;
  setSelected: any;
  placeholder?: string;
  type?: string;
  metricsList?: any[];
  className?: string;
  position?: "bottom" | "left" | "right" | "top" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start";
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const listOfSources = [
  {
    name: "all",
    label: "All Sources",
    icon: CubeTransparentIcon,
  },
  {
    name: "native",
    label: "Hyperengage",
    icon: He,
  },
  {
    name: "segment",
    label: "Segment",
    icon: Segment,
  },
];

const listOfAttributes = [
  {
    name: "all",
    label: "All",
    icon: CubeTransparentIcon,
  },
  {
    name: "traits",
    label: "Traits",
    icon: NewspaperIcon,
  },
  {
    name: "metrics",
    label: "Metrics",
    icon: PresentationChartBarIcon,
  },
];

const SingleSelectDropdown = ({
  list = [],
  metricsList = [],
  type = "Accounts",
  selected,
  setSelected,
  position = "left-start",
  className = "",
  placeholder = "Select Property",
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const [selectedSource, setSelectedSource] = useState(listOfSources[0].name);
  const [selectedAttribute, setSelectedAttribute] = useState<any>("all");
  const [queriedData, setQueriedData] = useState<any[]>([]);

  const displayTraits = useMemo(() => (selectedSource === "all"
    ? list : list?.filter((item: any) => item.source === selectedSource)
  ), [selectedSource, list?.length]);

  const displayMetrics = useMemo(() => (selectedSource === "all"
    ? metricsList : metricsList?.filter(
      (item: any) => item.source === selectedSource,
    )), [selectedSource, metricsList?.length]);

  useEffect(() => {
    let data = [];
    if (selectedAttribute === "metrics") {
      data = displayMetrics.filter(
        (item) => item?.display_name?.toLowerCase().includes(query.toLowerCase()),
      );
    }
    if (selectedAttribute === "traits") {
      data = displayTraits.filter(
        (item) => item?.display_name?.toLowerCase().includes(query.toLowerCase()),
      );
    }
    if (selectedAttribute === "all") {
      data = [...displayTraits, ...displayMetrics].filter(
        (item) => item?.display_name?.toLowerCase().includes(query.toLowerCase()),
      );
    }
    setQueriedData(data);
  }, [query, displayMetrics?.length, displayTraits?.length, selectedAttribute]);

  return (
    <Menu
      withinPortal
      closeOnClickOutside
      opened={isOpen}
      onChange={setIsOpen}
      position={position}
    >
      <Menu.Target>
        <div>
          <MButton
            className={
            `${selected?.display_name
            && "text-gray-900 hover:bg-white hover:text-gray-900 hover:border-gray-400"} 
             ${className}
            `
          }
            color="white"
            radius={7}
            size="sm"
            leftIcon={<EyeIcon className="w-5 h-5" />}
            rightIcon={<ChevronDownIcon className="w-5 h-5" />}
            type="button"
            onClick={() => {
              setIsOpen((state) => !state);
            }}
          >
            {selected?.display_name || placeholder}
          </MButton>
        </div>
      </Menu.Target>
      <Menu.Dropdown className="z-6 p-0 origin-top-right shadow-sm">
        <div
          className="h-[350px] w-[600px] overflow-visible border-gray-200 flex rounded-md bg-white "
        >
          <SourceList
            data={listOfSources}
            activeTab={selectedSource}
            handleTabChange={setSelectedSource}
          />
          <div className="w-[70%] border-gray-100">
            <div className="w-full">
              <AttributeList
                data={listOfAttributes}
                activeTab={selectedAttribute}
                handleTabChange={setSelectedAttribute}
              />
            </div>
            <div className="flex items-center">
              <div className="relative w-full border-t">
                <div
                  className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                  aria-hidden="true"
                >
                  <MagnifyingGlassIcon
                    className="mr-3 h-4 w-4 text-gray-500 fill-purple-600"
                    aria-hidden="true"
                  />
                </div>
                <input
                  onChange={(e) => {
                    setQuery(e.target.value);
                  }}
                  value={query}
                  type="text"
                  className="text-sm border-none focus:ring-0 placeholder:text-xs text-gray-500 w-full pl-9 hover:text-gray-700 placeholder:hover:text-gray-700 focus:text-gray-900 sm:text-sm"
                  placeholder={`Search ${type}`}
                />
              </div>
            </div>
            <div className="h-[275px] overflow-y-scroll bg-gray-50 border-t border-gray-200">
              { queriedData.length ? queriedData.map((item: any) => (
                <button
                  type="button"
                  key={uuid()}
                  onClick={() => {
                    setSelected(item);
                    setIsOpen(false);
                  }}
                  className={classNames(
                    "hover:text-white hover:bg-purple-500 bg-ease-linear duration-100 group text-gray-900",
                    "block w-full min-h-0 relative cursor-pointer select-none py-2 pl-3 pr-9",
                  )}
                >
                  <div className="flex items-center">
                    {item?.source === "segment" ? (
                      <Segment className="h-6 w-6 flex-shrink-0 rounded-full mr-3 group-hover:bg-white" />
                    ) : (
                      <He className="h-6 w-6 flex-shrink-0 rounded-full mr-3 group-hover:text-white" />
                    )}
                    <span
                      className={classNames(
                        item === selected ? "font-semibold" : "font-normal",
                        " block truncate text-sm",
                      )}
                    >
                      {item?.display_name}
                    </span>
                  </div>
                  {item === selected ? (
                    <span
                      className={classNames(
                        "absolute inset-y-0 right-0 flex items-center pr-4",
                      )}
                    >
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </button>
              )) : (
                <Paper className="w-full bg-gray-50 h-full flex justify-center items-center">
                  <Text italic size="sm" color="text.3" className="font-semibold text-center">No Matching Items</Text>
                </Paper>
              )}
            </div>
          </div>
        </div>
      </Menu.Dropdown>
    </Menu>
  );
};

export default SingleSelectDropdown;
