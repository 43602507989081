import {
  Divider, Flex, Paper,
} from "@mantine/core";
import Pill from "../../../../../shared/ui/badges/Pill";
import ProfileImg from "../../../../../shared/ui/icons/ProfileImg";
import TruncatedTextTooltip from "../../../../../shared/ui/truncate/TruncatedText";
import { dateFormat } from "../../../../../utils/tableUtils";
import AccountInfo from "./AccountInfo";
import UserHeaderMetrics from "./UserHeaderMetrics";
import UserTraits from "./UserTraits";

type Props = {
  user: any,
};

const UserProfileHeader = ({ user }: Props) => (
  <div className="flex justify-between items-start gap-3 p-4 pt-1">
    <Paper
      shadow="xs"
      radius="sm"
      className="flex-shrink-0"
      style={{ width: "300px", height: "215px" }}
    >
      <div className="flex flex-col h-full">
        <Flex gap={12} p="md" mt={5} className="items-center self-start">
          <ProfileImg
            width={64}
            height={64}
            desc={user?.email}
            img={user?.avatar_url}
            name={user?.name}
          />
          <div className="flex flex-col">
            {user?.name && (
            <TruncatedTextTooltip weight={600} width={180} size="xl" c="text.9" label={user?.name}>
              {user?.name}
              {user?.title && <Pill color="purple" rounded size="sm" text={user.title} />}
            </TruncatedTextTooltip>
            )}
            {user?.email
        && (
        <TruncatedTextTooltip width={180} label={user.email} c="text.5" size="sm">
          {user.email}
        </TruncatedTextTooltip>
        )}
          </div>
        </Flex>
        <Divider className="border-gray-200" />
        <div>
          <UserHeaderMetrics
            lastSeen={user?.last_seen && dateFormat("last", user?.last_seen)}
          />
        </div>
      </div>
    </Paper>
    <UserTraits contact={user} />
    <div className="flex justify-end items-center">
      {user?.account_id
        && <AccountInfo accountIds={user?.related_accounts} />}
    </div>
  </div>
);

export default UserProfileHeader;
