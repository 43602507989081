import {
  Accordion,
  ActionIcon,
  Container, Flex, Stack, Text,
} from "@mantine/core";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";

type Props = {
  title: string;
  description: string;
  IntegrationIcon: any;
  workingDesc?: string;
  children?: React.ReactNode;
};

const IntegrationLayout = ({
  title,
  description,
  IntegrationIcon,
  workingDesc,
  children,
}: Props) => {
  const navigate = useNavigate();
  return (
    <Container p="md" mt={70} className="self-start w-full relative">
      <Flex align="center" className="-mx-10" mb={30} justify="between" gap={12}>
        <ActionIcon size="md" onClick={() => { navigate(-1); }} variant="outline" className="duration-100 transition-all" color="text.3" radius="xl">
          <IconChevronLeft className="h-4 w-4" />
        </ActionIcon>
      </Flex>
      <Stack spacing={5}>
        <Flex align="center" gap={12}>
          {IntegrationIcon && <IntegrationIcon className="!w-8 !h-8" />}
          <Text className="text-gray-900 text-[28px] font-bold">{title}</Text>
        </Flex>
        <Text className="text-gray-700 tracking-tight">{description}</Text>
      </Stack>
      <Accordion className="bg-white rounded-sm mt-8" w="auto" variant="contained" defaultValue="customization">
        <Accordion.Item value="customization">
          <Accordion.Control icon={<IntegrationIcon className="w-5 h-5" />}>How does it work?</Accordion.Control>
          <Accordion.Panel>
            <Text c="text.4" className="tracking-tight" size="md">
              {workingDesc}
            </Text>
            <Link to="https://docs.hyperengage.io/getting-started/understanding-our-data-model" target="blank" className="text-gray-900 rounded-md p-1 w-[105px] duration-75 hover:bg-gray-200 text-sm mt-2 hover:text-gray-900 flex gap-2 items-center">
              Learn More
              {" "}
              <IconChevronRight className="w-3.5 h-3.5" />
            </Link>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      <div className="mt-5">
        {children}
      </div>
    </Container>
  );
};
export default IntegrationLayout;
