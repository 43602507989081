/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */

import { ArrowDownIcon, ArrowUpIcon, MinusIcon } from "@heroicons/react/20/solid";
import { ICellTextProps } from "ka-table/props";
import React from "react";
import { Popover } from "@mantine/core";
import uuid from "react-uuid";
import { useDisclosure } from "@mantine/hooks";
import Pill from "../ui/badges/Pill";

const DropdownCell: React.FC<ICellTextProps> = React.memo(
  ({
    column, dispatch, rowKeyValue, ...props
  }) => {
    const color = "gray";
    const [opened, { close, open }] = useDisclosure(false);
    const value = props.rowData[`${column.key}`];
    return (
      <div className="relative w-full">
        <Popover withinPortal opened={opened} position="top-start" offset={2} shadow="md">
          <Popover.Target>
            <div onMouseEnter={open} onMouseLeave={close} className="cursor-pointer whitespace-nowrap capitalize w-full text-ellipsis overflow-x-hidden block">
              {value[column.key] ? value[column.key] : column.title}
            </div>
          </Popover.Target>
          <Popover.Dropdown className="bg-gray-700 px-3 py-2 rounded-lg opacity-50">
            <div className="py-1 px-5 flex flex-col gap-2 rounded-lg text-ellipsis overflow-x-hidden">
              {value && Object.entries(value)?.map(([key, val]: any) => (key === column.key ? "" : (
                <div className="flex items-center" key={uuid()}>
                  <span className="capitalize w-[90px] italic font-extrabold text-ellipsis whitespace-nowrap overflow-hidden text-gray-400 text-xs">
                    {key}
                    :
                  </span>
                  <span className="text-xs capitalize text-gray-50 italic">{JSON.stringify(val)}</span>
                </div>
              )))}
            </div>
          </Popover.Dropdown>
        </Popover>
      </div>
    );
  },
);

export default DropdownCell;
