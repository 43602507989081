/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import SignalsHeader from "./SignalHeader";
import SignalsBody from "./SignalsBody";
import useWorkspaceStore from "../../store/workspace/workspaceStore";
import { queryClient, useCurrentUser, useInbox } from "../../hooks/QueryHooks";
import { setTimeRange } from "../../utils/dateConverter";
import EmptySignalPlaceholder from "./EmptySignalPlaceholder";

type Props = {
  bodyClass?: string,
  workspace: any,
  onAccountClick: any,
  selectedSignal?: string | null,
  onTabClick?: any,
  accountSignal?: boolean
  accountId?: string | null,
  navTo?: "inbox" | "signals",
  user?: string,
  showTitle?: boolean,
};

const SignalsContainer = ({
  bodyClass,
  workspace,
  onAccountClick,
  selectedSignal,
  onTabClick = () => {},
  accountSignal = false,
  accountId = null,
  navTo = "inbox",
  user,
  showTitle = true,
}: Props) => {
  const [period, setPeriod] = useState<any>("last_30_days");
  const [playbookId, setPlaybookId] = useState<any>();
  const [signalStatus, setSignalStatus] = useState<any>();
  const [automationId, setAutomationId] = useState<any>();
  const [owner, setOwner] = useState<any>(!user ? ["unassigned"] : ["unassigned", user]);
  const [tab, setTab] = useState<"accounts" | "users">("accounts");

  const inbox: any = useInbox({
    automationId,
    workspaceId: workspace?.id,
    entityRelation: tab,
    signalStatus,
    playbookId,
    notificationType: "inbox",
    timeStart: setTimeRange(period).metrics_time_window_start,
    timeEnd: setTimeRange(period).metrics_time_window_end,
    owner,
    retry: 0,
    onError: () => {
      queryClient.setQueryData(["inbox",
        workspace?.id,
        automationId,
        tab,
        setTimeRange(period).metrics_time_window_start,
        setTimeRange(period).metrics_time_window_end,
        playbookId,
        signalStatus,
        "inbox",
        owner,
      ], (oldData: any) => ({
        ...oldData,
        data: {
          data: {
            results: [],
          },
        },
      }));
    },
    select: (data: any) => (
      !accountSignal
        ? data?.data?.results
        : data?.data?.results?.filter(
          (signal: any) => signal?.entity_data?.external_id === accountId,
        )),
  });

  return (
    <div className="w-full">
      <SignalsHeader
        showTitle={showTitle}
        navTo={navTo}
        accountSignal={accountSignal}
        period={period}
        owner={owner}
        setOwner={setOwner}
        setPeriod={setPeriod}
        automationId={automationId}
        setAutomationId={setAutomationId}
        playbookId={playbookId}
        setPlaybookId={setPlaybookId}
        signalStatus={signalStatus}
        setSignalStatus={setSignalStatus}
        tab={tab}
        setTab={setTab}
        onTabClick={onTabClick}
      />
      {inbox?.data?.length ? (
        <SignalsBody
          tab={tab}
          workspaceId={workspace.id}
          onSignalClick={onAccountClick}
          signalsList={inbox?.data}
          selectedSignal={selectedSignal}
          containerClass={`border-none ${bodyClass && bodyClass}`}
        />
      )
        : <EmptySignalPlaceholder isLoading={inbox?.isLoading} className={`${bodyClass || ""}`} />}
    </div>
  );
};

export default SignalsContainer;
