/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Text } from "@mantine/core";
import { useMemo } from "react";
import { dateFormatter } from "../../utils/dateConverter";
import ProfileImg from "../ui/icons/ProfileImg";
import { useWorkspaceMembers } from "../../hooks/QueryHooks";
import useWorkspaceStore from "../../store/workspace/workspaceStore";

type Props = {
  userId: string,
  action: string,
  desc?: any,
  time?: any,
  containerClass?: string,
};

const SignalAction = ({
  userId, action, desc, time, containerClass,
}: Props) => {
  const workspace = useWorkspaceStore((state) => state.workspace);
  const { data: workspaceMembers }: any = useWorkspaceMembers({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
    retry: 2,
  });
  const user = useMemo(() => workspaceMembers
    ?.users?.find((member: any) => member.user_id === userId), [workspaceMembers?.users?.length]);
  return (
    <Flex align="center" justify="space-between" className={`self-stretch gap-3 ${containerClass && containerClass}`}>
      <Flex gap={4} align="center">
        <ProfileImg size="sm" rounded name={user?.name} desc={user?.email} img={user?.avatar_url} />
        <Text size="sm" fw={600}>
          {`${user?.first_name} ${user?.last_name}`}
          {" "}
          <Text span color="text.4" size="sm" transform="lowercase">
            {action}
            {" "}
          </Text>
          {desc && (
          <Text span color="text.9" size="sm" fw={600}>
            {desc}
            {" "}
          </Text>
          )}
        </Text>
      </Flex>
      {time && (
      <Text className="shrink-0" color="text.4" size="sm" fw={500}>
        {dateFormatter(time, "last")}
      </Text>
      )}
    </Flex>
  );
};
export default SignalAction;
