/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/dot-notation */

import uuid from "react-uuid";

/* eslint-disable @typescript-eslint/no-unused-vars */
type ParseData = {
  data: any,
  memberData?: any[],
};

const parseTableData = ({ data, memberData = [] }: ParseData) => {
  const newData = data.map((row: any) => {
    const value = { ...row };
    if (value["accounts.name"] || value["accounts.first_name"] || value["accounts.tracking_domain"]) {
      value["accounts.name"] = {
        name: value["accounts.name"] ? value["accounts.name"] : (value["accounts?.first_name"] ? `${value["accounts.first_name"]} ${value["accounts.last_name"] || ""}` : undefined),
        desc: value["accounts.tracking_domain"],
        img: value["accounts.avatar_url"],
        externalId: value["accounts.external_id"],
      };
      delete (value["accounts.tracking_domain"]);
      delete (value["accounts.native.email"]);
      delete (value["accounts.avatar_url"]);
      delete (value["accounts.external_id"]);
    }
    if (value["users.name"] || value["users.first_name"] || value["users.email"]) {
      value["users.name"] = {
        name: value["users.name"] ? value["users.name"] : (value["users?.first_name"] ? `${value["users.first_name"]} ${value["users.last_name"]}` : undefined),
        desc: value["users.email"],
        img: value["users.avatar_url"],
        externalId: value["users.external_id"],
      };
      delete (value["users.website"]);
      delete (value["users.native_email"]);
      delete (value["users.avatar_url"]);
    }
    if (value["accounts.csm_id"]) {
      const csm = memberData.find((member) => member?.user_id === value["accounts.csm_id"]);
      value["accounts.csm_id"] = {
        name: `${csm?.first_name} ${csm?.last_name}`,
        desc: csm?.email,
        img: csm?.picture_url,
        externalId: csm?.user_id,
      };
    }
    if (value["accounts.csm_updated_by_user_id"]) {
      const mem = memberData.find((member) => member?.user_id === value["accounts.csm_updated_by_user_id"]);
      value["accounts.csm_updated_by_user_id"] = {
        name: `${mem?.first_name} ${mem?.last_name}`,
        desc: mem?.email,
        img: mem?.picture_url,
        externalId: mem?.user_id,
      };
    } if (value["accounts.ae_id"]) {
      const mem = memberData.find((member) => member?.user_id === value["accounts.ae_id"]);
      value["accounts.ae_id"] = {
        name: `${mem?.first_name} ${mem?.last_name}`,
        desc: mem?.email,
        img: mem?.picture_url,
        externalId: mem?.user_id,
      };
    } if (value["accounts.ae_updated_by_user_id"]) {
      const mem = memberData.find((member) => member?.user_id === value["accounts.ae_updated_by_user_id"]);
      value["accounts.ae_updated_by_user_id"] = {
        name: `${mem?.first_name} ${mem?.last_name}`,
        desc: mem?.email,
        img: mem?.picture_url,
        externalId: mem?.user_id,
      };
    }
    value.randomId = uuid();
    return value;
  });
  return newData;
};

export default parseTableData;
