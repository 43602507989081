/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */

import { IconChevronsDown, IconChevronsUp } from "@tabler/icons-react";
import { ICellTextProps } from "ka-table/props";
import React from "react";
import Pill from "../ui/badges/Pill";

const MetricCell: React.FC<ICellTextProps> = React.memo(
  ({
    column, dispatch, rowKeyValue, ...props
  }) => {
    let color = "gray";
    const value = props.rowData[`${column.key}`];
    let icon;
    if ((value?.delta) > 0 || value?.delta === null) {
      icon = IconChevronsUp;
      color = "green";
    } else if ((value?.delta) < 0) {
      icon = IconChevronsDown;
      color = "red";
    }
    return (
      <span className="whitespace-nowrap capitalize w-full text-ellipsis overflow-hidden block px-2">
        <div className="flex items-center">
          <div className="w-[30px]">
            {value?.value || "0"}
          </div>
          <Pill
            LeadingIcon={icon}
            text={(value?.delta === null ? "∞" : `${value?.delta} %`)}
            color={color}
            rounded
            size="md"
          />
        </div>
      </span>
    );
  },
);

export default MetricCell;
