import React, { useEffect } from "react";
import { IconArrowRight } from "@tabler/icons-react";
import MButton from "../../../shared/ui/buttons/MButton";

const CalendlyButton = ({ name, email }: { name: string, email: string }) => {
  useEffect(() => {
    const { head } = document;
    const link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "https://assets.calendly.com/assets/external/widget.css";
    head.appendChild(link);

    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      head.removeChild(link);
      document.body.removeChild(script);
    };
  }, []);

  const handleClick = () => {
    const calendlyUrl = `https://calendly.com/d/z2d-jcx-jf5/hyperengage-founders?name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}`;
    Calendly.initPopupWidget({ url: calendlyUrl });
  };

  return (
    <MButton onClick={handleClick} color="purple" mb={0} rightIcon={<IconArrowRight width={20} />}>Schedule a demo</MButton>
  );
};

export default CalendlyButton;
