import {
  CheckCircleIcon, XCircleIcon, ExclamationTriangleIcon, InformationCircleIcon,
} from "@heroicons/react/20/solid";
import uuid from "react-uuid";
import { notifications } from "@mantine/notifications";

type Props = {
  heading?:string,
  type?: "failure" | "success" | "warning" | "info",
  messageList: string[] | React.ReactNode[],
};

const notify = ({
  heading = "",
  type = "warning",
  messageList,
} : Props) => {
  let color;
  let Icon;

  switch (type) {
    case ("success"): {
      color = "green";
      Icon = CheckCircleIcon;
      break;
    }
    case ("failure"): {
      color = "red";
      Icon = XCircleIcon;
      break;
    }
    case ("warning"): {
      color = "yellow";
      Icon = ExclamationTriangleIcon;
      break;
    }
    case ("info"): {
      color = "blue";
      Icon = InformationCircleIcon;
      break;
    }
    default: {
      color = "yellow";
      Icon = ExclamationTriangleIcon;
    }
  }

  return notifications.show({
    title: heading,
    message: messageList.join("\n"),
    id: uuid(),
    color,
    icon: <Icon className="w-5 h-5" />,
    autoClose: 6000,
    classNames: {
      root: `bg-${color}-100 border border-${color}-200 text-${color}-700 !z-[10000]`,
      title: `text-${color}-800`,
      description: `text-${color}-600`,
      closeButton: `text-${color}-700 hover:bg-${color}-200`,
    },
  });
};

export default notify;
