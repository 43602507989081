/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */

import { ArrowDownIcon, ArrowUpIcon, MinusIcon } from "@heroicons/react/20/solid";
import { ICellTextProps } from "ka-table/props";
import React from "react";
import Flag from "react-world-flags";
import Avatar from "../ui/avatar/Avatar";
import { getValueForRow } from "../../utils/tableUtils";

const LocationCell: React.FC<ICellTextProps> = React.memo(
  ({
    column, dispatch, rowKeyValue, ...props
  }) => {
    const value = props.rowData[`${column.key}`];
    const location = value ? value.split("|||") : ["", ""];

    return (
      <span className="whitespace-nowrap capitalize w-full text-ellipsis overflow-hidden block">
        <Flag code={location[0]} width={24} height={24} />
        <div className="ml-3 text-sm ka-cell-text">
          <div>{location[1] || "Unknown City"}</div>
          <div>{getValueForRow("location_country", location[0])}</div>
        </div>
      </span>
    );
  },
);

export default LocationCell;
