import { ReactComponent as Api } from "../../../assets/images/nativeIcon.svg";

type Props = {
  size?: string,
  className? : string,
};

const ApiIcon = ({
  size = "md",
  className,
} : Props) => {
  let iconStyle = "bg-purple-700";
  if (size === "sm") {
    iconStyle += " w-[156px]";
  } else if (size === "md") {
    iconStyle += " w-[110px]";
  } else if (size === "lg") {
    iconStyle += " w-[60px]";
  }

  if (className) {
    iconStyle = `${iconStyle} ${className}`;
  }

  return (
    <Api className={iconStyle} />
  );
};

export default ApiIcon;
