/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { subDays } from "date-fns";
import { ITableProps, kaReducer } from "ka-table";
import {
  hideLoading, loadData, showLoading, updateData, updatePageIndex, updatePagesCount,
} from "ka-table/actionCreators";
import { ActionType, SortingMode } from "ka-table/enums";
import { DispatchFunc } from "ka-table/types";
import {
  useEffect, useLayoutEffect, useMemo, useRef, useState,
} from "react";
import { useParams } from "react-router-dom";
import uuid from "react-uuid";
import useLocalStorageState from "use-local-storage-state";
import { getEntityActivity } from "../../../../../services/userActivityEndpoint";
import KaTable, { IActionPayload } from "../../../../../shared/table/KaTable";
import dateConverter from "../../../../../utils/dateConverter";

const UsersTable = ({ metric, height, workspaceId }: any) => {
  const tableRef = useRef<any>(null);
  const [tableColumns, setTableColumns] = useLocalStorageState<any>("he-entity-activity-prefs", {
    defaultValue: [
      {
        key: "event",
        dataType: "object",
        title: "Action Performed",
        isResizable: true,
        isSortable: false,
        colGroup: { style: { minWidth: 50 } },
      }, {
        key: "_timestamp",
        dataType: "string",
        title: "Time",
        isResizable: true,
        colGroup: { style: { minWidth: 50 } },
        isSortable: true,
      }, {
        key: "src",
        dataType: "string",
        title: "source",
        isResizable: true,
        colGroup: { style: { minWidth: 50 } },
        isSortable: true,
      }, {
        key: "url",
        title: "page",
        isResizable: true,
        colGroup: { style: { minWidth: 50 } },
        isSortable: false,
      },
    ],
  });

  const tableHeight = useMemo(() => window.innerHeight - 370, [height]);

  const [tableProps, changeTableProps] = useState<ITableProps>({
    columns: tableColumns,
    columnResizing: true,
    paging: {
      enabled: true,
      pageIndex: 0,
      pagesCount: 1,
      pageSize: 20,
    },
    columnReordering: true,
    height: tableHeight,
    rowKeyField: "randomId",
    singleAction: loadData(),
    sortingMode: SortingMode.Single,
    loading: {
      enabled: true,
      text: "Loading",
    },
  });

  useLayoutEffect(() => {
    if (tableHeight) {
      changeTableProps((prevState: ITableProps) => ({
        ...prevState,
        height: tableHeight,
      }));
    }
  }, [tableHeight]);

  const [noResults, setNoResults] = useState<boolean>(false);
  const params: any = useParams();
  const [total, setTotal] = useState<any>(0);

  const calcDate: any = useMemo(() => {
    if (metric?.name) {
      const val = parseInt(metric.name.match(/[0-9]+/) ? metric.name.match(/[0-9]+/)[0] : "1", 10);
      return subDays(new Date(), val);
    }
    return new Date("1-1-1970");
  }, [metric.name]);

  const onColumnChange = (payload: IActionPayload) => {
    const {
      type, columnKey, width, targetColumnKey,
    } = payload;

    if (type === ActionType.ResizeColumn) {
      const newFields = tableColumns.map((field: any) => {
        if (field.key === columnKey) {
          field.width = width;
        }
        return field;
      });

      setTableColumns(newFields);
    }

    if (type === ActionType.ReorderColumns) {
      const columnIndex = tableColumns.findIndex((field: any) => field.key === columnKey);
      const targetColumnIndex = tableColumns.findIndex(
        (field: any) => field.key === targetColumnKey,
      );
      const newFields = [...tableColumns];
      const temp = newFields[columnIndex];
      newFields[columnIndex] = newFields[targetColumnIndex];
      newFields[targetColumnIndex] = temp;
      // shift the column to the target column index.
      setTableColumns(newFields);
    }
  };

  const kaDispatch: DispatchFunc = (action: any) => {
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
    const sortColumn: any = tableProps.columns.find((c) => c.sortDirection);
    if (action.type === ActionType.ResizeColumn || action.type === ActionType.ReorderColumns) {
      if (action.type === ActionType.ResizeColumn) {
        onColumnChange({
          type: action.type,
          columnKey: action.columnKey,
          width: action.width,
        });
      } else {
        onColumnChange({
          type: action.type,
          columnKey: action.columnKey,
          targetColumnKey: action.targetColumnKey,
        });
      }
    }
  };

  const fetchData = async (
    page: number,
  ) => {
    kaDispatch(showLoading());
    try {
      const response = await getEntityActivity({
        external_id: params.userId,
        workspace_identifier: workspaceId,
        entity_type: "contacts",
        start_time: dateConverter(calcDate),
        offset: page * 20,
        limit: 20,
      });
      if (response?.data) {
        const temp = response?.data?.results.map((item: any) => {
          const value = { ...item };
          if (value?.event) {
            value.event = {
              event: value?.event,
              category: value?.event_category,
              ...value.traits,
            };
            delete (value.category);
            delete (value?.traits);
          }
          value.randomId = uuid();
          return value;
        });
        setTotal(response.data.total_hits);
        kaDispatch(updateData(temp));
        if (response.data.total_hits < ((tableProps?.paging?.pageIndex || 0) * (tableProps?.paging?.pageSize || 1))) {
          kaDispatch(updatePageIndex(0));
        }
        kaDispatch(updatePagesCount(Math.ceil(response.data.total_hits / (tableProps?.paging?.pageSize ?? 1)) || 0));
        kaDispatch(hideLoading());
      } else {
        setTotal(0);
        setNoResults(true);
        kaDispatch(updatePageIndex(0));
        kaDispatch(updateData([]));
        kaDispatch(updatePagesCount(1));
        kaDispatch(hideLoading());
      }
    } catch (err) {
      setTotal(0);
      setNoResults(true);
      kaDispatch(updatePageIndex(0));
      kaDispatch(updateData([]));
      kaDispatch(updatePagesCount(1));
      kaDispatch(hideLoading());
    }
  };

  useEffect(() => {
    fetchData(tableProps?.paging?.pageIndex || 0);
  }, [metric, tableProps?.paging?.pageIndex]);

  return (
    <KaTable
      ref={tableRef}
      tableProps={tableProps}
      changeTableProps={changeTableProps}
      noDataTitle="No Recent Activity"
      noDataMessage="No recent activity found for this user in the given time frame."
      kaDispatch={kaDispatch}
      rowKeyField="randomId"
      itemType="Actions"
      onColumnChange={onColumnChange}
      totalData={total}
    />
  );
};

export default UsersTable;
