/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */

import { ICellTextProps } from "ka-table/props";
import React from "react";
import { dateFormat, getValueForRow } from "../../utils/tableUtils";

const DateCell: React.FC<ICellTextProps> = React.memo(
  ({
    column, dispatch, rowKeyValue, ...props
  }) => {
    const value = props.rowData[`${column.key}`];
    return (
      <span className="whitespace-nowrap text-gray-500 w-full text-ellipsis overflow-hidden block !px-2 !py-2">
        {dateFormat(column.key, value)}
      </span>
    );
  },
);

export default DateCell;
