/* eslint-disable react/destructuring-assignment */
import React from "react";
import {
  Tabs,
} from "@mantine/core";

type Props = {
  activeTab: string,
  data: any[],
  handleTabChange: (value: string) => void,
};

const AttributeList = ({
  activeTab,
  data,
  handleTabChange,
} : Props) => (
  <Tabs
    value={activeTab}
    className="h-full w-full text-xs"
    onTabChange={handleTabChange}
    orientation="horizontal"
  >
    <Tabs.List>
      {data.map((item: any) => (
        <Tabs.Tab
          className="text-xs w-[32.2%]"
          icon={(
            <item.icon
              width={15}
              height={15}
            />
)}
          value={item.name}
          key={item.name}
        >
          {item.label}

        </Tabs.Tab>
      ))}
    </Tabs.List>
  </Tabs>
);

export default AttributeList;
