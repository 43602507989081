import { IconApi } from "@tabler/icons-react";
import { useState } from "react";
import { ReactComponent as BrowserIcon } from "../../../assets/images/browser.svg";
import { ReactComponent as NodeIcon } from "../../../assets/images/node.svg";
import { ReactComponent as RubyIcon } from "../../../assets/images/ruby.svg";
import { useApiKey } from "../../../hooks/QueryHooks";
import useSelectWorkspace from "../../../hooks/useSelectWorkspace";
import stepSelector from "../../../utils/stepSelector";
import NativeApiHero from "./components/NativeApiHero";
import NativeSteps from "./components/NativeSteps";

const NativeApiPage = () => {
  const [selectedMethod, setSelectedMethod] = useState<string>("Browser");
  const selectedWorkspace: any = useSelectWorkspace();
  const apiKey: any = useApiKey({
    workspaceId: selectedWorkspace?.data?.id,
    enabled: !!selectedWorkspace?.data?.id,
  });

  const types = [{ name: "Browser", icon: BrowserIcon }, { name: "Node", icon: NodeIcon }, { name: "Api", icon: IconApi }, { name: "Ruby", icon: RubyIcon }];

  const onMethodClick = (name: string) => {
    setSelectedMethod(name);
  };

  return (
    <section className="flex flex-col md:flex-row md:overflow-hidden rounded-xl border-2 border-gray-200 bg-white w-[85%] mt-[50px] h-[80vh] overflow-auto">
      <NativeApiHero choices={types} onClick={onMethodClick} selected={selectedMethod} />
      <div className="w-full min-w-0 text-white flex flex-col basis-[60%] gap-4">
        <NativeSteps
          steps={stepSelector(
            selectedMethod,
            selectedWorkspace?.data?.unique_identifier,
            selectedMethod === "Api" ? apiKey?.data?.server_analytics_key : apiKey?.data?.js_analytics_key,
          )}
          api={selectedMethod === "Api"}
          data={{ apiKey: apiKey?.data, workspace: selectedWorkspace?.data }}
        />
      </div>
    </section>
  );
};

export default NativeApiPage;
