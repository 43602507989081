/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, Text } from "@mantine/core";
import { useApiKey } from "../../../hooks/QueryHooks";
import useSelectWorkspace from "../../../hooks/useSelectWorkspace";
import stepSelector from "../../../utils/stepSelector";
import NativeSteps from "./components/SegmentSteps";
import { ReactComponent as SegmentIcon } from "../../../assets/images/segmentGreen.svg";
import IntegrationLayout from "../../../shared/integration/IntegrationLayout";

const SegmentApiPage = () => {
  const selectedWorkspace: any = useSelectWorkspace();
  const apiKey: any = useApiKey({
    workspaceId: selectedWorkspace?.data?.id,
    enabled: !!selectedWorkspace?.data?.id,
  });

  return (
    <IntegrationLayout
      title="Segment"
      description="Install Hyperengage via Segment's client-side Analytics library"
      workingDesc="With Segment you can install Hyperengage without code.
      Use our client-side destination (recommended) to automatically send all your track and identify calls from Segment to Hyperengage and start capturing active website sessions in Hyperengage. Alternatively, you can use our server-side destination to automatically send events and identifies from Segment to Hyperengage server-side."
      IntegrationIcon={SegmentIcon}
    >
      <NativeSteps
        steps={stepSelector(
          "Browser",
          selectedWorkspace?.data?.unique_identifier,
          apiKey?.data?.js_analytics_key,
        )}
        data={{ apiKey: apiKey?.data, workspace: selectedWorkspace?.data }}
      />
    </IntegrationLayout>
  );
};

export default SegmentApiPage;
