/* eslint-disable react/jsx-props-no-spreading */
import {
  Avatar,
  Flex,
  Select,
  Text,
} from "@mantine/core";
import { forwardRef } from "react";

type Props = {
  users?: any[],
  onUserSelect?: any
  selected?: any,
  value?: any,
  placeHolder?: string,
  disabled?: boolean,
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  avatar_url?: string;
  email?: string;
  name: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({
    avatar_url, email, name, ...others
  }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Flex gap={8} align="center" className="overflow-hidden">
        <Avatar src={avatar_url} color="main.6" radius="xl" size="sm" alt={name}>{name ? name[0] : ""}</Avatar>
        <div>
          <Text truncate size="sm">{name}</Text>
          <Text truncate size={10} opacity={0.65}>
            {email}
          </Text>
        </div>
      </Flex>
    </div>
  ),
);

const AddUserDropdown = ({
  value, selected, users = [], onUserSelect = () => {}, placeHolder, disabled = false,
}: Props) => (
  <Select
    value={value}
    size="xs"
    placeholder={placeHolder}
    ff="Nunito"
    classNames={{
      input: "font-nunito text-sm text-gray-600",
      item: "px-1.5 py-1",
    }}
    itemComponent={SelectItem}
    icon={(
      <div>
        <Avatar
          radius="xxl"
          c="main.6"
          size="sm"
          src={selected?.avatar_url}
          alt={selected?.name}
        >
          {selected?.name ? selected?.name[0] : ""}
        </Avatar>
      </div>
)}
    onChange={(val) => onUserSelect(val)}
    data={users}
    searchable
    disabled={disabled}
    maxDropdownHeight={400}
    nothingFound="Nobody here"
    filter={(val, item) => (item?.name?.toLowerCase()?.includes(val?.toLowerCase()?.trim())
      || item?.email?.toLowerCase()?.includes(val?.toLowerCase()?.trim()))}
  />
);

export default AddUserDropdown;
