/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Container, Flex, Text } from "@mantine/core";
import classNames from "../../utils/classNames";
import ProfileImg from "../ui/icons/ProfileImg";

type Props = {
  title: string,
  desc?: string,
  name?: string,
  avatar_url?:string,
  time?:string
  selected?: boolean,
  onClick?: any,
  accountId?: string,
  signalId?: string,
  tab?: string,
};

const SignalCard = ({
  title, desc, name,
  avatar_url, time,
  selected = false, accountId, signalId, onClick = () => {},
  tab,
}: Props) => (
  <Container onClick={() => { onClick(accountId, signalId, selected, tab); }} className={classNames("border-2 pr-5 rounded-md max-h-[100px] overflow-hidden py-3 w-full cursor-pointer", selected && "border-purple-200 bg-purple-100 shadow-sm shadow-text")}>
    {name && (
    <Flex gap={10} className="w-full" align="center">
      <ProfileImg img={avatar_url} name={name} size="md" />
      <div className="flex-col gap-0 overflow-hidden">
        <Text c="text.9" className="whitespace-nowrap overflow-hidden text-ellipsis capitalize group-hover:underline font-semibold">{name}</Text>
        {time && <Text className="whitespace-nowrap lowercase overflow-hidden text-ellipsis -mt-0.5 text-gray-500 group-hover:underline text-xs">{time}</Text>}
      </div>
    </Flex>
    )}
    <Text size="sm" fw={600} c="text.9" className="mt-2">{title}</Text>
    {desc && <Text lineClamp={2} size="sm" fw={400} c="text.7">{desc}</Text>}
  </Container>
);

export default SignalCard;
