/* eslint-disable @typescript-eslint/no-unused-vars */
import KeyRoles from "./KeyRoles";
import LeftAccountBox from "./LeftAccountBox";
import VisibleTraits from "./VisibleTraits";

type Props = {
  account: any,
  contacts: any
};

const AccountProfileHeader = ({ account, contacts = [] }: Props) => (
  <div className="flex justify-between gap-3 p-4 pt-1">
    <LeftAccountBox account={account} />
    <VisibleTraits account={account} />
    {account?.external_id && (
      <KeyRoles
        key={account?.external_id}
        accountId={account?.external_id}
        contacts={contacts}
        owner={account?.account_champion_id}
        csm={account?.csm_id}
        ae={account?.ae_id}
      />
    )}
  </div>
);
export default AccountProfileHeader;
