import { authorizedApiClient } from "../config";
import {
  CreatePlaybook, CreatePlaybookResponse,
  ReadAllPlaybooks, ReadAllPlaybooksResponse,
  ReadPlaybookById, ReadPlaybookByIdResponse,
  UpdatePlaybook, UpdatePlaybookResponse,
} from "./playbookEndpoints.interface";

export const createPlaybook = async ({
  title,
  actions,
  content,
  content_json,
  entity_relation,
  workspace_id,
  owner_id,
  updated_by,
}: CreatePlaybook) => authorizedApiClient.post<CreatePlaybookResponse>(`/${workspace_id}/playbooks`, {
  title,
  actions,
  content,
  content_json,
  entity_relation,
  workspace_id,
  owner_id,
  updated_by,
});

export const readAllPlaybooks = async ({
  workspace_id,
  skip = 0,
  limit = 50,
}: ReadAllPlaybooks) => authorizedApiClient.get<ReadAllPlaybooksResponse>(`/${workspace_id}/playbooks/all`, {
  params: { skip, limit },
});

export const readPlaybookById = async ({
  workspace_id,
  playbook_id,
}: ReadPlaybookById) => authorizedApiClient.get<ReadPlaybookByIdResponse>(`/${workspace_id}/playbooks/${playbook_id}`);

export const updatePlaybook = async ({
  title,
  actions,
  content,
  content_json,
  entity_relation,
  updated_by,
  workspace_id,
  playbook_id,
}: UpdatePlaybook & { workspace_id: string; playbook_id: string }) => authorizedApiClient.put<UpdatePlaybookResponse>(`/${workspace_id}/playbooks/${playbook_id}`, {
  title,
  actions,
  content,
  content_json,
  entity_relation,
  updated_by,
});

export const deletePlaybook = async ({
  workspace_id,
  playbook_id,
}: ReadPlaybookById) => authorizedApiClient.delete<ReadPlaybookByIdResponse>(`/${workspace_id}/playbooks/${playbook_id}`);
