/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
import { Avatar, Tooltip } from "@mantine/core";
import { ICellTextProps } from "ka-table/props";
import React from "react";
import { useNavigate } from "react-router-dom";
import useWorkspaceStore from "../../store/workspace/workspaceStore";
import mantineRandomColor from "../../utils/MantineRandomColor";
import avatarGenerator from "../../utils/avatarGenerator";

/**
 * Custom cell component.
 */
const RelatedAccountsCell: React.FC<ICellTextProps> = React.memo(
  ({
    column, dispatch, rowKeyValue, ...props
  }) => {
    const value = props.rowData[`${column.key}`];
    const { workspace } = useWorkspaceStore();
    const navigate = useNavigate();
    return (
      <span className="whitespace-nowrap text-gray-500 w-full text-ellipsis overflow-hidden block !px-2 !py-2">
        {" "}
        <Avatar.Group spacing="sm">
          {value?.length > 0 && value?.map((item: any) => (
            <Tooltip label={item["accounts.name"]}>
              <Avatar
                onClick={() => {
                  navigate(`account/${item["accounts.external_id"]}/overview`);
                }}
                size="sm"
                className="hover:z-[2] hover:scale-125 cursor-pointer"
                src={avatarGenerator({ img: item["accounts.avatar_url"], desc: item["accounts.name"], size: 80 })}
                radius="xl"
                color={mantineRandomColor()}
              >
                {item["accounts.name"]?.charAt(0)}
              </Avatar>
            </Tooltip>
          ))}
        </Avatar.Group>
      </span>
    );
  },
);
export default RelatedAccountsCell;
