import React, { useState } from "react";
import SignalLogControls from "./components/SignalLogControls";
import SignalLogsTable from "./components/SignalLogsTable";

const SignalLogsPage = () => {
  const [status, setStatus] = useState("all");
  const [alert, setAlert] = useState("all");
  return (
    <>
      <SignalLogControls status={status} setStatus={setStatus} alert={alert} setAlert={setAlert} />
      <SignalLogsTable status={status} alert={alert} />
    </>
  );
};

export default SignalLogsPage;
