/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-spread */
import {
  Flex, ScrollArea, Stack, Text,
} from "@mantine/core";
import uuid from "react-uuid";
import { useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import AddComment from "./AddComment";
import SignalAction from "./SignalAction";
import SignalCard from "./SignalCard";
import SignalInfo from "./SignalInfo";
import { dateFormat } from "../../utils/tableUtils";
import { createSignalComment } from "../../services/inbox/inboxEndpoints";
import { queryClient, useCurrentUser } from "../../hooks/QueryHooks";
import EmptySignalBodyPlaceholder from "./EmptySignalBodyPlaceholder";

type Props = {
  containerClass?: string,
  selectedSignal?: string | null,
  signalsList?: any[],
  onSignalClick?: any,
  workspaceId?: string,
  tab?: any,
};

const SignalsBody = ({
  tab, workspaceId, containerClass, selectedSignal, signalsList = [], onSignalClick = () => {},
}: Props) => {
  const actionSignal = useMemo(
    () => signalsList?.find((item: any) => item?.SignalRuns?.id === selectedSignal),
    [signalsList, selectedSignal],
  );
  const user: any = useCurrentUser({});
  const commentMutation = useMutation({
    mutationFn: createSignalComment,
    onSuccess: () => {
      queryClient.invalidateQueries(["inbox"]);
    },
  });
  const onComment = (comment: any) => {
    if (comment.length && selectedSignal && workspaceId) {
      commentMutation.mutate({
        signal_id: selectedSignal || "",
        comment,
        workspace_id: workspaceId,
        user_id: user?.data?.user_id,
      });
    }
  };
  return (
    <Flex className={`border-2 max-h-[550px] px-4 border-l-0 bg-white border-gray-200 rounded-b-lg overflow-hidden ${containerClass || ""}`}>
      <Stack spacing={2} className="w-[300px] shrink-0 self-stretch py-2 border-r items-start">
        {signalsList?.length > 1 && (
        <Text size={12} mb={8} italic fw={600} c="text.5">
          {signalsList.length}
          {" "}
          Signals
        </Text>
        )}
        <ScrollArea offsetScrollbars className="w-full">
          <Stack className="pb-4 px-1 w-full">
            {signalsList?.map((item: any) => (
              <SignalCard
                key={item?.SignalRuns?.id}
                tab={tab}
                selected={selectedSignal === item?.SignalRuns?.id}
                accountId={item?.entity_data?.external_id}
                signalId={item?.SignalRuns?.id}
                onClick={onSignalClick}
                time={item?.SignalRuns?.created_at ? dateFormat("last", item?.SignalRuns?.created_at) : undefined}
                name={item?.entity_data?.name}
                title={item?.Automation?.name}
                desc={item?.SignalRuns?.name}
                avatar_url={item?.entity_data?.avatar_url}
              />
            ))}
          </Stack>
        </ScrollArea>
      </Stack>
      {actionSignal ? (
        <Stack spacing={15} className="flex-1 items-start">
          <ScrollArea className="self-stretch" offsetScrollbars>
            <SignalInfo
              title={selectedSignal ? actionSignal?.Automation?.name : undefined}
              account={selectedSignal ? actionSignal?.entity_data : undefined}
              status={selectedSignal ? actionSignal?.SignalRuns?.status : undefined}
              signalId={selectedSignal ? actionSignal?.SignalRuns?.id : undefined}
              externalId={selectedSignal ? actionSignal?.entity_data?.external_id : undefined}
              playbookId={selectedSignal ? actionSignal?.SignalRuns?.playbook_id : undefined}
            />
            <Stack px={12} spacing={8}>
              {actionSignal ? actionSignal?.SignalRuns?.comments?.map((item: any) => (
                <SignalAction
                  userId={item.user_id}
                  action="added a comment"
                  desc={item.comment}
                  time={item.created_at}
                />
              )) : null}
            </Stack>
            <AddComment onSubmit={onComment} />
          </ScrollArea>
        </Stack>
      ) : <EmptySignalBodyPlaceholder height="full" />}
    </Flex>
  );
};

export default SignalsBody;
