import { AdjustmentsVerticalIcon } from "@heroicons/react/20/solid";
import { useMemo } from "react";
import BasicSelect from "../../../../../shared/ui/inputs/select/BasicSelect";

const UserToolbar = ({ metric, setMetric }: any) => {
  const metrics = useMemo(() => (
    ["Last day", "Last 7 days", "Last 30 days", "Last 90 days", "Last 180 days", "Last 365 days"].map((key, id) => ({ id: (id + 1), name: key }))), []);
  return (
    <div className="flex mt-4 gap-10 px-5 w-full justify-between items-center">
      <h2 className="text-gray-900 whitespace-nowrap text-[20px] font-semibold">
        User activity
      </h2>
      <div className="flex flex-wrap gap-2 items-center justify-end flex-1">
        <BasicSelect textClass="text-gray-700" LeadingIcon={AdjustmentsVerticalIcon} containerClass="min-w-[140px]" placeholder="Last ..." list={metrics} selectedItem={metric} setSelectedItem={setMetric} />
      </div>
    </div>
  );
};
export default UserToolbar;
