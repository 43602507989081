/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import {
  Flex,
  LoadingOverlay, Select, Text, TextInput, Tooltip,
} from "@mantine/core";
import { useValidatedState } from "@mantine/hooks";
import { useLogoutFunction } from "@propelauth/react";
import { currencies } from "currencies.json";
import { useEffect, useState } from "react";
import {
  useNavigate,
} from "react-router-dom";
import TimezoneSelect from "react-timezone-select";
import validator from "validator";
import { IconInfoCircle } from "@tabler/icons-react";
import { queryClient } from "../../../hooks/QueryHooks";
import useSelectWorkspace from "../../../hooks/useSelectWorkspace";
import { createApiKey } from "../../../services/apiKeyEndpoint";
import { updateWorkspace } from "../../../services/workspace/workspaceEndpoints";
import MButton from "../../../shared/ui/buttons/MButton";
import notify from "../../../utils/notify";

const OnboardingWorkspace = () => {
  const { data, isLoading } = useSelectWorkspace();
  const navigate = useNavigate();
  const [currency, setCurrency] = useState<string | null>("USD");
  const [{ value: trackingDomain, valid }, setTrackingDomain] = useValidatedState(
    "https://",
    // eslint-disable-next-line no-useless-escape
    (value) => (!validator.isURL(value)),
    true,
  );

  const [timezone, setTimezone] = useState<any>(Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || "");
  const logout = useLogoutFunction();

  const submitHandler = async () => {
    if (data?.id) {
      try {
        await Promise.all([await updateWorkspace({
          workspace_id: data?.id,
          workspace_time_zone: timezone?.value || timezone,
          is_default_workspace: true,
          workspace_default_currency: currency || "USD",
          tracking_website_domain: trackingDomain,
        }),
        await createApiKey({ workspace_id: data?.id })]);
        notify({
          type: "success",
          messageList: ["Successfully updated workspace"],
        });
        queryClient.setQueryData(["workspace", data?.id], (oldData: any) => ({
          ...oldData,
          data: {
            ...oldData?.data,
            workspace_time_zone: timezone?.value || timezone,
            is_default_workspace: true,
            workspace_default_currency: currency || "USD",
            tracking_website_domain: trackingDomain,
          },
        }));
        navigate(`/onboarding/workspace/${data?.unique_identifier}/integrations`, { replace: true });
      } catch (err) {
        notify({
          heading: "Workspace creation failed",
          type: "failure",
          messageList: ["Sorry for the inconvenience. Kindly contact support to help you with this issue"],
        });
      }
    } else {
      notify({
        heading: "Failed to update workspace",
        type: "failure",
        messageList: ["The provided workspace id is invalid"],
      });
    }
  };

  useEffect(() => {
    if (data?.tracking_website_domain
      && data?.workspace_default_currency && data?.workspace_time_zone) {
      navigate(`/onboarding/workspace/${data?.unique_identifier}/integrations`, { replace: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.tracking_website_domain, data?.workspace_default_currency, data?.workspace_time_zone]);

  return (
    <section className="flex flex-col rounded-lg border-2 border-gray-200 p-9 max-w-[780px] gap-2 bg-white">
      <LoadingOverlay
        loaderProps={{ size: "lg" }}
        c="main.5"
        visible={isLoading || (data?.tracking_website_domain
      && data?.workspace_default_currency && data?.workspace_time_zone)}
        overlayBlur={10}
      />
      <h2 className="font-bold">
        Let's complete your workspace setup
      </h2>
      <h4>
        Workspaces allow you to easily manage your data in
        multiple environments and allow fine grain access
        to different teams for enhanced data security.
      </h4>
      <form className="flex flex-col gap-6 mt-2 text-gray-900">
        <div className="h-[75px]">
          <TextInput
            placeholder="https://YourAwesomeWebsite.com"
            value={trackingDomain}
            description="Just the naked domain or subdomain without the 'www'"
            error={valid && trackingDomain !== "https://" && "Please enter a valid URL"}
            onChange={(e) => setTrackingDomain(e.currentTarget.value)}
            label={(
              <Flex align="items-center" className="gap-1">
                Domain
                <Text span c="red.5">*</Text>
                <Tooltip label="This url represent the primary URL of your application">
                  <IconInfoCircle className="text-gray-400 w-[15px] cursor-context-menu" />
                </Tooltip>
              </Flex>
            )}
            autoFocus
          />
        </div>
        <div className="h-[75px]">
          <Select
            data={currencies.map((curr: any) => (
              { label: `${curr.name} (${curr.symbolNative})`, value: `${curr.code}` }
            ))}
            description="To make cents (and dollars) of our transactions."
            value={currency}
            searchable
            onChange={setCurrency}
            label={(
              <Flex align="items-center" className="gap-1">
                Workspace Currency
                <Text span c="red.5">*</Text>
                <Tooltip label="This helps our system accurately attribute your revenue data to customers">
                  <IconInfoCircle className="text-gray-400 w-[15px] cursor-context-menu" />
                </Tooltip>
              </Flex>
)}
          />
        </div>
        <div className="h-[75px]">
          <label htmlFor="timezone" className="block text-sm font-semibold text-gray-700">
            Timezone
            {" "}
            <Text span c="red">*</Text>
            <Text c="text.3" weight={400} size="xs">To make sure we agree on what 'today' means</Text>
            <TimezoneSelect
              id="timezone"
              value={timezone}
              onChange={setTimezone}
            />
          </label>
        </div>
      </form>
      <div className="flex justify-end align-center mt-5">
        <span className="inline-flex gap-2 items-center">
          <MButton variant="subtle" onClick={() => { logout(true); }}>Logout</MButton>
          <MButton size="sm" color="purple" disabled={!currency || !timezone || !trackingDomain} onClick={() => { submitHandler(); }}>
            Complete Setup
          </MButton>
        </span>
      </div>
    </section>
  );
};

export default OnboardingWorkspace;
