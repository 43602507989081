import React from "react";
import { format } from "date-fns";
import Dash from "../../../../shared/placeholder/Dash";
import Pill from "../../../../shared/ui/badges/Pill";
import TruncatedTextTooltip from "../../../../shared/ui/truncate/TruncatedText";

type Props = {
  trait: any,
};

const TraitType = ({ trait }: Props) => {
  if (!trait?.val) {
    return (<Dash className="mt-2 bg-gray-400 opacity-80" />);
  } if (trait?.data_type === "datetime" || trait?.data_type === "timestamp") {
    return (<div className="text-gray-800 text-sm font-semibold">{format(new Date(trait.val), "MMM dd, yyyy")}</div>);
  } if (trait?.name === "mrr") {
    return (
      <Pill
        color="green"
        rounded
        size="sm"
        className=" min-w-[40px] items-center justify-center"
        text={`$${trait?.val || 0}`}
      />
    );
  }
  return (
    <TruncatedTextTooltip label={trait?.val} width={150} c="text-gray-800" size="sm" weight={500}>{trait?.val}</TruncatedTextTooltip>
  );
};

export default TraitType;
