import React from "react";

type Props = {
  id: string, className? : string,
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
  checked: boolean,
  children?: React.ReactNode,
  disabled?: boolean,
};

const Checkbox = ({
  id,
  className,
  disabled = false,
  onChange,
  children,
  checked,
} : Props) => {
  let style = "h-4 w-4 cursor-pointer rounded border-gray-300 text-indigo-600 hover:border-secondary-400 focus:ring-0 focus:border-primary-700 focus:shadow-textBox checked:bg-purple-400 checked:hover:bg-purple-600 checked:hover:text-white checked:focus:bg-purple-700 checked:focus:ring-0";

  if (className) {
    style = `${style} ${className}`;
  }

  return (
    <div className="relative flex items-start">
      <label htmlFor={id} className="sr-only">
        Label for
        {" "}
        {id}
      </label>
      <div className="flex h-5 items-center">
        <input
          id={id}
          name={id}
          checked={checked}
          disabled={disabled}
          type="checkbox"
          className={style}
          onChange={onChange}
        />
      </div>
      {children && (
        <span className="ml-3 text-sm text-gray-700 group-hover:text-white">
          {children}
        </span>
      )}
    </div>
  );
};

export default Checkbox;
