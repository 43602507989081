/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Paper, Text, TextProps, Tooltip,
} from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { useEffect, useState } from "react";

const TruncatedTextTooltip = ({
  width, label, ...otherProps
}: { label: string, width: string | number } & TextProps) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const { hovered, ref } = useHover();

  useEffect(() => {
    if (ref.current) {
      setIsTruncated(ref.current.offsetWidth < ref.current.scrollWidth);
    }
  }, [label, ref.current]);

  return (
    <Paper
      maw={width}
      className="overflow-hidden text-ellipsis text-inherit whitespace-nowrap bg-inherit"
    >
      <Tooltip withinPortal opened={hovered && isTruncated} label={isTruncated ? label : ""} position="top">
        <Text truncate ref={ref} {...otherProps} />
      </Tooltip>
    </Paper>
  );
};

export default TruncatedTextTooltip;
