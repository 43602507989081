/* eslint-disable react-hooks/exhaustive-deps */
import { ChartBarIcon, PlusCircleIcon } from "@heroicons/react/20/solid";
import {
  Flex, Grid, LoadingOverlay, Paper, ScrollArea, Select, Stack, Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useMutation } from "@tanstack/react-query";
import { subDays } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import uuid from "react-uuid";
import { queryClient, useTraitsAndMetrics } from "../../../../../hooks/QueryHooks";
import { createMetric, deleteMetric } from "../../../../../services/metric/metricEndpoint";
import { updateWorkspacePreference } from "../../../../../services/preference/preferenceEndpoints";
import { getUserMetricsGraph } from "../../../../../services/userActivityEndpoint";
import MButton from "../../../../../shared/ui/buttons/MButton";
import dateConverter from "../../../../../utils/dateConverter";
import notify from "../../../../../utils/notify";
import CustomChart from "../../account-overview/components/CustomChart";
import NewTrendModal from "../../account-overview/components/NewTrendModal";

type Props = {
  account: any;
  prefs: any;
  metrics: any;
  currentWorkspace: any;
};

const UserTrendsContainer = ({
  account, currentWorkspace, prefs, metrics,
}: Props) => {
  const [opened, { close, open }] = useDisclosure(false);
  const [loading, setLoading] = useState(false);

  const updatePrefMutation: any = useMutation({
    mutationFn: updateWorkspacePreference,
    onSuccess: () => {
      queryClient.invalidateQueries(["workspace_preferences", currentWorkspace.id]);
    },
  });

  const deleteMetricMutation: any = useMutation({
    mutationFn: deleteMetric,
  });

  const [bucket, setBucket] = useState<any>(prefs?.data?.users?.metrics?.grouping || "day");
  const [duration, setDuration] = useState<any>(
    Number.isNaN(parseInt(prefs?.data?.users?.metrics?.time_window, 10))
      ? "7" : parseInt(prefs?.data?.users?.metrics?.time_window, 10).toString(),
  );
  const [tableData, setTableData] = useState<any>({});

  useEffect(() => {
    if (duration !== prefs?.data?.users?.metrics?.time_window) {
      updatePrefMutation.mutate({
        workspace_id: currentWorkspace.id,
        preference: {
          ...prefs?.data,
          users: {
            ...prefs?.data?.users,
            metrics: {
              grouping: bucket,
              time_window: duration,
              items: [],
            },
          },
        },
      });
    }
  }, [bucket, duration]);

  const bucketList = useMemo(() => (
    [{ value: "day", label: "Daily" },
      { value: "week", label: "Weekly" },
      { value: "month", label: "Monthly" },
    ]
  ), []);

  const durationList = useMemo(() => {
    if (bucket === "day") {
      return [
        { value: "7", label: "Last 7 days" },
        { value: "30", label: "Last 30 days" },
        { value: "90", label: "Last 90 days" },
        { value: "180", label: "Last 180 days" },
      ];
    } if (bucket === "week") {
      return [
        { value: "4", label: "Last 4 weeks" },
        { value: "8", label: "Last 8 weeks" },
        { value: "12", label: "Last 12 weeks" },
        { value: "24", label: "Last 24 weeks" },
      ];
    } if (bucket === "month") {
      return [
        { value: "3", label: "Last 3 months" },
        { value: "6", label: "Last 6 months" },
        { value: "9", label: "Last 9 months" },
        { value: "12", label: "Last 12 months" },
      ];
    } return [];
  }, [bucket]);

  const onDeleteClick = (id: any) => {
    deleteMetricMutation.mutate({
      workspace_id: currentWorkspace.id,
      metric_id: id,
    }, {
      onSuccess: (data: any) => {
        queryClient.setQueryData(["workspace_metric_preferences", currentWorkspace.id], (oldData: any) => ({
          ...oldData,
          data: oldData.data.filter((item: any) => item.id !== data.data.id),
        }));
        if (process.env.REACT_APP_HE_WORKSPACE_KEY && process.env.REACT_APP_HE_DATA_KEY) {
          window?.hyperengage(
            "track",
            "deleted_pinned_metric",
            {
              properties: {
                metric_id: id,
                workspace_id: currentWorkspace.id,
                entity_type: "users",
              },
            },
          );
        }
        notify({
          type: "success",
          messageList: ["Removed Pinned metric"],
        });
      },
      onFailure: () => {
        notify({
          type: "failure",
          messageList: ["Failed to remove Pinned metric"],
        });
      },
    });
  };

  useEffect(() => {
    const getMetrics = async () => {
      setLoading(true);
      let days = 7;
      if (bucket === "day") {
        days = parseInt(duration, 10);
      } else if (bucket === "week") {
        days = parseInt(duration, 10) * 7;
      } else if (bucket === "month") {
        days = parseInt(duration, 10) * 30;
      }
      try {
        const apiPromises = metrics?.map(async (event: any) => {
          const response = await getUserMetricsGraph({
            bucket_size: bucket,
            external_id: account?.external_id,
            event_name: event.aggregator,
            workspace_identifier: currentWorkspace?.id,
            from_date: dateConverter(subDays(new Date(), days)),
          });
          return response;
        });
        const resolvedPromises = await Promise.all(apiPromises);
        resolvedPromises.forEach((response: any) => {
          if (response?.data) {
            setTableData((state: any) => ({ ...state, ...response.data }));
          }
        });
      } catch (error) {
        setTableData({});
      }
      setLoading(false);
    };
    if (!!account?.external_id && !!currentWorkspace?.id && metrics?.length > 0) {
      getMetrics();
    }
  }, [account?.external_id, bucket, duration, currentWorkspace?.id, metrics?.length]);

  const {
    data: metricData,
  }: any = useTraitsAndMetrics({
    workspaceId: currentWorkspace?.id,
    enabled: !!currentWorkspace?.id,
  });

  const selectedEvents = useMemo(() => metrics
    ?.map((item:any) => item.aggregator), [metrics]);

  const createMetricMutation: any = useMutation({
    mutationFn: createMetric,
  });

  const shownList = useMemo(() => (
    metricData?.contactMetrics?.list?.filter((item: any) => !selectedEvents?.find(
      (event: any) => event === item.name,
    ))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [selectedEvents, metricData?.contactMetrics?.length]);

  const onMetricSubmit = (metric: string, metricName: string, type: string) => {
    createMetricMutation.mutate({
      workspace_id: currentWorkspace?.id,
      aggregator: metric,
      display_name: metricName,
      description: "",
      type,
      id: `am_${metric}_${Math.random() * 1000}`,
      linked_entity: "users",
      is_default: false,
    }, {
      onSuccess: (newMetric: any) => {
        queryClient.setQueryData(["workspace_metric_preferences", currentWorkspace?.id], (oldData: any) => ({
          ...oldData,
          data: [...oldData.data, newMetric.data],
        }));
        if (process.env.REACT_APP_HE_WORKSPACE_KEY && process.env.REACT_APP_HE_DATA_KEY) {
          window?.hyperengage(
            "track",
            "created_pinned_metric",
            {
              properties: {
                display_name: metricName,
                aggregator: metric,
                workspace_id: currentWorkspace.id,
                entity_type: "users",
              },
            },
          );
        }
        notify({
          type: "success",
          messageList: ["Pinned metric successfully"],
        });
        close();
      },
      onFailure: () => {
        notify({
          type: "failure",
          messageList: ["Something went wrong"],
        });
      },
    });
  };

  return (
    <div className="max-w-full border bg-white border-gray-200 p-5">
      <NewTrendModal
        onMetricSubmit={onMetricSubmit}
        shownList={shownList}
        opened={opened}
        close={close}
      />
      <Stack>
        <Text className="flex gap-2 items-center" fz={20} fw={600} c="text.9">
          <ChartBarIcon className="w-4 h-4 fill-purple-500" />
          Trends
        </Text>
        <Flex gap={10}>
          <Select
            value={bucket}
            onChange={(value) => {
              setBucket(value);
              let dur;
              if (value === "day") {
                dur = "7";
              } if (value === "week") {
                dur = "4";
              } if (value === "month") {
                dur = "3";
              }
              setDuration(dur);
            }}
            classNames={{
              input: "rounded-md w-[150px]",
              root: "focus:ring-purple-500",
            }}
            placeholder="Select range"
            data={bucketList}
          />
          <Select
            value={duration}
            onChange={setDuration}
            classNames={{
              input: "rounded-md w-[150px]",
              root: "focus:ring-purple-500",
            }}
            placeholder="Select range"
            data={durationList}
          />
        </Flex>
      </Stack>
      <div className="flex flex-col gap-5 justify-between items-center">
        <div className="flex flex-col w-full gap-2">
          <MButton disabled={metrics?.length === 6} onClick={open} className="text-sm mt-2 w-full" color="white" size="sm" leftIcon={<PlusCircleIcon className="w-5 h-5" />}>
            New pinned Metric
          </MButton>
        </div>
        <ScrollArea h={window.innerHeight - 480} w="full">
          <Grid className="w-full relative">
            <LoadingOverlay loaderProps={{ size: "md" }} c="main.5" visible={loading} overlayBlur={2} />
            {metrics?.length !== 0 ? (Object.keys(tableData)?.length !== 0
          && Object.entries(tableData).map(([key, data]: any) => {
            const selectedEvent = metrics?.find((event: any) => event.aggregator === key);
            return selectedEvent && (
              <Grid.Col key={uuid()} span={12}>
                <CustomChart
                  title={selectedEvent.display_name}
                  data={data}
                  type={selectedEvent.type}
                  deleteHandler={onDeleteClick}
                  id={selectedEvent.id}
                />
              </Grid.Col>
            );
          })) : (
            <Paper p="xl" radius="md" className="bg-gray-50 italic text-gray-500 w-full flex items-center justify-center" shadow="sm">
              <Text size="sm" weight={500}>
                Add your first custom success metric and generate graphs to view trends.
              </Text>
            </Paper>
            )}
          </Grid>
        </ScrollArea>
      </div>
    </div>
  );
};

export default UserTrendsContainer;
