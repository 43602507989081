import { create } from "zustand";

type ViewStore = {
  view: {
    id: string;
    entity_relation: string;
    view_icon: null | string;
    owner_id: string;
    workspace_id: string;
    metrics_time_frame: string;
    sort_fields: {
      type: string;
      field: string;
      order: string;
    };
    metrics: string[];
    updated_at: string;
    is_precreated: boolean;
    view_name: string;
    view_description: null | string;
    filters: {
      query_type: "OR";
      predicates: {
        query_type: "AND";
        predicates: {
          entity_type?: any;
          field: string;
          operator: string;
          value: string | undefined;
          type?: string;
          time_window?: string;
        }[];
      }[];
    };
    is_public: boolean;
    created_at: string;
    selected_columns: string[];
    page_limit: number;
    is_pinned: boolean;
  };
  setView: (view: any) => void;
  updateView: (view: any) => void;
  setSelectedFields: (fields: any) => void;
  setTimeWindow: (time_window: string) => void;
  setMetrics: (metrics: any) => void;
  editFilter: (filter: any, index: number) => void;
  clearFilter: () => void;
  editPredicate: (predicate: any, index: number, filterIndex: number) => void;
  addPredicate: (predicate: any, filterIndex: number) => void;
  deletePredicate: (index: number, filterIndex: number) => void;
  addFilter: (filter: any) => void;
  deleteFilter: (index: number) => void;
};

const useViewStore = create<ViewStore>((set) => ({
  // state data and actions will go here
  view: {
    id: "",
    entity_relation: "",
    view_icon: null,
    owner_id: "",
    workspace_id: "",
    metrics_time_frame: "",
    sort_fields: {
      type: "",
      field: "",
      order: "",
    },
    metrics: [],
    updated_at: "",
    is_precreated: false,
    view_name: "",
    view_description: null,
    filters: {
      query_type: "OR",
      predicates: [{
        query_type: "AND",
        predicates: [{
          field: "",
          operator: "",
          value: "",
        }],
      }],
    },
    is_public: false,
    created_at: "",
    selected_columns: [],
    page_limit: 0,
    is_pinned: false,
  },
  setView: (view: any) => set({ view }),
  updateView: (view: any) => set(
    (state: any) => (
      { view: { ...state.view, ...view } }),
  ),
  addFilter: (filter) => set(
    (state) => ({
      view: {
        ...state.view,
        filters: {
          query_type: "OR",
          predicates: state?.view?.filters?.predicates?.length
            ? [...state.view.filters.predicates, filter] : [filter],
        },
      },
    }),
  ),
  editFilter: (filter, index) => set((state) => {
    const filters = [...state.view.filters.predicates];
    filters[index] = filter;
    return {
      view: {
        ...state.view,
        filters: {
          ...state.view.filters,
          predicates: filters,
        },
      },
    };
  }),
  deleteFilter: (index) => set((state) => {
    const filters = [...state.view.filters.predicates];
    filters.splice(index, 1);
    return {
      view: {
        ...state.view,
        filters: {
          ...state.view.filters,
          predicates: filters,
        },
      },
    };
  }),
  clearFilter: () => set((state) => ({
    view: {
      ...state.view,
      filters: {
        query_type: "OR",
        predicates: [{
          query_type: "AND",
          predicates: [{
            field: "",
            operator: "",
            value: "",
          }],
        }],
      },
    },
  })),
  editPredicate: (predicate, index, filterIndex) => set((state: any) => {
    if (state?.view?.filters?.predicates) {
      const filters = [...state.view.filters.predicates];
      const predicates = [...state.view.filters.predicates[filterIndex].predicates];
      predicates[index] = predicate;
      filters[filterIndex].predicates = predicates;
      return {
        view: {
          ...state.view,
          filters: {
            ...state.view.filters,
            predicates: filters,
          },
        },
      };
    }
    return state.view;
  }),
  addPredicate: (predicate, filterIndex) => set((state) => {
    const filters = [...state.view.filters.predicates];
    filters[filterIndex].predicates.push(predicate);
    return {
      view: {
        ...state.view,
        filters: {
          ...state.view.filters,
          predicates: filters,
        },
      },
    };
  }),
  deletePredicate: (index, filterIndex) => set((state) => {
    const filters = [...state.view.filters.predicates];
    filters[filterIndex].predicates.splice(index, 1);
    return {
      view: {
        ...state.view,
        filters: {
          ...state.view.filters,
          predicates: filters,
        },
      },
    };
  }),
  setSelectedFields: (selected_fields) => set((state) => ({
    view: {
      ...state.view,
      selected_fields,
    },
  })),
  setMetrics: (metrics) => set((state) => ({
    view: {
      ...state.view,
      metrics,
    },
  })),
  setTimeWindow: (time_window) => set((state) => ({
    view: {
      ...state.view,
      metrics_time_frame: time_window,
    },
  })),
}));

export default useViewStore;
