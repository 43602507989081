/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import {
  BoltIcon, PlusCircleIcon,
} from "@heroicons/react/20/solid";
import {
  CheckBadgeIcon, StarIcon, XCircleIcon,
} from "@heroicons/react/24/outline";
import {
  ActionIcon,
  Box,
  Center,
  Flex,
  Indicator,
  Menu,
  SegmentedControl,
  Select,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useAllAutomations, useAllPlaybooks, useWorkspaceMembers } from "../../hooks/QueryHooks";
import useWorkspaceStore from "../../store/workspace/workspaceStore";
import InboxTabs from "./InboxTabs";
import SelectItemWithTooltip from "../select/SelectItemWIthTooltip";

type Props = {
  navTo?: "inbox" | "signals",
  automationId: any,
  setAutomationId: any,
  period: any,
  setPeriod: any,
  playbookId: any,
  setPlaybookId: any,
  signalStatus: any,
  setSignalStatus: any,
  tab: string,
  setTab: any,
  owner: any,
  setOwner: any,
  onTabClick?: any,
  accountSignal?: boolean,
  showTitle?: boolean,
};

const SignalsHeader = ({
  automationId,
  setAutomationId,
  period,
  setPeriod,
  playbookId,
  setPlaybookId,
  signalStatus,
  setSignalStatus,
  tab,
  setTab,
  owner,
  setOwner,
  onTabClick,
  accountSignal,
  navTo = "inbox",
  showTitle = true,
}: Props) => {
  const workspace = useWorkspaceStore((state: any) => state.workspace);
  const timeframes = useMemo(() => ([
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 30 days",
    "Last 90 days",
    "Last 180 days",
    "Last 365 days",
    "All time",
  ]).map((key, id) => ({ id: id + 1, label: key, value: key.toLowerCase().split(" ").join("_") })), []);

  const { data: playBookData }: any = useAllPlaybooks({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
  });

  const { data: workspaceMembers }: any = useWorkspaceMembers({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
    retry: 2,
  });

  const { data: signalsData }: any = useAllAutomations({
    workspaceId: workspace?.id,
    automationType: "signal",
    enabled: !!workspace?.id,
    retry: 2,
  });

  const playbooks = useMemo(() => playBookData?.map((playbook: any) => ({
    label: playbook?.title,
    value: playbook?.id,
  })) || [], [playBookData?.length]);

  const signalsList = useMemo(() => signalsData?.map((signal: any) => ({
    label: signal?.name,
    value: signal?.id,
  })) || [], [signalsData?.length]);

  const usersList = useMemo(() => (workspaceMembers?.users?.length ? [{
    value: "unassigned",
    assigned: true,
    label: "Unassigned",
    name: "Unassigned",
  }, ...workspaceMembers.users.map((member: any) => ({
    value: member.user_id,
    label: `${member.first_name} ${member.last_name}`,
    name: `${member.first_name} ${member.last_name}`,
    email: member.email,
    avatar_url: member.avatar_url,
  }))] : []), [workspaceMembers?.users?.length]);

  return (
    <Stack spacing={10} bg="white" className="py-4 border-b w-full rounded-t-md px-3 mt-1">
      <Flex align="center" className="self-stretch" justify="space-between" gap={5}>
        {showTitle && (
        <Flex align="center" gap={5}>
          <ActionIcon color="main.5" size="lg">
            <BoltIcon className="w-7 rounded-md h-7 p-1 bg-purple-100" />
          </ActionIcon>
          <Text color="text.9" size={20} fw={600}>Inbox</Text>
        </Flex>
        )}
        <Link
          className="text-sm text-purple-700 mt-1 hover:text-purple-900 font-semibold mr-4"
          to={`/home/${workspace?.unique_identifier}/${navTo === "signals" ? "signals/overview" : "inbox"}`}
        >
          {navTo === "signals" ? "Manage signals" : "View all"}
        </Link>
      </Flex>
      <Flex justify="space-between" gap={10} align="center">
        <Flex align="center" wrap="wrap" gap={5}>
          {!accountSignal && <InboxTabs onTabClick={onTabClick} tab={tab} setTab={setTab} />}
          <Select
            value={playbookId}
            onChange={setPlaybookId}
            itemComponent={SelectItemWithTooltip}
            allowDeselect
            size="sm"
            classNames={{
              input: "rounded-md text-sm text-ellipsis whitespace-nowrap",
              root: "focus:ring-purple-500",
            }}
            placeholder="Select Playbook"
            data={playbooks}
          />
          <Select
            value={automationId}
            allowDeselect
            onChange={setAutomationId}
            itemComponent={SelectItemWithTooltip}
            size="sm"
            classNames={{
              input: "rounded-md text-sm text-ellipsis whitespace-nowrap",
              root: "focus:ring-purple-500",
            }}
            placeholder="Select Signal"
            data={signalsList}
          />
        </Flex>
        <Flex align="center" justify="end" wrap="wrap" gap={5}>
          <SegmentedControl
            color="main.5"
            size="sm"
            value={signalStatus}
            onChange={setSignalStatus}
            className="bg-white border border-gray-200"
            data={[
              {
                value: "",
                label: (
                  <Center>
                    <Box>All</Box>
                  </Center>
                ),
              },
              {
                value: "starred",
                label: (
                  <Tooltip
                    classNames={{
                      tooltip: "bg-gray-700/60 rounded-md",
                    }}
                    withinPortal
                    offset={10}
                    label="Starred"
                  >
                    <Center>
                      <StarIcon className="w-5 h-5" />
                    </Center>
                  </Tooltip>
                ),
              },
              {
                value: "done",
                label: (
                  <Tooltip
                    classNames={{
                      tooltip: "bg-gray-700/60 rounded-md",
                    }}
                    withinPortal
                    offset={10}
                    label="Done"
                  >
                    <Center>
                      <CheckBadgeIcon className="w-5 h-5" />
                    </Center>
                  </Tooltip>
                ),
              },
              {
                value: "dismissed",
                label: (
                  <Tooltip
                    classNames={{
                      tooltip: "bg-gray-700/60 rounded-md",
                    }}
                    withinPortal
                    offset={10}
                    label="Dismissed"
                  >
                    <Center>
                      <XCircleIcon className="w-5 h-5" />
                    </Center>
                  </Tooltip>
                ),
              },
            ]}
          />
          <Select
            value={period}
            onChange={setPeriod}
            size="sm"
            classNames={{
              input: "w-[130px] rounded-md text-sm",
              root: "focus:ring-purple-500",
            }}
            placeholder="Last ..."
            data={timeframes}
          />
          <Menu position="bottom-end">
            <Menu.Target>
              <Tooltip
                classNames={{
                  tooltip: "bg-gray-700/80 rounded-md",
                }}
                withinPortal
                offset={10}
                label={`Owners: ${owner?.map((own: any) => usersList.find((val: any) => val.value === own)?.label).join(", ")}`}
              >
                <Indicator color="main.5" size={7} classNames={{ indicator: "p-1.5 text-[8px]" }} inline label={`${owner.length}`} disabled={owner?.length <= 1}>
                  <ActionIcon color="main.5" size="lg" radius="xl">
                    <PlusCircleIcon className="fill-purple-500" />
                  </ActionIcon>
                </Indicator>
              </Tooltip>
            </Menu.Target>
            <Menu.Dropdown>
              {usersList.map((member: any) => (
                <Menu.Item
                  key={member.value}
                  onClick={() => setOwner(
                    (owners: any) => (owners.includes(member.value)
                      ? owners.filter((ow: any) => ow !== member.value)
                      : [...owners, member.value]),
                  )}
                  className={`px-5 py-1 border-b my-1 last:border-b-0 ${owner?.includes(member?.value) && "bg-purple-100 hover:bg-purple-200"}`}
                >
                  <div>
                    <Text truncate size="sm" className={`${member?.value === "unassigned" && "py-[8px]"}`}>{member?.name}</Text>
                    {member?.email && (
                    <Text truncate size={10} opacity={0.65}>
                      {member?.email}
                    </Text>
                    )}
                  </div>
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default SignalsHeader;
