import React from "react";
import { Link } from "react-router-dom";

type Props = {
  to: string,
  children: React.ReactNode,
  className? : string,
  replace? : boolean,
};

const PurpleLink = ({
  to,
  children,
  className,
  replace = false,
} : Props) => {
  let linkStyle = "text-purple-700 hover:text-purple-800";

  if (className) {
    linkStyle = `${linkStyle} ${className}`;
  }

  return (
    <Link
      to={to}
      className={linkStyle}
      replace={replace}
    >
      {children}
    </Link>
  );
};

export default PurpleLink;
