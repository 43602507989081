import { authorizedApiClient } from "./config";

type AddUserToWorkspace = {
  workspace_id: string, email: string, role_name: string,
};

export const addUserToWorkspace = async ({
  workspace_id, email, role_name,
}: AddUserToWorkspace) => authorizedApiClient.post(`/${workspace_id}/members`, {
  email,
  role_name,
});

export const allWorkspaceMembers = async (
  {
    workspace_id,
    page_size = 100,
    page_number = 0,
  }: { workspace_id: string, page_size?: number, page_number?: number },
) => authorizedApiClient.get(`/${workspace_id}/members/all?page_size=${page_size}&page_number=${page_number}`);
