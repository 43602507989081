/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */

import { ICellTextProps } from "ka-table/props";
import React from "react";
import { zonedTimeToUtc } from "date-fns-tz";
import { formatDistance } from "date-fns";

const LastTimeEntry: React.FC<ICellTextProps> = React.memo(
  ({
    column, dispatch, rowKeyValue, ...props
  }) => {
    const value = props.rowData[`${column.key}`];
    const localTime = zonedTimeToUtc(new Date(value?.replace(" ", "T")), "UTC");
    const date = formatDistance(localTime, new Date(), {
      addSuffix: true,
    });
    return (
      <span className="whitespace-nowrap text-gray-500 w-full text-ellipsis overflow-hidden block !px-2 !py-2">
        {date || "..."}
      </span>
    );
  },
);

export default LastTimeEntry;
