import { Transition } from "@headlessui/react";
import { Fragment } from "react";

type Props = {
  children: React.ReactNode
  isOpen: boolean,
};

const StepModal = ({
  children,
  isOpen,
} : Props) => (
  <Transition
    as={Fragment}
    enter="transition ease-out duration-100"
    enterFrom="transform opacity-0 scale-95"
    enterTo="transform opacity-100 scale-100"
    leave="transition ease-in duration-75"
    leaveFrom="transform opacity-100 scale-100"
    leaveTo="transform opacity-0 scale-95"
    show={isOpen}
  >
    <article className="overflow-auto ml-12">
      {children}
    </article>
  </Transition>
);

export default StepModal;
