/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClipboardIcon, EnvelopeIcon } from "@heroicons/react/20/solid";
import {
  Collapse,
  Flex,
  HoverCard,
  Paper,
  Stack,
  Switch, Text, Tooltip, UnstyledButton,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useMemo } from "react";
import Nango from "@nangohq/frontend";
import { ReactComponent as SlackIcon } from "../../../../../assets/images/slack.svg";
import { queryClient, useSlackIntegration } from "../../../../../hooks/QueryHooks";
import useWorkspaceStore from "../../../../../store/workspace/workspaceStore";
import ActionCard from "./ActionCard";
import SlackModal from "./SlackModal";
import InboxModal from "./InboxModal";
import EmailModal from "./EmailModal";
import notify from "../../../../../utils/notify";

type Props = {
  updateAction: (action: any, type: any) => void;
  actions: any[];
  playbook: any;
  deleteAction: any;
  setSelectedFields: any;
  setSelectedMetrics: any;
  signal?: any;
};

const Destinations = ({
  updateAction,
  deleteAction,
  actions,
  playbook,
  setSelectedFields,
  setSelectedMetrics,
  signal,
}: Props) => {
  const workspace: any = useWorkspaceStore((state) => state.workspace);
  const [slackOpened, { open: slackOpen, close: slackClose }] = useDisclosure(false);
  const [emailOpened, { open: emailOpen, close: emailClose }] = useDisclosure(false);
  const [inboxOpened, { open: inboxOpen, close: inboxClose }] = useDisclosure(false);
  const nango: any = new Nango({ publicKey: process.env.REACT_APP_NANGO_KEY! });

  const { data: slackIntegration }: any = useSlackIntegration({
    workspaceId: workspace.id,
    enabled: true,
    retry: false,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const slackEnabled = useMemo(() => actions.find((action: any) => action?.type === "send_to_slack"), [actions]);
  const emailEnabled = useMemo(() => actions.find((action: any) => action?.type === "send_to_email"), [actions]);
  const inboxEnabled = useMemo(() => actions.find((action: any) => action?.type === "send_to_inbox"), [actions]);

  const onCompleteAction = (action: any, type: string) => {
    updateAction(action, type);
  };

  const slackClick = () => {
    nango.auth("slack", workspace.id, { user_scope: ["channels:read", "channels:write"] }).then(() => {
      notify({
        heading: "Integrated Slack",
        type: "success",
        messageList: ["You have successfully integrated Slack with Hyperengage"],
      });
      queryClient.invalidateQueries(["slack-integration", workspace?.id]);
    }).catch(() => {
      notify({
        heading: "Integration Failed",
        type: "success",
        messageList: ["Failed to integrate Slack with Hyperengage, please try again later"],
      });
    });
  };

  return (
    <Paper py="md">
      <Text component="label" className="text-gray-500 text-sm font-bold mb-2" htmlFor="action-type">Send via</Text>
      <SlackModal
        setSelectedMetrics={setSelectedMetrics}
        onCompleteAction={onCompleteAction}
        setSelectedFields={setSelectedFields}
        signal={signal}
        opened={slackOpened}
        close={slackClose}
        data={slackEnabled}
      />
      <EmailModal
        signal={signal}
        setSelectedFields={setSelectedFields}
        setSelectedMetrics={setSelectedMetrics}
        onCompleteAction={onCompleteAction}
        opened={emailOpened}
        close={emailClose}
        data={emailEnabled}
        playbook={playbook}
      />
      <InboxModal
        onCompleteAction={onCompleteAction}
        opened={inboxOpened}
        close={inboxClose}
        data={inboxEnabled}
      />
      <Flex wrap="wrap" justify="center" gap="lg">
        <ActionCard
          action="Inbox"
          desc="Send alert to Hyperengage's inbuilt Inbox."
          Image={ClipboardIcon}
          onClick={() => {
            inboxOpen();
          }}
          onDelete={() => {
            deleteAction("send_to_inbox");
          }}
          complete={inboxEnabled}
        />
        <HoverCard
          openDelay={200}
          width={350}
          withArrow
          transitionProps={{ duration: 200 }}
        >
          <HoverCard.Target>
            <div>
              <ActionCard
                action="Slack"
                info="Send alerts via Slack. You can only choose one of slack or email alerts."
                desc="Send alert to a Slack channel."
                Image={SlackIcon}
                onClick={() => {
                  slackOpen();
                }}
                onDelete={() => {
                  deleteAction("send_to_slack");
                }}
                enabled={slackIntegration?.msg?.length > 0}
                complete={slackEnabled}
              />
            </div>
          </HoverCard.Target>
          {!slackIntegration?.msg && (
          <HoverCard.Dropdown className="bg-gray-100 text-sm">
            <Text>
              Looks like you currently don&apos;t have Slack integration enabled.
              {" "}
              <UnstyledButton className="text-sm" c="main.5" onClick={slackClick}>
                Click here to Integrate Slack
              </UnstyledButton>
            </Text>
          </HoverCard.Dropdown>
          )}
        </HoverCard>
        <ActionCard
          action="Email"
          info="Send email alerts by adding recipients. You can only choose one of slack or email alerts"
          desc="Send alert to email addresses."
          Image={EnvelopeIcon}
          onClick={() => {
            emailOpen();
          }}
          onDelete={() => {
            deleteAction("send_to_email");
          }}
          complete={emailEnabled}
        />
      </Flex>
    </Paper>
  );
};

export default Destinations;
