/* eslint-disable @typescript-eslint/no-var-requires */
// Rename the doc_path and count header for table.
import { format, formatDistance } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import countries from "i18n-iso-countries";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export const dateFormat = (key: string, value: string) => {
  try {
    if (key.includes("last") || key.includes("timestamp")) {
      const localTime = zonedTimeToUtc(new Date(value?.replace(" ", "T")), "UTC");
      return formatDistance(localTime, new Date(), {
        addSuffix: true,
      });
    }
    const localTime = zonedTimeToUtc(new Date(value?.replace(" ", "T")), "UTC");
    return format(localTime, "dd MMM yyyy");
  } catch {
    return "...";
  }
};

// Get Row Value.
export const getValueForRow = (key: any, value: any): any => {
  if (typeof value === "string" && value.match(/(\d{4})-(\d{2})-(\d{2})/)) {
    return dateFormat(key, value);
  }
  return value;
};

/**
 * This function is responsible for converting the data from the API to the format like:
 *  about a minute ago, 2 days ago, etc.
 * We will use date-fns to achieve this.
 * @param date{String} - The date string from the API.
 * @returns {String} - The formatted date string.
 */

export const mapKeysToColumns = (
  selectedColumns: string[],
  traits: any,
  prevColumns: any,
) => selectedColumns?.map((item: any) => {
  let datatype;
  switch (traits[item]?.data_type) {
    case "string": { datatype = "string";
      break;
    }
    case "boolean": { datatype = "boolean";
      break;
    }
    case "int":
    case "float":
      datatype = "number";
      break;
    case "timestamp":
    case "datetime":
      datatype = "date";
      break;

    default: {
      datatype = "string";
      break;
    }
  }

  if (prevColumns.includes((column: any) => column.key === item)) {
    return prevColumns.find((column: any) => column.key === item);
  }
  return ({
    key: item,
    title: traits[item]?.display_name || item.split("_").join(" "),
    dataType: datatype,
  });
});
