/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { ClipboardIcon, EnvelopeIcon } from "@heroicons/react/20/solid";
import {
  ActionType, ITableProps, SortingMode, kaReducer,
} from "ka-table";
import {
  hideLoading, loadData, showLoading, updateData, updatePageIndex, updatePagesCount,
} from "ka-table/actionCreators";
import { DispatchFunc } from "ka-table/types";
import {
  useEffect, useLayoutEffect, useMemo, useRef, useState,
} from "react";
import { useParams } from "react-router-dom";
import uuid from "react-uuid";
import { ReactComponent as SlackIcon } from "../../../../../assets/images/slack.svg";
import { useAllAutomations } from "../../../../../hooks/QueryHooks";
import KaTable, { IActionPayload } from "../../../../../shared/table/KaTable";
import useWorkspaceStore from "../../../../../store/workspace/workspaceStore";

const SignalsTable = ({
  selectedFilter, selectedState,
}: any) => {
  const workspace = useWorkspaceStore((state) => state.workspace);
  const tableRef = useRef<any>(null);
  const allAutomations: any = useAllAutomations({
    workspaceId: workspace?.id,
    automationType: "signal",
    enabled: !!workspace?.id,
  });

  const [tableColumns, setTableColumns] = useState<any>([
    {
      key: "name",
      dataType: "signal_name",
      title: "Name",
      isResizable: true,
      isSortable: true,
      colGroup: { style: { minWidth: 50 } },
    }, {
      key: "trigger",
      dataType: "string",
      title: "Trigger",
      isResizable: true,
      colGroup: { style: { minWidth: 50 } },
      isSortable: true,
    }, {
      key: "send_via",
      dataType: "signal_send",
      title: "Send via",
      isResizable: true,
      colGroup: { style: { minWidth: 50 } },
    }, {
      key: "updated_at",
      dataType: "last_activity",
      title: "Last Updated",
      sortDirection: "desc",
      isSortable: true,
      isResizable: true,
      colGroup: { style: { minWidth: 50 } },
    }, {
      key: "status",
      dataType: "signal_status",
      title: "Status",
      isResizable: true,
      colGroup: { style: { width: 150 } },
    },
    // {
    //   key: "delete_signal",
    //   dataType: "delete_signal",
    //   title: "",
    //   isResizable: false,
    //   colGroup: { style: { width: 100 } },
    //   isSortable: false,
    // },
  ]);

  const tableHeight = useMemo(() => window.innerHeight - 260, []);

  const [tableProps, changeTableProps] = useState<ITableProps>({
    columns: tableColumns,
    columnResizing: true,
    columnReordering: true,
    height: tableHeight,
    rowKeyField: "id",
    singleAction: loadData(),
    sortingMode: SortingMode.Single,
    loading: {
      enabled: true,
      text: "Loading",
    },
  });

  useLayoutEffect(() => {
    if (tableHeight) {
      changeTableProps((prevState: ITableProps) => ({
        ...prevState,
        height: tableHeight,
      }));
    }
  }, [tableHeight]);

  const params: any = useParams();
  const [total, setTotal] = useState<number>(0);

  const onColumnChange = (payload: IActionPayload) => {
    const {
      type, columnKey, width, targetColumnKey,
    } = payload;

    if (type === ActionType.ResizeColumn) {
      const newFields = tableColumns.map((field: any) => {
        if (field.key === columnKey) {
          field.width = width;
        }
        return field;
      });
      setTableColumns(newFields);
    }

    if (type === ActionType.ReorderColumns) {
      const columnIndex = tableColumns.findIndex((field: any) => field.key === columnKey);
      const targetColumnIndex = tableColumns.findIndex(
        (field: any) => field.key === targetColumnKey,
      );
      const newFields = [...tableColumns];
      const temp = newFields[columnIndex];
      newFields[columnIndex] = newFields[targetColumnIndex];
      newFields[targetColumnIndex] = temp;
      // shift the column to the target column index.
      setTableColumns(newFields);
    }
  };

  const kaDispatch: DispatchFunc = (action: any) => {
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
    if (action.type === ActionType.ResizeColumn || action.type === ActionType.ReorderColumns) {
      if (action.type === ActionType.ResizeColumn) {
        onColumnChange({
          type: action.type,
          columnKey: action.columnKey,
          width: action.width,
        });
      } else {
        onColumnChange({
          type: action.type,
          columnKey: action.columnKey,
          targetColumnKey: action.targetColumnKey,
        });
      }
    }
  };

  const fetchData = () => {
    kaDispatch(showLoading());
    try {
      if (allAutomations?.data) {
        const temp = allAutomations.data?.filter((item: any) => {
          if (selectedFilter === "all") {
            return item;
          }
          return item?.automation_actions?.actions?.some((action: any) => action?.type === selectedFilter);
        }).filter((item: any) => {
          if (selectedState === "all") {
            return item;
          }
          return item?.status === selectedState;
        }).map((item: any) => {
          const value = { ...item };
          value.name = item?.name;
          value.randomId = uuid();
          value.send_via = value?.automation_actions?.actions?.map((action: any) => {
            if (action?.type === "send_to_slack") {
              return { name: "Slack", Icon: SlackIcon };
            } if (action?.type === "send_to_inbox") {
              return { name: "Inbox", Icon: ClipboardIcon };
            } if (action?.type === "send_to_email") {
              return { name: "Email", Icon: EnvelopeIcon };
            } return { name: "", Icon: <div /> };
          });
          return value;
        });
        setTotal(temp?.length);
        kaDispatch(updateData(temp));
        if (allAutomations?.data?.total_hits < ((tableProps?.paging?.pageIndex || 0) * (tableProps?.paging?.pageSize || 1))) {
          kaDispatch(updatePageIndex(0));
        }
        kaDispatch(updatePagesCount(Math.ceil((allAutomations?.data?.total_hits || 1) / (tableProps?.paging?.pageSize ?? 1)) || 0));
        kaDispatch(hideLoading());
      } else {
        setTotal(0);
        kaDispatch(updatePageIndex(0));
        kaDispatch(updateData([]));
        kaDispatch(updatePagesCount(1));
        kaDispatch(hideLoading());
      }
    } catch (err) {
      setTotal(0);
      kaDispatch(updatePageIndex(0));
      kaDispatch(updateData([]));
      kaDispatch(updatePagesCount(1));
      kaDispatch(hideLoading());
    }
  };

  useEffect(() => {
    if (allAutomations?.isSuccess) {
      fetchData();
    }
  }, [selectedFilter, selectedState, allAutomations?.isSuccess]);

  return (
    <KaTable
      ref={tableRef}
      tableProps={tableProps}
      changeTableProps={changeTableProps}
      kaDispatch={kaDispatch}
      rowKeyField="id"
      itemType="Actions"
      onColumnChange={onColumnChange}
    />
  );
};

export default SignalsTable;
