import { authorizedApiClient } from "./config";

type CreateApiKey = {
  workspace_id: string,
};

export const createApiKey = async ({
  workspace_id,
}: CreateApiKey) => authorizedApiClient.post(`/${workspace_id}/api_key`, {
  workspace_id,
});

type GetApiKey = {
  workspace_id?: string
};

export const getApiKey = async ({ workspace_id }: GetApiKey) => authorizedApiClient.get(
  `/${workspace_id}/api_key`,
);
