import { Square2StackIcon } from "@heroicons/react/20/solid";
import {
  Paper,
  Text,
  Tooltip,
} from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { useState } from "react";

const SmallTextWithCopy = ({ text }: any) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const clipboard = useClipboard();

  const handleCopy = () => {
    clipboard.copy(text);
    setPopupVisible(true);
    setTimeout(() => {
      setPopupVisible(false);
    }, 2000); // Hide the popup after 2 seconds
  };

  return (
    <Paper className="flex items-center justify-between gap-2">
      <Text fz="sm" ff="Nunito" truncate className="w-[170px] font-semibold text-secondary-500" style={{ flex: 1 }}>{text}</Text>
      <Tooltip
        opened={popupVisible}
        position="right"
        color="text.5"
        label="Copied!"
        offset={3}
        withArrow
      >
        <button type="button" onClick={handleCopy} className="text-gray-400 hover:text-purple-700">
          <Square2StackIcon width={15} height={15} />
        </button>
      </Tooltip>
    </Paper>
  );
};

export default SmallTextWithCopy;
