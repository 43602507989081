/* eslint-disable max-len */
import { Flex, Tooltip } from "@mantine/core";
import React from "react";
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/prop-types */
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { ICellTextProps } from "ka-table/props";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { deleteAutomation } from "../../services/automation/automationEndpoints";
import notify from "../../utils/notify";
import { queryClient } from "../../hooks/QueryHooks";
import useWorkspaceStore from "../../store/workspace/workspaceStore";
import { deletePlaybook } from "../../services/playbook/playbookEndpoints";

/**
 * Custom cell component.
 */
const PlaybookActionsCell: React.FC<ICellTextProps> = React.memo(
  ({
    column, dispatch, rowKeyValue, ...props
  }) => {
    const workspace = useWorkspaceStore((state) => state.workspace);

    const navigate = useNavigate();

    const deletePlaybookMutation = useMutation({
      mutationFn: deletePlaybook,
      onSuccess: () => {
        queryClient.setQueryData(["all_playbooks"], (old: any) => {
          const newPlaybooks = old.filter(
            (automation: any) => automation.id !== props.rowData.id,
          );
          return {
            ...old,
            data: newPlaybooks,
          };
        });
        notify({
          messageList: ["Playbook deleted successfully"],
          type: "success",
        });
      },
    });

    const handleDelete = () => {
      deletePlaybookMutation.mutate({
        playbook_id: props.rowData.id,
        workspace_id: workspace.id,
      });
    };
    return (
      <Flex
        gap={10}
        align="center"
        className="group w-fit duration-100 cursor-pointer ease-linear -m-2 rounded-md"
      >
        <Tooltip
          classNames={{
            tooltip: "bg-gray-700/80 rounded-md",
          }}
          label="Edit playbook"
          offset={10}
        >
          <IconEdit
            onClick={() => {
              navigate(`${props.rowData.id}/edit`);
            }}
            className="h-[25px] w-[25px] p-1 text-purple-500 group-hover:block duration-100 ease-linear hover:text-purple-700 rounded-sm hover:bg-purple-200"
          />
        </Tooltip>
        {/* <Tooltip
          classNames={{
            tooltip: "bg-gray-700/80 rounded-md",
          }}
          offset={10}
          label="Delete playbook"
        >
          <IconTrash
            onClick={() => {
              handleDelete();
            }}
            className="h-[25px] w-[25px] p-1 text-red-500 group-hover:block duration-100 ease-linear hover:text-red-700 rounded-sm hover:bg-red-200"
          />
        </Tooltip> */}
      </Flex>
    );
  },
);
export default PlaybookActionsCell;
