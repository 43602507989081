/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Paper,
  Text,
  ThemeIcon,
  useMantineTheme,
} from "@mantine/core";
// import { IconCookie, IconMoonStars } from "@tabler/icons-react";
import { ReactComponent as NoDataIllus } from "../../assets/illustrations/emptyTableDark.svg";

const EmptyTablePlaceholder = ({ message, title }: any) => {
  const theme = useMantineTheme();
  return (
    <Paper
      p="lg"
      style={{
        textAlign: "center",
        backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
      }}
      className="flex items-center justify-center flex-col"
    >
      {/* <ThemeIcon p={3} mt={20} radius="sm" size="lg" color="main.4">
        <Empty size={120} stroke={1.5} />
      </ThemeIcon> */}
      <NoDataIllus className="w-[250px] h-[250px] mt-5" />
      <Text size="md" mt={10} mb={0} weight={500} style={{ color: theme.colors.main[5] }}>
        {title || "No Records Available"}
      </Text>
      <Text size="sm" style={{ marginBottom: "24px", color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[6] }}>
        {message || "We couldn't find any data to display at the moment."}
      </Text>
    </Paper>
  );
};

export default EmptyTablePlaceholder;
