import React from "react";
import { UserCircleIcon, BuildingOfficeIcon } from "@heroicons/react/24/outline";
import classNames from "../../../utils/classNames";

type Props = {
  type: string, selected?: boolean, disabled?: boolean, onClick: any,
};

const TypeSelect = ({
  selected = false, type, disabled = false, onClick,
}: Props) => (
  <button type="button" onClick={() => { if (!disabled) { onClick(type); } }} className={classNames("px-4 border-2 ease-linear duration-200 rounded-lg border-gray-200 py-2 flex items-center justify-between", selected ? "border-purple-400 bg-purple-100" : "hover:bg-gray-100", disabled && "bg-gray-400 hover:bg-gray-400 cursor-default")}>
    <div className="flex text-gray-500 gap-3 w-[250px] items-center">
      {type === "accounts" ? <BuildingOfficeIcon className="h-7 w-7" /> : <UserCircleIcon className="h-7 w-7" />}
      <div className="flex flex-col justify-center text-left">
        <h3 className="capitalize text-gray-700 font-bold text-md">{type}</h3>
        <p className="font-light text-gray-500 text-sm">
          {type === "accounts" ? "Group of Users" : "People using your product"}
        </p>
      </div>
    </div>
    {selected
    && <h3 className="flex text-xl items-center justify-center text-purple-500">&#x2022;</h3>}
  </button>
);

export default TypeSelect;
