/* eslint-disable max-len */
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { init, BrowserTracing, Replay } from "@sentry/react";
import { createRoot } from "react-dom/client";
import { queryClient } from "./hooks/QueryHooks";
import "./index.css";
import Main from "./main";

if (process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "staging") {
  init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    integrations: [
      new BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/staging\.hyperengage\.io/],
      }),
      new Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        theme={{
          fontFamily: "Nunito",
          colors: {
            main: [
              "#EDE9FA",
              "#EDE9FE",
              "#DDD6FE",
              "#C4B5FD",
              "#A78BFA",
              "#8B5CF6",
              "#7C3AED",
              "#6D28D9",
              "#5B21B6",
              "#4C1D95",
            ],
            text: [
              "#F3F4F6",
              "#E5E7EB",
              "#D1D5DB",
              "#9CA3AF",
              "#6B7280",
              "#4B5563",
              "#374151",
              "#1F2937",
              "#111827",
            ],
          },
          primaryColor: "main",
          primaryShade: 5,
        }}
      >
        <Notifications />
        <Main />
      </MantineProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
