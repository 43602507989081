import React from "react";
import Logo from "../../../assets/images/hyperengage-logo.svg";

type Props = {
  size?: string,
  className? : string,
};

const HeIcon = ({
  size = "md",
  className,
} : Props) => {
  let iconStyle = "";
  if (size === "sm") {
    iconStyle += " w-[140px]";
  } else if (size === "md") {
    iconStyle += " w-[156px]";
  } else if (size === "lg") {
    iconStyle += " w-[170px]";
  }

  if (className) {
    iconStyle = `${iconStyle} ${className}`;
  }

  return (
    <img src={Logo} className={iconStyle} alt="hyperengage-logo" />
  );
};

export default HeIcon;
