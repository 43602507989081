/* eslint-disable no-nested-ternary */
import { TrashIcon } from "@heroicons/react/20/solid";
import { Paper, Text } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import TraitType from "./TraitType";

type Props = {
  trait: any,
  edit: boolean,
  handleDelete: any,
};

const HeaderTrait = ({ trait, edit = false, handleDelete }: Props) => {
  const { hovered, ref } = useHover();
  return (
    <Paper
      radius="sm"
      ref={ref}
      c="text.5"
      className={`flex flex-col relative w-[150px] gap-1 p-2 py-1.5 text-sm ease-in transition-colors duration-100 ${hovered && edit ? "hover:bg-violet-50" : ""}`}
    >
      <Text size="sm" c="text.5" truncate fw={500} tt="capitalize" w={150}>{trait?.display_name}</Text>
      <TraitType trait={trait} />
      {edit && hovered && (
        <div className="absolute top-0 right-0 p-1">
          <button
            type="button"
            className="p-2 ml-2"
            onClick={() => { handleDelete(trait?.name); }}
          >
            <TrashIcon className="hover:text-gray-800 transition-colors duration-300" width={20} height={20} />
          </button>
        </div>
      )}
    </Paper>
  );
};

export default HeaderTrait;
