import React from "react";

type Props = {
  className?: string,
};

const Dash = ({ className }: Props) => (
  <span className={`block h-[5px] bg-gray-200 w-[40px] rounded-lg ${className}`} />
);

export default Dash;
