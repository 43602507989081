import React from "react";
import { Link } from "react-router-dom";

type Props = {
  to: string,
  children: React.ReactNode,
  className? : string,
};

const GrayLink = ({
  to,
  children,
  className,
} : Props) => {
  let linkStyle = "text-gray-700 text-sm";

  if (className) {
    linkStyle = `${linkStyle} ${className}`;
  }

  return (
    <Link
      to={to}
      reloadDocument
      className={linkStyle}
    >
      {children}
    </Link>
  );
};

export default GrayLink;
