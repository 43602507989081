/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Avatar,
  Flex, Paper, Stack, Text,
} from "@mantine/core";
import { IconCalendarShare } from "@tabler/icons-react";
import Adil from "../../../assets/images/adilGS.png";
import Taylor from "../../../assets/images/taylorGS.png";
import CalendlyButton from "./CalendlyButton";
import { useCurrentUser } from "../../../hooks/QueryHooks";

const ScheduleDemo = () => {
  const { data: user }: any = useCurrentUser({});
  return (
    <Paper w="full" px="md" py="sm" mb={8} radius="sm" shadow="sm">
      <Flex align="center" justify="space-between">
        <Stack spacing={2}>
          <Text size="lg" c="text.9" weight={700}>Learn how Hyperengage works</Text>
          <Text size="sm" c="text.4">
            Talk to our team of GTM experts to know how to
            maximise value from actionable insights in Hyperengage.
          </Text>
          <Flex className="gap-2 mt-3">
            <CalendlyButton name={`${user?.first_name} ${user?.last_name}`} email={user?.email} />
            <Avatar.Group spacing="sm">
              <Avatar src={Adil} radius="xl" />
              <Avatar src={Taylor} radius="xl" />
            </Avatar.Group>
          </Flex>
        </Stack>
        <IconCalendarShare width={80} height={80} strokeWidth={1} />
      </Flex>
    </Paper>
  );
};

export default ScheduleDemo;
