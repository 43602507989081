/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from "react";
import TruncatedTextTooltip from "../ui/truncate/TruncatedText";

const SelectItemWithTooltip = forwardRef<HTMLDivElement, any>(
  ({
    label, value, width = 165, c, ...others
  }: any, ref) => (
    <div ref={ref} {...others}>
      <TruncatedTextTooltip label={label || value} width={width} size="sm">{label || value}</TruncatedTextTooltip>
    </div>
  ),
);

export default SelectItemWithTooltip;
