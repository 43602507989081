/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Tooltip } from "@mantine/core";
import { updateSortDirection } from "ka-table/actionCreators";
import SortIcon from "ka-table/Components/SortIcon/SortIcon";
import { IHeadCellProps } from "ka-table/props";
import { isSortingEnabled } from "ka-table/Utils/SortUtils";

const HeadCell: React.FC<IHeadCellProps> = (props) => {
  const {
    column, dispatch, childComponents,
  } = props;
  const sortingEnabled = isSortingEnabled(props.sortingMode, props.column);
  return (
    <button
      draggable
      type="button"
      onClick={() => {
        if (column.isSortable) {
          dispatch(updateSortDirection(column.key));
        }
      }}
      className={`${sortingEnabled ? "cursor-pointer" : "!cursor-default"} w-full h-full flex justify-between items-center select-none ease-linear text-left text-xs font-medium tracking-wide text-gray-600`}
    >
      <div className="active:text-gray-800 hover:text-gray-800 active:cursor-grabbing text-gray-500 cursor-grab absolute hidden left-0 group-hover:flex items-center">
        <EllipsisVerticalIcon className="h-[17px] w-5" />
        <EllipsisVerticalIcon className="h-[17px] w-5 -ml-4" />
      </div>
      <Tooltip withinPortal classNames={{ tooltip: "text-xs transform-none" }} label={column.title || "-"} position="top">
        <span className="whitespace-nowrap text-ellipsis overflow-hidden block">
          {column.title || "-"}
        </span>
      </Tooltip>
      {column.sortDirection && sortingEnabled && (
        <SortIcon
          column={column}
          dispatch={dispatch}
          childComponents={childComponents}
        />
      )}
    </button>
  );
};

export default HeadCell;
