import { Flex } from "@mantine/core";
import HeIcon from "../../../shared/ui/icons/HeIcon";
import GrayLink from "../../../shared/ui/links/GrayLink";

const TopBar = () => (
  <header className="fixed bg-white px-5 z-20 py-2 top-0 left-0 right-0 flex justify-between items-center">
    <HeIcon size="md" />
    <Flex gap={10} align="center">
      <GrayLink to="https://docs.hyperengage.io/getting-started/start-here" className="font-medium">Help</GrayLink>
    </Flex>
  </header>
);

export default TopBar;
