import {
  Title, Text, Button, Center, Flex,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import MButton from "../../shared/ui/buttons/MButton";

const WorkspaceNotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <Center className="p-[10%]">
      <Flex
        align="center"
        justify="center"
        gap={80}
      >
        <div className="max-w-[700px] flex flex-col items-center">
          <Title className="">You do not have access to this workspace</Title>
          <Text c="dimmed" size="lg">
            Page you are trying to open has restricted access. You may have mistyped the address,
            Sorry for the inconvenience. If you think this is an error contact support.
          </Text>
          <Flex gap={10}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              variant="outline"
              size="md"
              mt="xl"
              className=""
            >
              Back
            </Button>
            <MButton
              color="purple"
              onClick={() => {
                navigate("/workspaces");
              }}
              size="md"
              mt="xl"
            >
              Your Workspaces
            </MButton>
          </Flex>
        </div>
      </Flex>
    </Center>
  );
};

export default WorkspaceNotFoundPage;
