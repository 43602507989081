/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react-hooks/exhaustive-deps */
import { subDays } from "date-fns";
import { ITableProps, kaReducer } from "ka-table";
import {
  hideLoading, loadData, updateData, updatePageIndex, updatePagesCount,
} from "ka-table/actionCreators";
import { ActionType, SortingMode } from "ka-table/enums";
import { DispatchFunc } from "ka-table/types";
import {
  useEffect, useMemo, useRef, useState,
} from "react";
import { useOutletContext, useParams } from "react-router-dom";
import uuid from "react-uuid";
import useLocalStorageState from "use-local-storage-state";
import { getCompanyEventActivity, getCompanyEventList } from "../../../../services/companyEndpoint";
import KaTable, { IActionPayload } from "../../../../shared/table/KaTable";
import dateConverter from "../../../../utils/dateConverter";
import LeftEventsBar from "./components/LeftEventsBar";
import useWorkspaceStore from "../../../../store/workspace/workspaceStore";

const AccountEvents = () => {
  const workspaceId = useWorkspaceStore((state) => state.workspace?.id);
  const tableRef = useRef<any>(null);
  const [divRef]: any = useOutletContext();
  const metricList = useMemo(() => (
    ["Last day", "Last 7 days", "Last 30 days", "Last 90 days", "Last 180 days", "Last 365 days"].map((key, id) => ({ id: (id + 1), name: key }))), []);
  const tableHeight = useMemo(
    // eslint-disable-next-line no-unsafe-optional-chaining
    () => window.innerHeight - divRef?.current?.offsetHeight - divRef?.current?.offsetTop,
    [divRef?.current],
  );

  const [tableColumns, setTableColumns] = useLocalStorageState<any>("he-events-prefs", {
    defaultValue: [
      {
        key: "users.name",
        dataType: "string",
        title: "User",
        isResizable: true,
        colGroup: { style: { minWidth: 50 } },
        isSortable: true,
      }, {
        key: "traits",
        dataType: "object",
        title: "Event Properties",
        isResizable: true,
        colGroup: { style: { minWidth: 50 } },
        isSortable: false,
      },
    ],
  });

  const [tableProps, changeTableProps] = useState<ITableProps>({
    columns: tableColumns,
    columnResizing: true,
    noData: { text: "No results found" },
    paging: {
      enabled: true,
      pageIndex: 0,
      pagesCount: 1,
      pageSize: 20,
    },
    columnReordering: true,
    rowKeyField: "randomId",
    height: tableHeight,
    singleAction: loadData(),
    sortingMode: SortingMode.Single,
    loading: {
      enabled: true,
      text: "Loading",
    },
  });

  const [noResults, setNoResults] = useState<boolean>(false);
  const params: any = useParams();
  const [events, setEvents] = useState<any[]>([]);
  const [metric, setMetric] = useState <any>(metricList[3]);
  const [selectedEvent, setSelectedEvent] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [total, setTotal] = useState<number>(0);
  const firstRender = useRef<boolean>(true);

  const calcDate: any = useMemo(() => {
    if (metric?.name) {
      const val = parseInt(metric.name.match(/[0-9]+/) ? metric.name.match(/[0-9]+/)[0] : "1", 10);
      return subDays(new Date(), val);
    }
    return new Date("1-1-1970");
  }, [metric.name]);

  useEffect(() => {
    if (metric?.name) {
      const getTable = async () => {
        setLoading(true);
        try {
          const data = await getCompanyEventList({
            workspace_identifier: workspaceId,
            search_query: query,
            external_id: params.accountId,
            from_date: dateConverter(calcDate),
          });
          setEvents(data?.data?.results);
          if (firstRender.current) {
            setSelectedEvent(data?.data?.results[0]?.event_name);
            firstRender.current = false;
          }
        } catch (e: any) {
          setEvents([]);
        }
        setLoading(false);
      };
      getTable();
    }
  }, [query, metric]);

  const handleQuery = (e: any) => {
    setQuery(e.target.value);
  };

  const onColumnChange = (payload: IActionPayload) => {
    const {
      type, columnKey, width, targetColumnKey,
    } = payload;

    if (type === ActionType.ResizeColumn) {
      const newFields = tableColumns.map((field: any) => {
        if (field.key === columnKey) {
          field.width = width;
        }
        return field;
      });

      setTableColumns(newFields);
    }

    if (type === ActionType.ReorderColumns) {
      const columnIndex = tableColumns.findIndex((field: any) => field.key === columnKey);
      const targetColumnIndex = tableColumns.findIndex(
        (field: any) => field.key === targetColumnKey,
      );
      const newFields = [...tableColumns];
      const temp = newFields[columnIndex];
      newFields[columnIndex] = newFields[targetColumnIndex];
      newFields[targetColumnIndex] = temp;
      // shift the column to the target column index.
      setTableColumns(newFields);
    }
  };

  const kaDispatch: DispatchFunc = (action: any) => {
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
    const sortColumn: any = tableProps.columns.find((c) => c.sortDirection);
    if (action.type === ActionType.ResizeColumn || action.type === ActionType.ReorderColumns) {
      if (action.type === ActionType.ResizeColumn) {
        onColumnChange({
          type: action.type,
          columnKey: action.columnKey,
          width: action.width,
        });
      } else {
        onColumnChange({
          type: action.type,
          columnKey: action.columnKey,
          targetColumnKey: action.targetColumnKey,
        });
      }
    }
  };

  const fetchData = async (page: number) => {
    try {
      const response = await getCompanyEventActivity({
        workspace_identifier: workspaceId,
        event_name: selectedEvent,
        external_id: params.accountId,
        from_date: dateConverter(calcDate),
        offset: page * 20,
        limit: 20,
      });
      if (response.data) {
        const temp = response?.data?.results.map((item: any) => {
          const value = { ...item };
          if (value?.name || value?.email) {
            value["users.name"] = {
              name: value?.name,
              desc: value?.email,
              img: value?.avatar_url,
              externalId: value?.user_id,
              relation: "user",
              accountId: params.accountId,
            };
            delete (value.name);
            delete (value.email);
            delete (value?.avatar_url);
            delete (value?.user_id);
          }
          value.randomId = uuid();
          return value;
        });
        setTotal(response.data.total_hits);
        kaDispatch(updateData(temp));
        if (response.data.total_hits < ((tableProps?.paging?.pageIndex || 0) * (tableProps?.paging?.pageSize || 1))) {
          kaDispatch(updatePageIndex(0));
        }
        kaDispatch(updatePagesCount(Math.ceil(response.data.total_hits / (tableProps?.paging?.pageSize ?? 1)) || 0));
        kaDispatch(hideLoading());
      } else {
        setTotal(0);
        setNoResults(true);
        kaDispatch(updatePageIndex(0));
        kaDispatch(updateData([]));
        kaDispatch(updatePagesCount(1));
        kaDispatch(hideLoading());
      }
    } catch (err) {
      setTotal(0);
      setNoResults(true);
      kaDispatch(updatePageIndex(0));
      kaDispatch(updateData([]));
      kaDispatch(updatePagesCount(1));
      kaDispatch(hideLoading());
    }
  };

  useEffect(() => {
    fetchData(tableProps?.paging?.pageIndex || 0);
  }, [tableProps?.paging?.pageIndex, selectedEvent]);

  return (
    <div className="relative flex overflow-auto">
      <LeftEventsBar
        metric={metric}
        setMetric={setMetric}
        selected={selectedEvent}
        height={tableHeight}
        metricList={metricList}
        setSelected={setSelectedEvent}
        events={events}
        query={query}
        onChange={handleQuery}
        loading={loading}
      />
      <div className="flex-1 relative min-w-0">
        <KaTable
          tableProps={tableProps}
          changeTableProps={changeTableProps}
          kaDispatch={kaDispatch}
          ref={tableRef}
          totalData={total}
          itemType="Events"
          rowKeyField="randomId"
          height={tableHeight}
        />
      </div>
    </div>
  );
};

export default AccountEvents;
