import React from "react";
import { Flex, Stack, Text } from "@mantine/core";
import ProfileImg from "../../../../shared/ui/icons/ProfileImg";
import SmallTextWithCopy from "../../../../shared/copy/SmallText";
import Dash from "../../../../shared/placeholder/Dash";
import TruncatedTextTooltip from "../../../../shared/ui/truncate/TruncatedText";

type Props = {
  name?: string,
  email?: string,
  website?:string,
  img?: string,
  id?: string,
  joined?: string,
};

const AccountProfileAvatar = ({
  name,
  email,
  website,
  img,
  id,
  joined,
}: Props) => (
  <Flex gap={12} p="md">
    <ProfileImg width={64} height={64} desc={email || website} img={img} name={name} />
    <Stack spacing={2}>
      {name && <TruncatedTextTooltip weight={600} width={180} size="xl" c="text.9" label="name">{name}</TruncatedTextTooltip>}
      {id && <SmallTextWithCopy text={id} />}
      {joined ? (
        <Text fz="xs" className="text-secondary-500">
          Joined on
          {" "}
          {joined}
        </Text>
      ) : <Dash />}
    </Stack>
  </Flex>
);

export default AccountProfileAvatar;
