/* eslint-disable import/prefer-default-export */
import { subDays } from "date-fns";
import qs from "qs";
import { authorizedApiClient } from "./config";
import dateConverter from "../utils/dateConverter";

type EntityActivity = {
  workspace_identifier: string,
  entity_type: string,
  external_id: string,
  start_time: string,
  end_time?: string,
  limit?: number,
  offset?: number,
};

export const getEntityActivity = async ({
  workspace_identifier,
  entity_type,
  start_time,
  end_time = dateConverter(new Date()),
  external_id,
  limit = 100,
  offset = 0,
}: EntityActivity) => authorizedApiClient.post(`${workspace_identifier}/utils/activity`, {
  entity_type,
  start_time,
  external_id,
  end_time,
  limit,
  offset,
});

type UserMetrics = {
  workspace_identifier: string,
  external_id: string,
  event_names: string[],
  from_date?: string,
  to_date?: string,
  bucket_size: "day" | "week" | "month" | "year"
};

export const getUserMetrics = async ({
  workspace_identifier,
  event_names,
  external_id,
  from_date = dateConverter(subDays(new Date(), 30)),
  to_date = dateConverter(new Date()),
  bucket_size,
}: UserMetrics) => authorizedApiClient.get(`/${workspace_identifier}/user_contacts/events/trend_search/${external_id}`, {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    withCredentials: true,
  },
  params: {
    event_names,
    from_date,
    to_date,
    bucket_size,
  },
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
});

type UserMetricsGraph = {
  workspace_identifier: string,
  external_id: string,
  event_name: string,
  from_date?: string,
  to_date?: string,
  bucket_size: "day" | "week" | "month" | "year"
};

export const getUserMetricsGraph = async ({
  workspace_identifier,
  event_name,
  external_id,
  from_date = dateConverter(subDays(new Date(), 30)),
  to_date = dateConverter(new Date()),
  bucket_size,
}: UserMetricsGraph) => authorizedApiClient.get(`/${workspace_identifier}/user_contacts/events/metrics_graph/${external_id}`, {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    withCredentials: true,
  },
  params: {
    event_name,
    from_date,
    to_date,
    bucket_size,
  },
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
});
