import {
  Container, Flex, LoadingOverlay, Text, Transition,
} from "@mantine/core";
import { useState } from "react";
import { useAccount, useContact, useCurrentUser } from "../../../hooks/QueryHooks";
import useWorkspaceStore from "../../../store/workspace/workspaceStore";
import HqDetails from "./components/HqDetails";
import MyAccounts from "./components/MyAccounts";
import SignalsContainer from "../../../shared/signals/SignalsContainer";

const transitionStyles = {
  in: { opacity: 1, transform: "scaleX(1)" },
  out: { opacity: 0, transform: "scaleX(0)" },
  common: { transformOrigin: "right" },
  transitionProperty: "transform, opacity",
};

const HeadquartersPage = () => {
  const [selected, setSelected] = useState(null);
  const [selectedSignal, setSelectedSignal] = useState(null);
  const [entityType, setEntityType] = useState("accounts");
  const workspace = useWorkspaceStore((state) => state.workspace);
  const currentUser: any = useCurrentUser({
    enabled: !!workspace?.id,
  });
  const account = useAccount(
    {
      workspaceId: workspace?.id,
      accountId: selected || "",
      enabled: !!workspace?.id && !!selected && entityType === "accounts",
    },
  );
  const contact = useContact(
    {
      workspaceId: workspace?.id,
      contactId: selected || "",
      enabled: !!workspace?.id && !!selected && entityType === "users",
    },
  );
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectAccount = (acc: any, select: boolean) => {
    setEntityType("accounts");
    setSelectedSignal(null);
    if (select) {
      setSelected(null);
      setIsOpen(false);
    } else {
      setSelected(acc);
      if (!isOpen) {
        setIsOpen(true);
      }
    }
  };

  const handleSelectSignal = (acc: any, signal: any, select: boolean, entity: string) => {
    setEntityType(entity);
    if (select) {
      setSelected(null);
      setSelectedSignal(null);
      setIsOpen(false);
    } else {
      setSelected(acc);
      setSelectedSignal(signal);
      if (!isOpen) {
        setIsOpen(true);
      }
    }
  };

  const onTabClick = (tab: string) => {
    setEntityType(tab);
    setSelectedSignal(null);
    setSelected(null);
    setIsOpen(false);
  };

  return (
    <Container fluid className="px-12 py-8 overflow-auto w-full relative h-full bg-gray-100 ">
      <Text p={16} pt={0} my={8} c="text.9" size={30} fw={700}>
        {`Hey ${currentUser?.data?.first_name || "User"}! Welcome to your HQ.`}
      </Text>
      <Flex className="w-full self-stretch" gap={12}>
        <div className="w-full transition-all duration-200">
          <MyAccounts
            selectedAccId={entityType === "accounts" ? selected : undefined}
            onAccountClick={handleSelectAccount}
            workspace={workspace}
          />
          <SignalsContainer
            navTo="inbox"
            user={currentUser?.data?.user_id}
            selectedSignal={selectedSignal}
            workspace={workspace}
            onAccountClick={handleSelectSignal}
            onTabClick={onTabClick}
          />
        </div>
        <Transition mounted={isOpen} transition={transitionStyles} duration={200} timingFunction="ease">
          {(styles: any) => (
            <div className="basis-[550px]" style={{ ...styles, position: "relative" }}>
              <LoadingOverlay loaderProps={{ size: "md" }} c="main.5" visible={entityType === "accounts" ? account.isLoading : contact.isLoading} overlayBlur={2} />
              <HqDetails
                workspaceId={workspace?.id}
                entityRelation={entityType}
                entity={entityType === "accounts" ? account?.data : contact?.data}
              />
            </div>
          )}
        </Transition>
      </Flex>
    </Container>
  );
};

export default HeadquartersPage;
