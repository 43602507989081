/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Flex,
} from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  queryClient, useAllAutomations, useAllPlaybooks, useCurrentUser, useTraitsAndMetrics,
} from "../../../../hooks/QueryHooks";
import { createAutomation } from "../../../../services/automation/automationEndpoints";
import { getWorkspaceTrendsAgaintQuery } from "../../../../services/workspace/workspaceEndpoints";
import { ENTITY } from "../../../../services/workspace/workspaceEndpoints.enums";
import MButton from "../../../../shared/ui/buttons/MButton";
import useSignalStore from "../../../../store/signal/signalStore";
import useWorkspaceStore from "../../../../store/workspace/workspaceStore";
import notify from "../../../../utils/notify";
import NewSignalBody from "./components/NewSignalBody";
import { getLargerTimeWindow } from "../../../../utils/dateConverter";

const NewSignalPage = () => {
  const {
    signal,
    setSignal,
    setName,
    setAutomationType,
    setEntityRelation,
    setTrigger,
    setMetrics,
    setSelectedFields,
    addFilter,
    addPredicate,
    editPredicate,
    deletePredicate,
    deleteFilter,
    clearFilter,
    clearSignal,
    setStatus,
    setTimeWindow,
    setTimeZone,
    setSuppressionPeriod,
    createAction,
    deleteAction,
    setPlaybook,
    updateAction,
    setResponseData,
  } = useSignalStore();
  const workspace = useWorkspaceStore((state) => state.workspace);
  const [error, setError] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);

  const traitsAndMetrics: any = useTraitsAndMetrics({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
  });

  const { data: user }: any = useCurrentUser({});

  const { data: playBookData }: any = useAllPlaybooks({
    workspaceId: workspace?.id,
    enabled: !!workspace?.id,
  });

  useAllAutomations({
    workspaceId: workspace?.id,
    automationType: "signal",
    enabled: !!workspace?.id,
  });

  const playbooks = useMemo(() => playBookData?.map((playbook: any) => ({
    label: playbook?.title,
    value: playbook?.id,
  })), [playBookData?.length]);

  const createSignalMutation = useMutation({
    mutationFn: createAutomation,
  });

  const getTotalMutation = useMutation({
    mutationFn: getWorkspaceTrendsAgaintQuery,
  });

  const handleFilterAdd = () => {
    const selectedFilters = signal
      .filters.predicates[signal.filters.predicates.length - 1]?.predicates || [];
    if (selectedFilters.length === 0 || selectedFilters[0].field !== "") {
      addFilter({
        query_type: "AND",
        predicates: [{
          field: "",
          operator: "",
          value: "",
          type: "",
          entity_type: "column",
        }],
      });
    }
  };

  const handlePredicateAdd = (filterId?: any) => {
    const selectedFilters = signal.filters.predicates[filterId].predicates;
    if (selectedFilters.length === 0 || selectedFilters[selectedFilters.length - 1].field !== "") {
      addPredicate(
        {
          field: "",
          operator: "",
          value: "",
          type: "",
          entity_type: "column",
        },
        filterId,
      );
    }
  };

  const handlePredicateClear = (filterId?: any) => {
    deleteFilter(filterId);
  };

  const handleEntityChange = (value: "accounts" | "users") => {
    if (value === "accounts" && signal.entity_relation !== "accounts") {
      setSelectedFields(["accounts.name", "accounts.last_seen", "accounts.created_at"]);
    } else if (value === "users" && signal.entity_relation !== "users") {
      setSelectedFields(["users.name", "users.first_name", "users.last_seen", "users.created_at"]);
    }
    setEntityRelation(value);
  };

  const handlePredicateChange = (
    field: string,
    operator: string,
    value: any,
    type: string,
    entity_type?: string,
    id?: any,
    filterId?: any,
    time_window?: string,
  ) => {
    editPredicate({
      field,
      operator,
      value,
      type,
      entity_type: entity_type || "column",
      time_window,
    }, id, filterId);
    const selectedColumns = signal?.selected_fields || [];
    const selectedMetrics = signal?.metrics || [];
    let newColumns: any = selectedColumns;
    const newMetrics: any = (!selectedMetrics.includes(field) && entity_type !== "column" && field !== "") ? [...selectedMetrics, field] : selectedMetrics;
    if (signal?.entity_relation === "accounts" && field.startsWith("accounts.")) {
      newColumns = (!selectedColumns.includes(field) && entity_type === "column" && field !== "") ? [...selectedColumns, field] : selectedColumns;
      setSelectedFields(newColumns);
    } else if (signal?.entity_relation === "users" && field.startsWith("users.")) {
      newColumns = (!selectedColumns.includes(field) && entity_type === "column" && field !== "") ? [...selectedColumns, field] : selectedColumns;
      setSelectedFields(newColumns);
    }
    setMetrics(newMetrics);
    setTimeWindow(getLargerTimeWindow(signal?.time_window, time_window));
  };

  useEffect(() => {
    if (signal?.filters?.predicates?.[0]?.predicates?.[0]?.field !== "") {
      getTotalMutation.mutate({
        workspaceId: workspace?.id,
        entityType: signal?.entity_relation === "accounts" ? ENTITY.ACCOUNTS : ENTITY.USERS,
        query: {
          include_count: true,
          page: 1,
          per_page: 10,
          filter: {
            ...signal?.filters,
            predicates: signal?.filters?.predicates?.map((fil: any) => ({
              ...fil,
              predicates: fil?.predicates.filter((item: any) => item.field !== ""),
            })),
          },
          selected_fields: signal?.selected_fields,
          sort: {
            field: signal?.entity_relation === "accounts" ? "accounts.last_seen" : "users.last_seen",
            order: "desc",
            type: "property",
          },
          metrics: signal?.metrics,
          time_window: signal?.time_window,
          time_zone: workspace?.workspace_time_zone,
        },
      }, {
        onSuccess: (data: any) => {
          setResponseData(data?.data);
          setTotal(data?.total_results);
        },
        onError: () => {
          setTotal(0);
          notify({
            messageList: ["Unknown error while applying filter"],
            type: "failure",
          });
        },
      });
    }
  }, [signal?.filters]);

  const handlePredicateRemove = (id: number, filterId?: any) => {
    deletePredicate(id, filterId);
  };

  const navigate = useNavigate();

  const onTimeWindowChange = (value: any) => {
    setTimeWindow(value);
  };

  const onSignalSubmit = () => {
    const slackEnabled = signal?.automation_actions?.actions?.find((action: any) => action?.type === "send_to_slack");
    const emailEnabled = signal?.automation_actions?.actions?.find((action: any) => action?.type === "send_to_email");
    if (!signal?.name || !signal?.entity_relation || !signal?.trigger
      || !signal?.filters?.predicates[0]?.predicates?.length
      || !signal?.automation_actions?.actions?.length
      || !signal?.automation_actions?.suppression_period
      || !signal?.time_window
      || (!!slackEnabled && ((slackEnabled?.message_type === "channel" && !slackEnabled?.channel_id)
       || (slackEnabled?.message_type === "direct" && !slackEnabled?.slack_user_id)))
      || (!!emailEnabled && (!emailEnabled?.to || !emailEnabled?.subject)) || (!signal?.filters?.predicates?.find((fil: any) => fil?.predicates?.find((item: any) => item?.field?.includes("metric"))))) {
      setError(true);
      notify({
        messageList: ["Kindly resolve errors in form fields"],
        type: "warning",
      });
    } else {
      createSignalMutation.mutate({
        ...signal,
        time_zone: workspace?.workspace_time_zone,
        status: "active",
        workspace_id: workspace?.id,
        owner_id: user?.user_id,
      }, {
        onSuccess: (data: any) => {
          queryClient.setQueryData(["all_automations", workspace?.id, signal?.automation_type], (oldData: any) => ({
            ...oldData,
            data: [
              ...oldData.data,
              data?.data,
            ],
          }));
          if (process.env.REACT_APP_HE_WORKSPACE_KEY && process.env.REACT_APP_HE_DATA_KEY) {
            window?.hyperengage(
              "track",
              "new_signal_created",
              {
                properties: {
                  name: signal?.name,
                  entity_relation: signal?.entity_relation,
                  trigger: signal?.trigger,
                  actions: signal?.automation_actions?.actions?.map((action: any) => action?.type),
                  time_zone: workspace?.workspace_time_zone,
                  status: "active",
                  workspace_id: workspace?.id,
                  owner_id: user?.user_id,
                },
              },
            );
          }
          notify({
            heading: "New Signal Created",
            type: "success",
            messageList: ["You have successfully created a new Signal."],
          });
          clearSignal();
          navigate(-1);
        },
        onError: (err: any) => {
          if (err.response?.status !== 0) {
            notify({
              heading: "Unknown error.",
              type: "failure",
              messageList: [
                "An unknown error occurred while creating the signal. Please try again later or contact our support team for assistance.",
              ],
            });
          }
        },
      });
    }
  };

  useEffect(
    () => () => { clearSignal(); },
    [],
  );

  return (
    <div className="px-12 py-8 overflow-auto relative h-full bg-gray-100">
      <div className="max-w-6xl mx-auto">
        <div className="flex mt-3 mb-5 justify-between items-center">
          <h2 className="text-gray-900 text-[30px] font-semibold">Create new signal</h2>
        </div>
        <NewSignalBody
          clearFilter={clearFilter}
          clearSignal={clearSignal}
          onTimeWindowChange={onTimeWindowChange}
          handlePredicateAdd={handlePredicateAdd}
          handlePredicateClear={handlePredicateClear}
          handlePredicateRemove={handlePredicateRemove}
          handlePredicateChange={handlePredicateChange}
          handleFilterAdd={handleFilterAdd}
          onSignalSubmit={onSignalSubmit}
          error={error}
          total={total}
          traitsAndMetrics={traitsAndMetrics}
          setSelectedMetrics={setMetrics}
          signal={signal}
          setName={setName}
          setEntityRelation={handleEntityChange}
          setTrigger={setTrigger}
          setSuppressionPeriod={setSuppressionPeriod}
          setSelectedFields={setSelectedFields}
          createAction={createAction}
          deleteAction={deleteAction}
          updateAction={updateAction}
          playbooks={playbooks}
          setPlaybook={setPlaybook}
        />
        <Flex justify="flex-end" gap={5} className="mt-2 p-4 bg-gray-100">
          <MButton
            variant="outline"
            onClick={() => {
              clearSignal();
              navigate(-1);
            }}
            className="hover:bg-gray-100"
            color="gray"
          >
            Cancel
          </MButton>
          <MButton color="purple" onClick={onSignalSubmit}>Save and activate</MButton>
        </Flex>
      </div>
    </div>
  );
};

export default NewSignalPage;
