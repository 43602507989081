/* eslint-disable react/destructuring-assignment */
import {
  Tabs,
} from "@mantine/core";

type Props = {
  activeTab: string,
  data: any[],
  handleTabChange: (value: string) => void,
};

const SourceList = ({
  activeTab,
  data,
  handleTabChange,
} : Props) => (
  <Tabs
    value={activeTab}
    color="gray.8"
    className="w-[30%] py-[30px] bg-gray-50 px-2"
    onTabChange={handleTabChange}
    variant="pills"
    orientation="vertical"
  >
    <Tabs.List className="w-full h-full">
      {data.map((item: any) => (
        <Tabs.Tab
          icon={(
            <item.icon
              width={20}
              height={20}
            />
)}
          value={item.name}
          key={item.name}
          className="text-md font-semibold h-[50px]"
        >
          {item.label}

        </Tabs.Tab>
      ))}
    </Tabs.List>
  </Tabs>
);

export default SourceList;
