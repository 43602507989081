import { ReactComponent as Segment } from "../../../assets/images/segmentAvatar.svg";

type Props = {
  size?: string,
  className? : string,
};

const SegmentIcon = ({
  size = "md",
  className,
} : Props) => {
  let iconStyle = "";
  if (size === "sm") {
    iconStyle += " lg:h-[96px] lg:w-[96px]]";
  } else if (size === "md") {
    iconStyle += " h-[90px] w-[90px] lg:h-[156px] lg:w-[156px]";
  } else if (size === "lg") {
    iconStyle += " lg:h-[160px] lg:w-[160px]";
  }

  if (className) {
    iconStyle = `${iconStyle} ${className}`;
  }

  return (
    <Segment className={iconStyle} />
  );
};

export default SegmentIcon;
