/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import BasicSelect from "../ui/inputs/select/BasicSelect";

type Props = {
  handleChange: (
    operator: string,
    value: any,
    type: string,
    entityType: string,
  ) => void;
  fieldSelect: boolean;
  op?: string;
  value?: any;
};

const BooleanFilter = (props: Props) => {
  const {
    op,
    value,
    handleChange,
    fieldSelect,
  } = props;

  const Ops = useMemo(
    () => [
      { name: "is equal to", operator: "eq", id: 1 },
      { name: "is known", operator: "known", id: 2 },
      { name: "is unknown", operator: "unknown", id: 3 },
    ],
    [],
  );

  const Values = useMemo(
    () => [
      { name: "true", id: 1 },
      { name: "false", id: 2 },
    ],
    [],
  );

  const [selectOp, setSelectOp] = useState<any>(
    op ? Ops.find((item) => item.operator === op) : { id: -1, name: "" },
  );
  const [selectValue, setSelectValue] = useState<any>(value ? Values.find((item:any) => (item.name === value)) : { id: -1, name: "" });

  useEffect(() => {
    if (
      selectOp?.operator === "known"
      || selectOp?.operator === "unknown"
      || (!!selectValue?.name && !!selectOp?.operator)
    ) {
      handleChange(
        selectOp.operator,
        selectValue.name,
        "boolean",
        "column",
      );
    }
  }, [selectValue, selectOp]);

  return (
    <>
      <BasicSelect
        disabled={fieldSelect}
        containerClass="min-w-[170px]"
        placeholder="Select Condition"
        list={Ops}
        selectedItem={selectOp}
        setSelectedItem={setSelectOp}
      />
      {selectOp?.operator !== "known" && selectOp?.operator !== "unknown" && (
        <BasicSelect
          disabled={!selectOp?.operator}
          containerClass="min-w-[170px]"
          placeholder="Select Value"
          list={Values}
          selectedItem={selectValue}
          setSelectedItem={setSelectValue}
        />
      )}
    </>
  );
};

export default BooleanFilter;
