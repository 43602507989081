import { Loader, Text } from "@mantine/core";
import React from "react";

const LoadWorkspace = () => (
  <div className="fixed inset-0 flex flex-col gap-10 items-center justify-center z-10 bg-gray-300 opacity-95">
    <div className="w-[50%] p-10 rounded-[40px] flex flex-col gap-10 items-center backdrop-blur-sm justify-center z-10 bg-white ">
      <Text w={600} size="lg" className="italic text-center" c="text.5">Please wait while we configure your workspace</Text>
      <Loader size="xl" variant="bars" c="main.5" />
    </div>
  </div>
);

export default LoadWorkspace;
