import { BoltIcon } from "@heroicons/react/20/solid";
import React from "react";
import MButton from "./MButton";

type Props = {
  text?: string, disabled?: boolean, size?: "sm" | "md" | "lg",
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => any,
};

const ConnectButton = ({
  onClick,
  text = "Connect",
  size = "sm",
  disabled = false,
} : Props) => (
  <MButton className="!text-xs" onClick={onClick} size={size} leftIcon={<BoltIcon width={12} height={12} />} radius="sm" disabled={disabled} color="white">
    {text}
  </MButton>
);

export default ConnectButton;
