import {
  BellAlertIcon,
} from "@heroicons/react/20/solid";
import {
  SegmentedControl, Select,
} from "@mantine/core";
import { useAllAutomations } from "../../../../../hooks/QueryHooks";
import useWorkspaceStore from "../../../../../store/workspace/workspaceStore";

type Props = {
  status: string;
  setStatus: (status: string) => void;
  alert: string;
  setAlert: (alert: string) => void;
};

const SignalLogControls = ({
  status, setStatus, alert, setAlert,
}: Props) => {
  const workspace = useWorkspaceStore((state) => state.workspace);
  const allAutomations: any = useAllAutomations({
    workspaceId: workspace?.id,
    automationType: "signal",
    enabled: !!workspace?.id,
  });
  return (
    <div className="sm:flex items-center px-5 py-2">
      <div className="sm:flex-auto items-center">
        <h2 className="text-[16px] font-semibold text-gray-800">
          Signal Logs
        </h2>
      </div>
      <div className="flex gap-3 justify-end flex-wrap items-end py-1">
        <SegmentedControl
          value={status}
          color="main.6"
          size="sm"
          onChange={setStatus}
          data={[
            { value: "all", label: "All" },
            { value: "error", label: "Error" },
            { value: "success", label: "Success" },
          ]}
        />
        <Select
          placeholder="Select Alert"
          size="sm"
          value={alert}
          onChange={setAlert}
          data={
            allAutomations?.data?.length ? [...allAutomations.data.map((automation: any) => ({
              value: automation.id,
              label: automation.name,
            })), { value: "all", label: "All" }] : []
        }
          icon={<BellAlertIcon className="h-4 w-4" />}
        />
      </div>
    </div>
  );
};

export default SignalLogControls;
