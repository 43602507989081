/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo } from "react";
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from "recharts";

type Props = {
  data: any,
};

const TrendsChart = ({ data }: Props) => {
  const colors = useMemo(() => ["#73CA", "#FF8B13", "#F273E6", "#39B5E0", "#7B2869", "#00005C", "#DC3535", "#624F82"], []);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <YAxis
          type="number"
          domain={[0, "auto"]}
          tickMargin={20}
          tick={{ fontFamily: "Nunito", fontSize: "5px" }}
          tickCount={6}
          axisLine={false}
          tickLine={false}
        />
        <CartesianGrid stroke="#ccc" vertical={false} strokeDasharray="5 5" />
        <Tooltip
          cursor={{ fill: "black", stroke: "#D1D5DB" }}
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
          content={<CustomTooltip />}
        />
        <XAxis
          dataKey="date"
          padding={{ left: 20 }}
          type="category"
          tickLine={false}
          allowDuplicatedCategory={false}
          tick={{ fontFamily: "Nunito", fontSize: "12px" }}
          interval="preserveStartEnd"
          minTickGap={150}
        />
        <Legend />
        {data[0] && Object.keys(data[0]).map((event, index) => (
          event !== "date" ? (
            <Line dot={false} dataKey={event} type="monotone" stroke={index < 8 ? colors[index - 1] : `#${Math.floor(Math.random() * 16777215).toString(16)}`} strokeWidth={2} />) : ""
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};
export const CustomTooltip = ({
  label,
  payload,
  active,
  ...props
}: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-gray-700 px-3 py-2 rounded-lg" {...props}>
        <text className="text-xs text-gray-400">
          {label}
        </text>
        {}
        <div />
        {payload?.map((p: any, idx:any) => (
          <div className="flex gap-2 items-center" key={idx}>
            <div className="h-2 w-2 rounded-full" style={{ backgroundColor: p.color }} />
            <text key={idx} className="text-sm text-gray-50">
              {p.value}
              {" "}
              events
            </text>
            <text className="text-gray-400 text-xs">
              {p.name}
            </text>
            {p.payload?.eventProperties && p.payload?.eventProperties?.map(
              (eventProperty: any, id: number) => (
                <span className=" text-gray-600 font-medium text-sx" key={id}>
                  {eventProperty.body.value}
                </span>
              ),
            )}
          </div>
        ))}
      </div>
    );
  }
  return null;
};

export default TrendsChart;
