import {
  Paper,
} from "@mantine/core";
import { RichTextEditor } from "@mantine/tiptap";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import DOMPurify from "dompurify";

type Props = {
  htmlContent: string;
};

const TooltipPlaybookPreview = ({ htmlContent }: Props) => {
  const sanitizedHTML = DOMPurify.sanitize(htmlContent);
  const editor = useEditor({
    editable: false,
    extensions: [
      Underline,
      TaskList.configure({
        HTMLAttributes: {
          class: "!pl-0",
        },
      }),
      TaskItem.configure({
        nested: true,
        HTMLAttributes: {
          class: "flex gap-3",
        },
      }),
      Link,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3, 4],
        },
        bulletList:
        {
          HTMLAttributes: {
            class: "list-disc list-outside",
          },
        },
        orderedList:
        {
          HTMLAttributes: {
            class: "list-decimal list-outside",
          },
        },
      }),
    ],
    content: sanitizedHTML,
  });

  editor?.commands?.setContent(sanitizedHTML);

  return (
    <Paper withBorder shadow="xs">
      <RichTextEditor
        classNames={{
          typographyStylesProvider: "text-xs",
        }}
        editor={editor}
      >
        <RichTextEditor.Content />
      </RichTextEditor>
    </Paper>
  );
};

export default TooltipPlaybookPreview;
